import Switch from 'react-switch';
import { useEffect, useState } from 'react';

import { LandingBox } from 'common/components/ui/Box/Box';
import { Breadcrumbs } from 'common/components/ui/Breadcrumbs/Breadcrumbs';
import { Title } from 'common/components/ui/Title/Title';
import { AppColor } from 'store/app';
import { eventsData } from 'routes/calendar/_temporaryEventsData';
import { useToggle } from '@proscom/ui-react';
import { Notification } from 'common/components/ui/Notification/Notification';
import { Button, ButtonSize, ButtonType } from 'common/components/ui/Button/Button';
import { useHistory } from 'react-router';
import React from 'react';
import { Calendar } from './components/Calendar';
import { EventNotification } from './components/notification/EventNotification';
import { EventNotification2 } from './components/notification/EventNotification2';
import { EventNotification3 } from './components/notification/EventNotification3';
import s from './CalendarPage.module.scss';

const breadcrumbsData = [
  { href: '/', title: 'Главная' },
  { href: '/calendar', title: 'Календарь' }
];

export const CalendarPage = () => {
  const history = useHistory();

  const isNotification = useToggle();
  const isNotification2 = useToggle();
  const isNotification3 = useToggle();
  const isNotificationTeacher = useToggle();
  const isNotificationLMS = useToggle();
  const isInLesson = useToggle();
  const [isEvents, setIsEvents] = useState(false);

  const handleChangeIsEvents = (checked) => {
    setIsEvents(checked);
  };

  const onOpenNotification = () => {
    isNotification.toggle();
  };

  const onCloseNotification = () => {
    isNotification.toggle();
    isNotification2.toggle();
    isNotificationTeacher.toggle();
  };

  const onCloseNotification2 = () => {
    isNotification2.toggle();
    isNotificationTeacher.toggle();
    isNotification3.toggle();
    isNotificationLMS.toggle();
  };

  const redirectToLMS = () => {
    history.push('/lms');
  };

  const redirectToLesson = () => {
    // history.push('/lesson/1');
  };

  return (
    <LandingBox className={s.CalendarPage__wrapper}>
      <Breadcrumbs items={breadcrumbsData} />
      <Title title="Календарь" tag="h1" className={s.CalendarPage__header}>
        <label className={s.CalendarPage__switch_wrapper}>
          <Switch
            onChange={handleChangeIsEvents}
            checked={isEvents}
            onColor={AppColor.Purple}
            checkedIcon={false}
            uncheckedIcon={false}
          />
          <span className={s.CalendarPage__switch_label}>Показывать события</span>
        </label>
      </Title>
      <EventNotification
        title="Физика (семинар)."
        lecturer="Ощепков Ю. А"
        cabinet={302}
        visible={isNotification.value}
        toggle={onCloseNotification}
        time={'через 9 минут начало'}
      />
      <EventNotification2
        lessonType="Физика (семинар)."
        lecturer="Ощепков Ю. А"
        cabinet={302}
        visible={isNotification2.value}
        toggle={onCloseNotification2}
        onCancel={onCloseNotification2}
      />
      <EventNotification3
        lecturer="Ощепков Ю. А"
        cabinet={302}
        visible={isNotification3.value}
        toggle={isNotification3.toggle}
        isInLesson={isInLesson.value}
      />
      <Notification
        visible={isNotificationTeacher.value}
        onToggle={isNotificationTeacher.toggle}
        className={s.CalendarPage__notification}>
        <div className={s.CalendarPage__notification_title}>Как поступит преподаватель?</div>
        <Button onClick={redirectToLesson} btnType={ButtonType.Outlined} size={ButtonSize.Small}>
          Смотреть
        </Button>
      </Notification>
      <Notification
        visible={isNotificationLMS.value}
        onToggle={isNotificationLMS.toggle}
        className={s.CalendarPage__notification}>
        <div className={s.CalendarPage__notification_title}>Перейти в Smart LMS</div>
        <Button onClick={redirectToLMS} btnType={ButtonType.Outlined} size={ButtonSize.Small}>
          Смотреть
        </Button>
      </Notification>
      <Calendar items={eventsData} isEvents={isEvents} toggleNotification={onOpenNotification} />
    </LandingBox>
  );
};
