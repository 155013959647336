import { eventsData } from 'routes/calendar/_temporaryEventsData';
import { LinkShowAll } from 'common/components/ui/LinkShowAll/LinkShowAll';
import { Title } from '../../../common/components/ui/Title/Title';
import { Schedule } from '../../../common/components/ui/Schedule/Schedule';

export const MySchedule = () => {
  return (
    <>
      <Title title="Календарь" tag="h2">
        <LinkShowAll href="/calendar" className="d-desktop-only" />
      </Title>
      <Schedule items={eventsData} />
    </>
  );
};
