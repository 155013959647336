import { ServiceGrid } from 'common/components/ui/ServiceGrid/ServiceGrid';
import { Card } from '../../../common/components/ui/Card/Card';
import s from './Specialization.module.scss';

const items = [
  { code: '06.00.00', title: 'АРХИТЕКТУРА' },
  { code: '58.00.00', title: 'ВОСТОКОВЕДЕНИЕ И АФРИКАНИСТИКА' },
  { code: '54.00.00', title: 'ИЗОБРАЗИТЕЛЬНОЕ ИСКУССТВО И ПРИКЛАДНЫЕ ВИДЫ ИСКУССТВ' },
  { code: '50.00.00', title: 'ИСКУССТВОЗНАНИЕ' },
  { code: '06.00.00', title: 'БИОЛОГИЧЕСКИЕ НАУКИ' },
  { code: '09.00.00', title: 'ИНФОРМАТИКА И ВЫЧИСЛИТЕЛЬНАЯ ТЕХНИКА' },
  { code: '06.00.00', title: 'ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ' },
  { code: '58.00.00', title: 'ВОСТОКОВЕДЕНИЕ И АФРИКАНИСТИКА' },
  { code: '46.00.00', title: 'ИСТОРИЯ И АРХЕОЛОГИЯ' },
  { code: '51.00.00', title: 'КУЛЬТУРОВЕДЕНИЕ И СОЦИОКУЛЬТУРНЫЕ ПРОЕКТЫ' },
  { code: '06.00.00', title: 'ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ' },
  { code: '58.00.00', title: 'ВОСТОКОВЕДЕНИЕ И АФРИКАНИСТИКА' }
];
type Props = {
  setChosenProgress: (number: number) => void;
};
export const Specialization: React.FC<Props> = ({ setChosenProgress }) => {
  const changeChosenProgress = () => {
    setChosenProgress(2);
  };

  const showItems = items.map((item) => (
    <Card onClick={changeChosenProgress} className={s.Specialization__item} key={item.title}>
      <span className={s.Specialization__code}>{item.code}</span>
      <span className={s.Specialization__title}>{item.title}</span>
    </Card>
  ));
  return <ServiceGrid className={s.Specialization__items}>{showItems}</ServiceGrid>;
};
