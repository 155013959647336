import clsx from 'clsx';
import React from 'react';
import { Button, ButtonSize } from '../Button/Button';
import { useMediaQueryResult } from '../../../hooks/useMediaQueryResult';
import { LandingBox } from '../Box/Box';
import pattern from './pattern.png';
import s from './LandingHero.module.scss';

export interface ILandingHeroProps {
  title: string;
  text?: string;
  picture?: string;
  pictureHeight?: number;
  action?: () => void;
  className?: string;
  contentClassName?: string;
  titleClassName?: string;
  textClassName?: string;
}

export function LandingHero({
  title,
  text,
  picture,
  pictureHeight = 438,
  action,
  className,
  contentClassName,
  titleClassName,
  textClassName
}: ILandingHeroProps) {
  const isTablet = useMediaQueryResult('(min-width: 768px)');
  const isDesktop = useMediaQueryResult('(min-width: 1024px)');
  const patternHeight = isDesktop ? pictureHeight + 76 : isTablet ? 391 : 279;
  return (
    <div className={s.LandingHero__container}>
      <LandingBox>
        <section className={clsx(s.LandingHero, className)} style={{ minHeight: patternHeight }}>
          <div className={clsx(s.LandingHero__content, contentClassName)}>
            <h1 className={clsx(s.LandingHero__title, titleClassName)}>{title}</h1>
            {text && <p className={clsx(s.LandingHero__text, textClassName)}>{text}</p>}
            <Button className={s.LandingHero__button} onClick={action} size={ButtonSize.Large}>
              Оставить заявку
            </Button>
          </div>

          <div className={s.LandingHero__pattern}>
            <img className={s.LandingHero__patternPattern} style={{ height: patternHeight }} src={pattern} alt={''} />
            {picture && <img className={s.LandingHero__patternPicture} src={picture} alt={''} />}
          </div>
        </section>
      </LandingBox>
    </div>
  );
}
