import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Box } from '../Box/Box';
import { useMediaQueryResult } from '../../../hooks/useMediaQueryResult';
import s from './TopBar.module.scss';

export interface ITopBarProps {
  logoLarge: React.ReactNode;
  logoSmall: React.ReactNode;
  slotLeft?: React.ReactNode;
  slotRight?: React.ReactNode;
  className?: string;
  classNameContent?: string;
}

export function TopBar({ logoLarge, logoSmall, slotLeft, slotRight, className, classNameContent }: ITopBarProps) {
  const isDesktop = useMediaQueryResult('(min-width: 768px)');

  return (
    <header className={clsx(s.TopBar, className)}>
      <Box className={clsx(s.TopBar__content, classNameContent)}>
        <div className={s.TopBar__contentLeft}>
          <Link to={'/'} className={s.TopBar__logo}>
            {isDesktop ? logoLarge : logoSmall}
          </Link>
          {slotLeft}
        </div>
        {slotRight}
      </Box>
    </header>
  );
}
