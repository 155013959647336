import clsx from 'clsx';
import React from 'react';
import { Card } from '../Card/Card';

import s from './Table.module.scss';

type Props = {
  children?: React.ReactNode;
  titles?: Array<string>;
  className?: string;
  classNameTitle?: string;
  classNameTitles?: string;
};
export const Table: React.FC<Props> = ({ children, titles, className, classNameTitle, classNameTitles }) => {
  const showTitles = titles?.map((title) => {
    return (
      <th key={title} className={clsx(s.Table__title, classNameTitle)}>
        {title}
      </th>
    );
  });

  return (
    <Card className={clsx(s.Table__wrapper, className)}>
      <table className={s.Table}>
        {titles && <tr className={clsx(s.Table__titles, classNameTitles)}>{showTitles}</tr>}
        {children && children}
      </table>
    </Card>
  );
};

type TableRowProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
  className?: string;
};
export const TableRow: React.FC<TableRowProps> = ({ onClick, children, className }) => {
  return (
    <tr onClick={onClick} className={clsx(s.Table__row, className)}>
      {children}
    </tr>
  );
};

type TableItemProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableDataCellElement>;
  className?: string;
};
export const TableItem: React.FC<TableItemProps> = ({ children, onClick, className }) => {
  return (
    <td onClick={onClick} className={clsx(s.Table__item, className)}>
      {children}
    </td>
  );
};
