import { useState } from 'react';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { Manager, Popper, Reference } from 'react-popper';

import { EventItem, EventType } from 'routes/calendar/_temporaryEventsData';


import { ScheduleEvent } from 'common/components/ui/ScheduleEvent/ScheduleEvent';
import { CalendarType } from './Calendar';
import { CalendarModal } from './CalendarModal';
import s from './CalendarGrid.module.scss';

moment.locale('ru');

type Props = {
  startDay: moment.Moment;
  today: moment.Moment;
  days: CalendarType;
  items: Array<EventItem>;
  dayArr: Array<any>;
  isEvents: boolean;
  toggleNotification: () => void;
  toggleParticipants: () => void;
};
export const CalendarGrid: React.FC<Props> = ({
  days,
  items,
  dayArr,
  startDay,
  today,
  isEvents,
  toggleNotification,
  toggleParticipants
}) => {
  const [chosenEvent, setChosenEvent] = useState<string | null>(null);
  const time = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'];

  // items.map((item, index) => {
  //   if (time.length) {
  //     if (
  //       !time.some(
  //         (timeItem) => moment.unix(timeItem.dateFrom).format('hh:mm') === moment.unix(item.dateFrom).format('hh:mm')
  //       )
  //     ) {
  //       time.push({ dateFrom: item.dateFrom, dateTo: item.dateTo });
  //     }
  //   } else {
  //     time.push({ dateFrom: item.dateFrom, dateTo: item.dateTo });
  //   }
  // });

  const isCurrentDay = (day) => {
    return moment().isSame(day, 'day');
  };
  const isCurrentMonth = (day) => today.isSame(day, 'month');

  const comparisonTime = (myTime: Moment, item: { dateFrom: number; dateTo: number }) => {
    return myTime.isSameOrAfter(moment.unix(item.dateFrom)) && myTime.isSameOrBefore(moment.unix(item.dateTo));
  };

  // const timeNum = (myItem: number) => {
  //   return time.map((item) => item.dateFrom).indexOf(myItem);
  // };

  // const timeNumLine = (myTime: Moment) => {
  //   let indexNum: any = 0;
  //   time.filter((item) => {
  //     if (comparisonTime(myTime, item)) {
  //       indexNum = item.dateFrom;
  //     }
  //   });
  //   const index = time.map((item) => item.dateFrom).indexOf(indexNum);
  //   return index;
  // };

  const isSomeDateFrom = (dateFrom, id) => {
    return items.some((i) => i.dateFrom === dateFrom && i.id !== id);
  };

  const showTime = time
    // .sort((left, right) => {
    //   return moment.utc(left.dateFrom).diff(moment.utc(right.dateFrom));
    // })
    .map((item) => (
      <div
        className={clsx(s.CalendarGrid__time_item, {
          // [s.CalendarGrid__time_active]: comparisonTime(moment(), item)
        })}>
        {item}
      </div>
    ));

  const showEvents = dayArr.map((item, index) => (
    <div
      className={clsx(s.CalendarGrid__item, {
        [s.CalendarGrid__item_active]: isCurrentMonth(item),
        [s.CalendarGrid__item_weekend]: item.day() === 1 || item.day() === 0
      })}>
      <div key={index} className={clsx(s.CalendarGrid__dayInWeek)} style={{ marginTop: index >= 7 ? `16px` : '0' }}>
        <span className={s.CalendarGrid__dayInWeek_day}>{item.format('D MMMM')}</span>
        {index < 7 && (
          <div className={s.CalendarGrid__dayInWeek_name_wrapper}>
            <span
              className={clsx(s.CalendarGrid__dayInWeek_name, {
                [s.CalendarGrid__dayInWeek_name_active]: isCurrentDay(item)
              })}>
              {moment(item).add(1, 'd').format('dd')}
            </span>
          </div>
        )}
      </div>
      <div
        className={clsx(s.CalendarGrid__events, {
          [s.CalendarGrid__events_flex]: isSomeDateFrom(item.dateFrom, item.id)
        })}>
        {items
          .filter(
            (event) => event.dateFrom >= item.format('X') && event.dateFrom <= item.clone().endOf('day').format('X')
          )
          .map((event, indexEvent) => {
            const content = (
              <>
                <Manager>
                  <Reference>
                    {({ ref }) => (
                      <ScheduleEvent
                        onClick={() => setChosenEvent(event.id)}
                        active={chosenEvent === event.id}
                        {...event}
                        isMonth={days === CalendarType.Month}
                        isFlex={isSomeDateFrom(event.dateFrom, event.id)}
                        className={s.CalendarGrid__event}
                        ref={ref}
                      />
                    )}
                  </Reference>
                  <Popper placement="left">
                    {({ ref, style }) =>
                      chosenEvent === event.id && (
                        <div>
                          <CalendarModal
                            toggle={() => setChosenEvent(null)}
                            toggleNotification={toggleNotification}
                            toggleParticipants={toggleParticipants}
                            {...event}
                            visible={chosenEvent === event.id}
                            ref={ref}
                            style={style}
                          />
                        </div>
                      )
                    }
                  </Popper>
                </Manager>
              </>
            );

            return event.type !== EventType.Event ? content : isEvents ? content : null;
          })}
      </div>
    </div>
  ));

  return (
    <div className={clsx(s.CalendarGrid__wrapper, { [s.CalendarGrid__wrapper_month]: days === CalendarType.Month })}>
      {days === CalendarType.Week && (
        <div
          className={clsx(s.Schedule__line, { [s.Schedule__line_show]: false })}
          // style={{ position: 'absolute', top: `calc(${(timeNumLine(moment()) * 100) / time.length}% + 31px)` }}
        />
      )}
      {days === CalendarType.Week && (
        <div className={s.CalendarGrid__time}>
          <div className={s.CalendarGrid__time_item} />
          {showTime}
        </div>
      )}
      <div>
        <div className={clsx(s.CalendarGrid__grid, s.CalendarGrid__calendar)}>{showEvents}</div>
      </div>
    </div>
  );
};
