import {
  defineQueryTransformers,
  ExtractTransformedQueryParams,
  IQueryTransformer
} from '@proscom/prostore-react-router';

// Рекомендуется перечислять все query-параметры, используемые в приложении,
// в виде констант и использовать их только через эти константы.
// Так легче находить места в коде, где они используются.

export const URL_KEY_FILTER = 'filter' as const;

// Трансформатор для булева значения
const booleanTransformer: IQueryTransformer<boolean | undefined> = {
  parse(value) {
    return value === 'true' ? true : value === 'false' ? false : undefined;
  },
  stringify(value: boolean | undefined) {
    return value === true ? 'true' : value === false ? 'false' : undefined;
  }
};

// Трансформеры определяют, как парсить и сериализовать query-параметры из адресной строки
export const urlTransformers = defineQueryTransformers({
  [URL_KEY_FILTER]: booleanTransformer
});

type UrlTransformersType = typeof urlTransformers;

export type TransformedQueryParams = ExtractTransformedQueryParams<UrlTransformersType>;
