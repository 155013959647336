import clsx from 'clsx';
import React from 'react';
import s from './UserInfo.module.scss';

export interface IUserInfoProps {
  name: string;
  about: string;
  photo: string;
  className?: string;
}

export function UserInfo({ name, about, photo, className }: IUserInfoProps) {
  return (
    <div className={clsx(s.UserInfo, className)}>
      <img className={s.UserInfo__photo} src={photo} alt={name} />
      <div className={s.UserInfo__content}>
        <h2 className={s.UserInfo__name}>{name}</h2>
        <p className={s.UserInfo__about}>{about}</p>
      </div>
    </div>
  );
}
