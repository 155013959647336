import React from 'react';
import { AppColor } from '../../store/app';
import { ReactComponent as CalendarIcon } from './serviceIcons/calendar.svg';
import { ReactComponent as BrainIcon } from './serviceIcons/brain.svg';
import { ReactComponent as ContentIcon } from './serviceIcons/content.svg';
import { ReactComponent as HierarchyIcon } from './serviceIcons/hierarchy.svg';
import { ReactComponent as HomeIcon } from './serviceIcons/home.svg';
import { ReactComponent as ChartIcon } from './serviceIcons/chart.svg';
import { ReactComponent as MobileIcon } from './serviceIcons/mobile.svg';
import calcHero from './serviceImages/calcHero.png';
import calcPoints from './serviceImages/calcPoints.png';
import lkHero from './serviceImages/lkHero.png';
import lkPoints from './serviceImages/lkPoints.png';
import lmsHero from './serviceImages/lmsHero.png';
import lmsPoints from './serviceImages/lmsPoints.png';
import mobileHero from './serviceImages/mobileHero.png';
import mobilePoints from './serviceImages/mobilePoints.png';

export interface ITag {
  title: string;
  color: string;
  backgroundColor?: string;
}

export interface IService {
  id?: string;
  title: string;
  heroImage?: string;
  description: string;
  descriptionShort?: string;
  points?: [string, string[]][];
  pointsImage?: string;
  tags: ITag[];
  icon: string;
  iconColor?: AppColor;
  to?;
  universityAdmission?: number;
}

export const Tag: { [tag: string]: ITag } = {
  student: {
    title: 'Студентам',
    color: '#53C120'
  },
  teacher: {
    title: 'Преподавателям',
    color: '#1ABCDF'
  },
  university: {
    title: 'Вузу',
    color: '#EE61E0'
  }
};

export const services: IService[] = [
  {
    id: '1',
    icon: 'fonts-in-shape',
    iconColor: AppColor.Purple,
    title: 'LMS',
    description: 'Система обучения нового поколения, обеспечивающая подготовку на новом высоком уровне качества ',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '2',
    icon: 'phone',
    iconColor: AppColor.Green,
    title: 'Мобильное приложение',
    description: 'Реализация функциональных возможностей ключевых систем и сервисов на мобильной платформе',
    tags: [Tag.student, Tag.university]
  },
  {
    id: '3',
    icon: 'phone',
    iconColor: AppColor.Pink,
    title: 'Учетная система',
    description: 'Сервис для совместной работы и организации процессов в компании, а также контроля выполнения задач',
    tags: [Tag.university]
  },
  {
    id: '4',
    icon: 'message',
    iconColor: AppColor.Pink,
    title: 'Поступи в вуз онлайн',
    description: 'Подать документы в вузы без личного посещения приемных комиссий, бумажных заявлений и очередей',
    tags: [Tag.university],
    to: '/university-admission',
    universityAdmission: 0
  },
  {
    id: '5',
    icon: 'diagram-pie',
    iconColor: AppColor.Green,
    title: 'Управленческие дашборды',
    description: 'Интерактивный аналитический сервис представления данных для принятия решений руководством',
    tags: [Tag.teacher, Tag.university]
  },
  {
    id: '6',
    icon: 'home',
    iconColor: AppColor.Yellow,
    title: 'Личный кабинет',
    description: 'Мультисервисное «единое окно» — та «Точка», где все пользователи могут получить доступ к услугам ',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '7',
    icon: 'folder-in-shape',
    iconColor: AppColor.Red,
    title: 'Документы и справки',
    description: 'Сервис позволяет получить все документы и справки необходимые в учебном процессе',
    tags: [Tag.teacher, Tag.university]
  },
  {
    id: '8',
    icon: 'file',
    iconColor: AppColor.Green,
    title: 'Яндекс.Коннект',
    description:
      'Готовая инфраструктура для общения и совместной работы внутри компании, включает в себя сервисы почты, диска, Вики',
    tags: [Tag.student, Tag.teacher]
  },
  {
    id: '9',
    icon: 'list',
    iconColor: AppColor.Pink,
    title: 'Яндекс.Трекер',
    description: 'Сервис для совместной работы и организации процессов в компании, а также контроля выполнения задач',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '10',
    icon: 'user-in-shape',
    iconColor: AppColor.Green,
    title: 'Яндекс.Телемост',
    description:
      'Сервис видеовстреч. С помощью сервиса можно совершать видеозвонки и приглашать участников по ссылке. ',
    tags: [Tag.student, Tag.teacher]
  },
  {
    id: '11',
    icon: 'user-in-shape',
    iconColor: AppColor.BlueLight,
    title: 'Яндекс.ID',
    description: 'Единый аккаунт для Яндекса и сайтов, которые поддерживают его авторизацию. ',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '12',
    icon: 'folder-in-shape',
    iconColor: AppColor.Purple,
    title: 'Обеспечение ИБ',
    description:
      'Обеспечение информационной безопасности  включает в себя применение современных комплексных защиты информации',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '13',
    icon: 'eye-3',
    iconColor: AppColor.Dark,
    title: 'CJM',
    description:
      'Инструмент, позволяющий визуализировать, анализировать и  улучшать пользовательский опыт и клиентский сервис',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '14',
    icon: 'brain',
    iconColor: AppColor.Dark,
    title: 'Конкурс ППС',
    description:
      'Система поддержки внутренних кадровых конкурсов служит для оптимизации проведения выборов на должность',
    tags: [Tag.student, Tag.teacher, Tag.university]
  },
  {
    id: '15',
    icon: 'book',
    iconColor: AppColor.Dark,
    title: 'Мониторинг публикационной активности',
    description: 'Предметом оценки является количество и качество публикаций научных сотрудников',
    tags: [Tag.student, Tag.teacher, Tag.university]
  }
];
// prettier-ignore
// export const services: IService[] = [{
//   id: 'lk',
//   icon: HomeIcon,
//   tags: [Tag.student, Tag.teacher, Tag.university],
//   heroImage: lkHero,
//   title: 'Личный кабинет абитуриента / студента / преподавателя',
//   description: 'Личный кабинет – это мультисервисное «единое окно» – та «Точка», где все пользователи могут получить доступ к административным услугам и сервисам, связаться с поддержкой и решить возникающие вопросы и проблемы',
//   pointsImage: lkPoints,
//   points: [
//     ['Для обучающихся и слушателей программ ДПО', [
//       'Возможность видеть расписание на главном экране, доступ к полной информации об Университете',
//       'Доступ к студенческим сервисам',
//       'Возможность получить справку, подать заявку на мероприятие и т.п.'
//     ]],
//     ['Для выпускников', [
//       'Доступ к студенческим сервисам',
//       'Сохранение связи с альма-матер'
//     ]],
//     ['Для преподавателей', [
//       'Доступ к сервисам преподавателей',
//       'Возможность просмотра расписания на главном экране'
//     ]],
//     ['Для сотрудников', [
//       'Доступ к административным сервисам',
//       'Наглядная информация по совершенным заявкам и активным задачам',
//       'Возможность просмотра информации о коллегах, их контактах и организационной структуре Университета'
//     ]],
//     ['Другие преимущества', [
//       'Применение единой системы авторизации',
//       'Наличие персонализированной возможности настройки своего личного пространства',
//       'Показ подборки главных новостей Университета, подробных инструкций и ответов на часто задаваемые вопросы'
//     ]]
//   ]
// }, {
//   id: 'mobile',
//   icon: MobileIcon,
//   tags: [Tag.student, Tag.teacher, Tag.university],
//   heroImage: mobileHero,
//   title: 'Мобильное приложение',
//   description: 'Мобильные приложения – это философия легкости, удобства и доступности. Прежде всего, это реализация функциональных возможностей ключевых систем и сервисов на мобильной платформе',
//   pointsImage: mobilePoints,
//   points: [
//     ['Сервисы мобильного приложения', [
//       'Для обучающихся и преподавателей - мобильные приложения с информацией об учебном процессе: расписания, поиск преподавателей и аудиторий, совместное планирование дедлайнов и многое другое',
//       'Для слушателей программ ДПО- подробный мобильный навигатор с картой по зданиям вуза: схемы, поиск аудиторий, построение маршрута, информация и многое другое',
//       'Для сотрудников вуза и руководителей - дублирование возможностей ключевых систем и сервисов в мобильном телефоне для того, чтобы всегда оставаться на связи и в курсе дел'
//     ]],
//     ['Преимущества', [
//       'Доступность - все возможности для обучения и работы в вашем смартфоне или планшете в любое время суток и из любой точки Земли',
//       'Мультифункциональность - все самые востребованные системы и сервисы умещаются в паре иконок на экране телефона',
//       'Удобство - оптимизированная архитектура приложений и интуитивно простые интерфейсы позволяют легко и быстро использовать востребованные функции'
//     ]]
//   ]
// }, {
//   id: 'lms',
//   icon: ChartIcon,
//   tags: [Tag.student, Tag.teacher, Tag.university],
//   heroImage: lmsHero,
//   title: 'LMS',
//   description: 'Система обучения нового поколения, обеспечивающая подготовку специалистов на новом высоком уровне качества образования',
//   pointsImage: lmsPoints,
//   points: [
//     ['Преимущества LMS', [
//       'Позволяет создавать цифровое портфолио и индивидуальные траектории обучения, управлять «своими» курсами через наглядный образовательный маркетплейс и многое другое',
//       'Является технологически современной средой для создания учебных материалов, применяя новый взгляд на привычную работу с обучающимися («геймификация» учебного процесса, электронные ведомости, адаптивные сценарии обучения)',
//       'Обеспечивает управление образовательным процессом на всех этапах, отчетность и статистику, применяя семантические связи междисциплинарного и внутрикурсового контента и многое другое',
//       'Предоставление точной и наглядной отчетности по всем направлениям работ для руководства вуза'
//     ]]
//   ]
// }, {
//   id: 'calc',
//   icon: ChartIcon,
//   tags: [Tag.teacher, Tag.university],
//   heroImage: calcHero,
//   title: 'Учетная система',
//   description: 'Учетная система – это система бухгалтерско-кадрового учета, представляет собой набор взаимосвязанных подсистем, решающих задачи финансового управления и управления людскими ресурсами',
//   pointsImage: calcPoints,
//   points: [
//     ['Основа системы', [
//       'Проработка учетных и управленческих функций для решения задач предприятия',
//       'Опора на современные технологии и лучший мировой опыт',
//       'Оперативная поддержка всех изменений в законодательстве, устойчивость и надежность'
//     ]]
//   ]
// }, {
//   id: 'red',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.teacher, Tag.university],
//   title: 'Красная кнопка',
//   description: 'Красная кнопка - это горячая линия. Воспользовавшись ею любой участник образовательного процесса (студент, преподаватель, сотрудник) может оставить описание возникшей проблемы, выразить свое отношение к той или иной стороне учебного процесса, обратиться к администрации Университета с предложением по улучшению деятельности. \n' +
//     'Подача обращений производится в онлайн-формате, есть возможность выбора анонимного или авторизованного способа подачи. Ответ направляется на электронную почту, указанную при подаче обращения.',
//   points: [
//     ['Цели организации работы Красной кнопки', [
//       'Сбор информации о качестве оказываемых в Университете  образовательных услуг',
//       'Предоставление возможности студентам и преподавателям выразить свое отношении к той или иной стороне учебного процесса',
//       'Предоставление возможности обратиться к администрации Университета с предложением по улучшению деятельности'
//     ]]
//   ]
// }, {
//   id: 'super',
//   icon: MobileIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Суперсервис – поступи в ВУЗ онлайн',
//   description: 'Суперсервис «Поступление в вуз онлайн» позволяет абитуриентам подать документы в вузы без личного посещения приемных комиссий, бумажных заявлений и очередей.\n' +
//     'Все функции доступны из личного кабинета на Госуслугах и не требуют посещения вуза.',
//   points: [
//     ['Возможности абитуриентов при использовании сервиса', [
//       'Выбор вузов для поступления',
//       'Направление заявления о зачислении с приложением необходимых документов',
//       'Информация о датах дополнительных вступительных испытаний',
//       'Отслеживание своего места в конкурсных списках',
//       'Управление согласием на зачисление',
//       'Внесение изменений в заявление',
//       'Информация о зачислении, получив уведомление от вуза'
//     ]]
//   ]
// }, {
//   id: 'connect',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Яндекс.Коннект',
//   description: 'Яндекс.Коннект - это готовая инфраструктура для общения и совместной работы внутри компании, включает в себя сервисы почты, диска, Вики, форм.Яндекс.Коннект - это готовая инфраструктура для общения и совместной работы внутри компании, включает в себя сервисы почты, диска, Вики, форм',
//   points: [
//     ['Функционал системы', [
//       'Единое виртуальное пространство для общения, хранения знаний и работы над задачами',
//       'Почтовые адреса в домене организации и доступность контактов сотрудников',
//       'Яндекс.Диск – облачное хранилище для рабочих файлов с возможностью быстро ими делиться с коллегами, контроль доступа к файлам',
//       'Каталог карточек отражающий структуру организации, информационные карточки содержат номер телефона, почту, информацию о том, в каком отделе и в каких командах  работают сотрудники',
//       'Сервис Вики – для сбора актуального описания задач и проектов, справочных данных и т.п., настройка прав доступа',
//       'Возможность организации учётных записей в систему, отражающую структуру компании'
//     ]]
//   ]
// }, {
//   id: 'tracker',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Яндекс.Трекер',
//   description: 'Яндекс.Трекер – это сервис для совместной работы и организации процессов в компании, а также контроля выполнения задач в подразделениях',
//   points: [
//     ['Возможности сервиса', [
//       'Для каждой задачи заводится отдельная страница с описанием и возможностью обсуждения. Задачи разных отделов группируются по очередям. Есть возможность настройки фильтров',
//       'Для наглядности процесса существуют дашборды, на которых собирается основная информация и статистика по всем задачам',
//       'Трекер позволяет создавать Agile-доски, планировать спринты, оценивать трудоемкость задач, управлять задачами, следить за их выполнением на burndown-диаграмме',
//       'Возможность создания шаблонов типовых задач, настройки напоминаний о задаче, настройки уведомлений о срочности выполнения и отслеживанием редактирования',
//       'Настройки позволяют перенаправить все заявки от клиентов (идеи, вопросы, жалобы и тд) или сотрудников (заявка на отпуск, справку о доходах и тд), приходящие на почту, в Трекер и обработать их внутри сервиса. Заявки можно собирать через интегрированный сервис Яндекс.Формы',
//       'Если раннее использовался другой сервис управления проектами, то в Трекер можно перенести все данные с помощью API Яндекс.Трекера',
//       'Возможность использовать мобильное приложение. Доступно для iOS и Android',
//       'Возможность подключить репозитории и отслеживать все коммиты по задачам в GitHub и BitBucket',
//       'Плагин для IntelliJ IDE'
//     ]]
//   ]
// }, {
//   id: 'tele',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Яндекс.Телемост',
//   description: 'Яндекс.Телемост – это сервис видеовстреч. С помощью сервиса можно совершать видеозвонки и приглашать участников по ссылке',
//   points: [
//     ['Особенности сервиса', [
//       'Предоставляет возможность общаться без регистрации в системе',
//       'Работает в Яндекс.Браузере, Google Chrome, а также совместим с Yandex.mail и Яндекс.Диском, для разговора не нужно дополнительно устанавливать никаких приложений',
//       'Войти в кабинет видеоконференции можно с помощью ссылки-приглашения, доступные в течение 24 часов',
//       'Допустимое число участников 35 человек',
//       'Пользователи могут отключать микрофон и камеру, делиться экраном, время вызова не ограничено',
//       'Есть возможность показать свой экран другим участникам встречи'
//     ]]
//   ]
// }, {
//   id: 'yid',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Яндекс.ID',
//   description: 'Яндекс ID — это единый аккаунт для Яндекса и сайтов, которые поддерживают его авторизацию. Многие сервисы Яндекса доступны только после регистрации (Почта, Диск или Вебмастер), с единым аккаунтом при входе на Почту или регистрации на других сервисах Яндекса активируется аккаунт-ID',
//   points: [
//     ['Особенности Яндекс ID', [
//       'При необходимости в смене пароля или редактировании личной информации, достаточно сделать это всего один раз в Яндекс ID на странице Управление аккаунтом — и изменения автоматически отобразятся во всех сервисах',
//       'Есть возможность привязать к Яндекс ID номер телефона и включить двухфакторную аутентификацию'
//     ]]
//   ]
// }, {
//   id: 'dashboards',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Управленческие дашборды',
//   description: 'Управленческие дашборды - интерактивный аналитический сервис визуального представления массивов данных для поддержки принятия управленческих решений руководством организации и структурных подразделений',
//   points: [
//     ['Функции дашбордов', [
//       'Автоматизация сбора и формирования регламентированной отчетности в реальном времени',
//       'Визуализация структурированных данных',
//       'Оптимизация и ускорение процессов объединения различных сведений и составления иерархичной системы сравнения информационных блоков'
//     ]]
//   ]
// }, {
//   id: 'cjm',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'CJM',
//   description: 'Карта клиентского пути – инструмент, позволяющий визуализировать, анализировать и  улучшать пользовательский опыт и клиентский сервис на всем жизненном цикле цифровых продуктов, а также трансформировать внутренние бизнес-процессы',
//   points: [
//     ['Возможности использования карты', [
//       'Управление системой клиентского опыта в бизнесе',
//       'Управление эмоциональным интеллектом для решения бизнес-задач и выстраивания эффективных коммуникации',
//       'Сегментация и формирование портретов клиентов по методике персона-модель',
//       'Организация сбора информации и проведение глубинных структурированных интервью',
//       'Учет особенностей представления результатов исследований разным целевым аудиториям',
//       'Использование инструментов вовлечения сотрудников и руководителей для повышения эффективности коммуникаций',
//       'Реализация принципов обратной связи с клиентами в разных точках контакта'
//     ]]
//   ]
// }, {
//   id: 'pps',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Конкурс ППС',
//   description: 'Система поддержки внутренних кадровых конкурсов служит для оптимизации проведения выборов на должность и является частью системы поддержки конкурсов на замещение должностей профессорско-преподавательского состава',
//   points: [
//     ['Возможности системы для проведения конкурса ППС', [
//       'Подача конкурсных документов в электронном виде',
//       'Консультирование претендентов',
//       'Возможность организации онлайн-интервью'
//     ]],
//     ['Ключевые функции сервиса для конкурса ППС', [
//       'Проведение голосования по конкурсному отбору на должности ППС',
//       'Формирование протокола с результатами голосования'
//     ]]
//   ]
// }, {
//   id: 'publishing',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Мониторинг публикационной активности',
//   description: 'Предметом оценки публикационной активности является количество и качество публикаций научных сотрудников за определённый промежуток времени, внесенные в базу публикаций корпоративного сайта',
//   points: [
//     ['Особенности', [
//       'Сервис по мониторингу публикационной активности обеспечивает наблюдение и анализ публикационной активности подразделений и сотрудников организации',
//       'Развитие публикационной активности в организации представляет собой сложную задачу, направленную на развитие академических навыков работников, привлечение наиболее сильных работников с академического рынка, а также построение системы стимулов и контроля за результатами',
//       'Рейтинги должны играть роль инструментов, отражающих текущее состояние университетов и динамику их развития',
//       'Методики расчета метрик рейтингов должны быть открытыми и воспроизводимыми',
//       'В разработке метрик следует руководствоваться лучшими международным практиками, тщательно выбирая показатели, учитывающие проблемы недобросовестных публикаций и «накруток»'
//     ]]
//   ]
// }, {
//   id: 'sop',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'СОП',
//   description: 'Студенческая оценка преподавания – инструмент по организации SET (student evaluation of teaching) и рассматривается как одна из ключевых мер, направленных на постоянное развитие качества преподавания и улучшение качества планирования и организации учебного процесса',
//   points: [
//     ['Формат проведения СОП', [
//       'СОП проводится в установленные промежутки времени',
//       'Студентам предлагается оценить учебные дисциплины (с ведущими их преподавателями), по которым за этот период выставляется оценка. Кроме того, в отношении дисциплин, преподавателей и учебного процесса в целом студенты могут оставить текстовые комментарии в свободной форме',
//     ]]
//   ]
// }, {
//   id: 'ai-iot',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'ИОТ на базе ИИ',
//   description: 'Интернет вещей на базе искусственного интеллекта - гибрид Интернета вещей и искусственного интеллекта, получившего название AIoT (AI + IoT): устройства',
//   points: [
//     ['Особенности ИОТ', [
//       'ИОТ собирают необходимые данные, а эффективный анализ информации без задержек позволяет осуществлять использование искусственного интеллекта (ИИ)',
//       'ИОТ представляет собой технологии, облачных, распределенных и встраиваемых систем, программно-определяемых радиосистем и сетей, применяемых к  устройствам. При передаче данных с устройств ИОТ в централизованное хранилище, может возникать проблема с задержкой их передачи, даже несмотря на то, что скорость соединения постоянно увеличивается, характеристики этого процесса не соответствуют имеющемуся росту данных. Передача необработанных данных также снижает общую производительность системы. Применение технологии искусственного интеллекта позволяют решить проблему  ускоренной обработкой больших данных',
//       'Для внедрения указанной технологии необходима оптимизации решений для обеспечения  хранения данных и организации памяти и наличие платформы для ИИ'
//     ]]
//   ]
// }, {
//   id: 'library',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Цифровая библиотека',
//   description: 'Цифровая библиотека – это электронная библиотека с цифровым контентом, представляет собой информационную систему, основным назначением которой является доступ к цифровым материалам, содержит базу данных цифровых объектов, которые могут включать в себя текст, неподвижные изображения, аудио, видео, цифровые документы или другие цифровые мультимедийные форматы',
//   points: [
//     ['Особенности цифровых библиотек', [
//       'Отсутствие физических границ - Цифровая библиотека может предоставлять доступ к материалам из любой точки мира при наличии подключения к Интернету',
//       'Круглосуточная доступность',
//       'Множественный доступ',
//       'Доступный поиск информации (использование любого поискового запроса для поиска по всей коллекции',
//       'Сохранение и консервация (оцифровка)',
//       'Практически бесконечное пространство для хранения',
//       'Добавленная стоимость (некоторые характеристики объектов могут быть улучшены)'
//     ]]
//   ]
// }, {
//   id: 'analytics',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Аналитика, включая ИИ',
//   description: 'Аналитика комплексного назначения',
//   points: [
//     ['Функционал системы аналитики', [
//       'Информационная система для поддержки принятия управленческих решений',
//       'Ведение дорожных карт',
//       'Сбор данных в корпоративное хранилище данных',
//       'Анализ данных и формирование отчетности',
//       'Автоматизация сбора и формирования регламентированной отчетности',
//       'Использование элементов искусственного интеллекта в управление организацией',
//       'Потоковая обработка данных',
//       'Сквозная аналитика',
//       'Отчетность в реальном времени',
//       'Обеспечение АРМов руководителей',
//       'Автоматизированная сдача отчетности в органы исполнительной власти'
//     ]]
//   ]
// }, {
//   id: 'integration',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Интеграционные инструменты и данные',
//   description: 'Интеграция данных — это процесс объединения данных из разных источников. Используется для анализа, бизнес-аналитики, отчетности',
//   points: [
//     ['Инструменты интеграции', [
//       'Интеграционная сервисная шина (и различные проверки)',
//       'Система управления мастер-данными',
//       'Системы управления проектами и бизнес-процессами'
//     ]],
//     ['Преимущества внедрения интеграционных инструментов', [
//       'Единое хранилище основных данных. Консолидированные данные из всех подключенных информационных систем',
//       'Нормализация данных любого характера',
//       'Онлайн-актуализация данных в подключенных системах',
//       '«Золотые» записи. Возможность консолидации всех данных по физическим и юридическим лицам. Является хорошим фундаментом для «Цифрового следа»',
//       'Наличие механизма дедубликации данных в системах',
//       'Наличие гибкого механизма проверок и обработок на наличие некорректно заполненных данных',
//       'Ведение единой системы управления проектами и бизнес-процессами'
//     ]]
//   ]
// }, {
//   id: 'cloud',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Облачная инфраструктура и инфраструктурные сервисы Яндекс.Облако',
//   description: 'Яндекс.Облако – это набор связанных сервисов, облачная платформа представляющая  пользователям в формате «as a service» набор инфраструктурных сервисов',
//   points: [
//     ['Сервисы Яндекс.Облако', [
//       'Сервис для создания API-шлюзов',
//       'Балансировщики нагрузки уровня приложения',
//       'Сервис для управления TLS-сертификатами',
//       'Сервис бессерверных вычислений',
//       'Сервис управления выделенными сетевыми соединениями',
//       'Сервис Облачных вычислений и др.'
//     ]]
//   ]
// }, {
//   id: 'support',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Техническая поддержка',
//   description: 'Обеспечение поддержки клиентов до, во время и после того, как они покупают или используют продукты или услуги осуществляет система CRM',
//   points: [
//     ['Ключевые функции', [
//       'Единое место хранения коммуникации: письма, звонки, встречи и т.д., систематизация данных из разных источников в одном месте',
//       'Реализация продуктовой линейки',
//       'Настройка бизнес-процессов',
//       'Интеграция с площадками маркетинговых партнеров и образовательными площадками',
//       'Аналитика маркетинговых компаний',
//       'Работа с социальными сетями',
//       'Управление маркетинговыми компаниями и лояльностью клиентов'
//     ]]
//   ]
// }, {
//   id: 'ib',
//   icon: BrainIcon,
//   tags: [Tag.student, Tag.university],
//   title: 'Обеспечение ИБ',
//   description: 'Обеспечение информационной безопасности  включает в себя применение современных комплексных методов и средств защиты информации.\n' +
//     'Для обеспечения ИБ используется Сервис смены / восстановления и сброса пароля от учетных записей пользователей, содержащих конфиденциальную информацию, также инструменты ИБ',
//   points: [
//     ['Инструменты ИБ', [
//       'VPN подключение к сети организации с применением двухфакторной аутентификации - технология, которая обеспечивает закрытую от внешнего доступа связь',
//       'Возможность получения доступа к данным на компьютере уволившегося работника - копирование данных с его компьютера или из почтового ящика',
//       'Обеспечение канала связи для сообщения об инцидентах ИБ',
//       'Перевод учетных записей пользователей в единый защищенный контур',
//       'Инъектирование скриптов защиты от взлома в SSO систему'
//     ]]
//   ]
// }];

export const fakeServices: IService[] = [
  {
    icon: 'ContentIcon',
    tags: [Tag.student, Tag.teacher, Tag.university],
    title: 'Документы и справки',
    description: 'Сервис позволяет получить все документы и справки необходимые в учебном процессе'
  },
  {
    icon: 'CalendarIcon',
    tags: [Tag.student, Tag.teacher, Tag.university],
    title: 'Мероприятия',
    description: 'Следите за анонсами мероприятий и формируйте свой календарь'
  },
  {
    icon: 'HierarchyIcon',
    tags: [Tag.student, Tag.teacher, Tag.university],
    title: 'Структура Университета',
    description: 'Отображение структуры и иерархии вашего университета'
  }
];
