import { useFormik } from 'formik';
import { useState } from 'react';

import { Button } from 'common/components/ui/Button/Button';
import { EnrollmentDropdown, EnrollmentDropdownStatus } from '../EnrollmentDropdown/EnrollmentDropdown';
import { Enrollment2ForeignEducation } from './Enrollment2ForeignEducation';
import { Enrollment2GeneralInformation } from './Enrollment2GeneralInformation';
import { Enrollment2OtherDocuments } from './Enrollment2OtherDocuments';
import { Enrollment2RegisteredData } from './Enrollment2RegisteredData';
import s from './Enrollment2.module.scss';

type Props = {
  setChosenItem: (number: number) => void;
};
export const Enrollment2: React.FC<Props> = ({ setChosenItem }) => {
  const [isGeneralInformation, setIsGeneralInformation] = useState(false);
  const [isRegisterData, setIsRegisterData] = useState(false);
  const [isForeignEducation, setIsForeignEducation] = useState(false);
  const [isOtherDocuments, setIsOtherDocuments] = useState(false);

  const initialValues = {
    surname: '',
    name: '',
    patronymic: '',
    citizenship: '',
    phone: ''
  };
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      setChosenItem(2);
    }
  });

  const items = [
    {
      title: 'Общая информация',
      status: EnrollmentDropdownStatus.Completed,
      contentType: 'general_information',
      setVal: setIsGeneralInformation,
      active: isGeneralInformation
    },
    {
      title: 'Регистрационные данные',
      status: EnrollmentDropdownStatus.Completed,
      contentType: 'registered_data',
      setVal: setIsRegisterData,
      active: isRegisterData
    },
    {
      title: 'Иностранное образование',
      status: EnrollmentDropdownStatus.Completed,
      contentType: 'foreign_education',
      setVal: setIsForeignEducation,
      active: isForeignEducation
    },
    {
      title: 'Другие документы',
      status: EnrollmentDropdownStatus.Completed,
      contentType: 'other_documents',
      setVal: setIsOtherDocuments,
      active: isOtherDocuments
    }
  ];

  const showItems = items.map((item) => {
    return (
      <EnrollmentDropdown
        title={item.title}
        status={item.status}
        content={
          item.contentType === 'general_information' ? (
            <Enrollment2GeneralInformation
              values={{
                surname: formik.values.surname,
                name: formik.values.name,
                patronymic: formik.values.patronymic,
                citizenship: formik.values.citizenship,
                phone: formik.values.phone
              }}
              onChange={formik.handleChange}
            />
          ) : item.contentType === 'registered_data' ? (
            <Enrollment2RegisteredData />
          ) : item.contentType === 'foreign_education' ? (
            <Enrollment2ForeignEducation />
          ) : item.contentType === 'other_documents' ? (
            <Enrollment2OtherDocuments />
          ) : (
            ''
          )
        }
        setVal={item.setVal}
        active={item.active}
        key={item.title}
        className={s.Enrollment2__dropdown}
      />
    );
  });

  return (
    <div className={s.Enrollment2}>
      <div className={s.Enrollment2__title}>Заявка на признание документа об образовании</div>
      <div className={s.Enrollment2__items}>{showItems}</div>
      <div className={s.Enrollment2__button_wrapper}>
        <Button onClick={formik.handleSubmit}>Далее</Button>
      </div>
    </div>
  );
};
