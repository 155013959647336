import { useEffect } from 'react';

export function usePreventBodyScroll(prevent?: boolean) {
  useEffect(() => {
    document.body.style.overflow = prevent ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [prevent]);
}
