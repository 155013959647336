import { Icon } from 'common/components/ui/Icons/Icon';
import s from './AdmissionsOfficerHeader.module.scss';

type Props = {
  title: string;
};
export const AdmissionsOfficerHeader: React.FC<Props> = ({ title }) => {
  return (
    <div className={s.AdmissionsOfficerHeader}>
      <div className={s.AdmissionsOfficerHeader__title__wrapper}>
        <Icon type="star" className={s.AdmissionsOfficerHeader__title__icon} />
        {title}
      </div>
      <div className={s.AdmissionsOfficerHeader__actions}>
        <Icon type="clip" rounded className={s.AdmissionsOfficerHeader__icon} backgroundColor={'#fff'} />
        <Icon type="menu" rounded className={s.AdmissionsOfficerHeader__icon} backgroundColor={'#fff'} />
      </div>
    </div>
  );
};
