import clsx from 'clsx';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '../../common/components/ui/Button/Button';
import { LinkPrimary } from '../../common/components/ui/LinkPrimary/LinkPrimary';
import { ReactComponent as FacebookIcon } from '../../assets/social/facebook.svg';
import { ReactComponent as VkIcon } from '../../assets/social/vk.svg';
import pattern from './pattern.png';
import s from './SignInPage.module.scss';

export default function SignInPage() {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'atarasov' && password === 'employee') return history.push('/employee');
    if (username === 'vvasilyev' && password === 'student') return history.push('/student');
    setError(true);
  };

  return (
    <React.Fragment>
      <Helmet title={'Вход'} />
      <div className={s.SignInPage}>
        {/* единственный путь назад */}
        <Link to={'/'}>
          <img className={s.SignInPage__pattern} src={pattern} alt={'Паттерн Цифрового университета'} />
        </Link>
        <form onSubmit={handleSubmit} className={s.SignInPage__form}>
          <h1 className={s.SignInPage__heading}>Вход</h1>
          {error && (
            <div className={s.SignInPage__error}>
              {username && password ? 'Неверное имя пользователя или пароль.' : 'Введите имя пользователя и пароль.'}
            </div>
          )}

          <input
            className={s.SignInPage__input}
            value={username}
            onChange={(e) => {
              setError(false);
              setUsername(e.target.value);
            }}
            placeholder={'Логин'}
            name={'username'}
          />

          <input
            className={s.SignInPage__input}
            value={password}
            onChange={(e) => {
              setError(false);
              setPassword(e.target.value);
            }}
            type={'password'}
            placeholder={'Пароль'}
            name={'password'}
          />

          <LinkPrimary className={s.SignInPage__forgotPassword}>Не помню пароль</LinkPrimary>
          <Button className={clsx(s.SignInPage__button, s.SignInPage__submit)} type={'submit'}>
            Войти
          </Button>
          {/* почему-то отправляет форму при нажатии, если не делать preventDefault() */}
          <Button
            className={clsx(s.SignInPage__button, s.SignInPage__yandex)}
            secondary
            onClick={(e) => e.preventDefault()}>
            Войти с Яндекс ID
          </Button>

          <div className={s.SignInPage__socialNote}>Войти с помощью</div>
          <div className={s.SignInPage__socialIcons}>
            <div className={s.SignInPage__socialIcon}>
              <VkIcon />
            </div>
            <div className={s.SignInPage__socialIcon}>
              <FacebookIcon />
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
