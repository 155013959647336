import clsx from 'clsx';
import { Button, ButtonType } from '../../../../../common/components/ui/Button/Button';
import { TestTask3MyPrograms } from './TestTask3MyPrograms';
import { TestTask3OtherPrograms } from './TestTask3OtherPrograms';
import s from './TestTask3.module.scss';
import s2 from '../TestTask.module.scss';

type Props = {
  setItem: (number: number) => void;
  setChosenProgress: (number: number) => void;
};
export const TestTask3: React.FC<Props> = ({ setItem, setChosenProgress }) => {
  return (
    <div className={s.TestTask3}>
      <div className={clsx(s.TestTask3__title, s2.TestTask__title)}>
        Статистика и списки зарегистрированных абитуриентов бакалавриата
      </div>
      <TestTask3MyPrograms />
      <TestTask3OtherPrograms />
      <div className={s.TestTask3__footer}>
        <Button onClick={() => setItem(1)} btnType={ButtonType.Outlined}>
          Назад
        </Button>
        <Button onClick={() => setChosenProgress(5)}>Далее</Button>
      </div>
    </div>
  );
};
