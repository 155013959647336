import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function ScrollIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.51233 17.8055L15.3124 1.49203C18.1868 2.09865 19.9339 4.22134 20.4481 5.20687L8.64806 21.5204C6.75569 19.0472 4.43575 18.0133 3.51233 17.8055Z"
          stroke={color || '#5C617C'}
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <path
          d="M10.505 8.13947C11.4285 8.34725 13.7484 9.3811 15.6408 11.8543"
          stroke={color || '#5C617C'}
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <path
          d="M8.68626 10.6551C9.60968 10.8629 11.9296 11.8967 13.822 14.3699"
          stroke={color || '#5C617C'}
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <path
          d="M15.2046 12.4586C15.5624 13.0242 17.2745 14.4752 17.9995 14.9996L15.9995 15.9996L15.9995 18.4996C15.2946 17.9898 13.1531 16.3126 13.0194 15.4796"
          stroke={color || '#5C617C'}
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <path
          d="M3.50018 17.9999C3.13598 18.5034 2.82505 19.4265 5.00018 20.9999"
          stroke={color || '#5C617C'}
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <path
          d="M8.49976 21.4995C8.13556 22.003 7.17489 22.5729 4.99976 20.9995"
          stroke={color || '#5C617C'}
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
