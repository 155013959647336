import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function DialogIcon2({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.66669 8.66667H12.1867"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.66669 13.3333H13.36"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.66669 18H14.5334"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3333 18H19.2"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3333 13.3333H18.5333"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9093 27.624L18.924 23.6093C19.5173 23.016 19.7973 22.1773 19.6787 21.3467L17.5267 6.28933C17.3387 4.976 16.2133 4 14.8867 4H6.66667C5.19333 4 4 5.19333 4 6.66667V20C4 21.4733 5.19333 22.6667 6.66667 22.6667H19.544"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2 22.6667L13.6347 25.7107C13.8213 27.024 14.9467 28 16.2747 28H25.3333C26.8067 28 28 26.8067 28 25.3333V10.6667C28 9.19333 26.8067 8 25.3333 8H18.5333"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
