import clsx from 'clsx';
import { FormEvent } from 'react';

import s from './Checkbox.module.scss';

type Props = {
  name: string;
  value?: string;
  checked: boolean;
  label: string;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
  className?: string;
  rounded?: boolean;
};
export const Checkbox: React.FC<Props> = ({ name, value, checked, label, onChange, className, rounded }) => {
  return (
    <label className={clsx(s.Checkbox__wrapper, className)}>
      <span className={clsx(s.Checkbox__label, { [s.Checkbox__label_rounded]: rounded })}>{label}</span>
      <div className={clsx(s.inputWrapper, { [s.Checkbox_rounded]: rounded })}>
        <input onChange={onChange} name={name} value={value} type="checkbox" checked={checked} />
        <span className={s.checkmark} />
      </div>
    </label>
  );
};
