import { AppColor } from 'store/app';


import { Notification } from 'common/components/ui/Notification/Notification';
import { Icon } from 'common/components/ui/Icons/Icon';
import s from './EventNotification.module.scss';

type Props = {
  date?: string;
  title?: string;
  lecturer: string;
  cabinet: number;
  visible: boolean;
  toggle: () => void;
  time: string;
};
export const EventNotification: React.FC<Props> = ({ date, title, lecturer, cabinet, visible, toggle, time }) => {
  return (
    <Notification visible={visible} onToggle={toggle} className={s.EventNotification__wrapper}>
      <div className={s.EventNotification__date}>{date}</div>
      <div className={s.EventNotification__contentWrapper}>
        <Icon type="calendar" fillColor={AppColor.Red} className={s.EventNotification__icon} />
        <div>
          <div className={s.EventNotification__title}>{title}</div>
          <div className={s.EventNotification__time}>{time}</div>
          <div className={s.EventNotification__info}>
            <div className={s.EventNotification__listWrapper}>
              Лектор: <span className={s.EventNotification__list}>{lecturer}</span>
            </div>
            <div className={s.EventNotification__listWrapper}>
              Кабинет: <span className={s.EventNotification__list}>{cabinet}</span>
            </div>
          </div>
        </div>
      </div>
    </Notification>
  );
};
