import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function EyeIcon3({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse
          cx="23.9998"
          cy="22.6667"
          rx="5.33333"
          ry="5.33333"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.0951 22.6667H21.9048"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.0002 20.5715V24.7618"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 22.3642C9.57142 21.6792 6.34194 19.2839 4.59465 15.8827C3.80178 14.2756 3.80178 12.3911 4.59465 10.7841C6.80185 6.54239 11.2192 3.91493 16 4.00005C20.7808 3.91493 25.1981 6.54239 27.4053 10.7841C27.7954 11.5773 27.9988 12.4494 28 13.3334"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0025 13.3304C20.0025 11.12 18.2106 9.32813 16.0003 9.32812C13.7899 9.32812 11.9981 11.12 11.998 13.3303C11.998 15.5407 13.7899 17.3326 16.0002 17.3326"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
