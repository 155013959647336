import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import { Button, ButtonType } from '../../../../common/components/ui/Button/Button';
import { Card } from '../../../../common/components/ui/Card/Card';
import { Icon } from '../../../../common/components/ui/Icons/Icon';
import { Logo } from '../../../../common/components/ui/Icons/Logo';
import { Input } from '../../../../common/components/ui/Forms/Input/Input';
import { InputPassword } from '../../../../common/components/ui/Forms/InputPassword/InputPassword';
import OopsIcon from './assets/oops.svg';
import s from './AuthCard.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().max(255).required(),
  password: Yup.string().min(8).max(255).required()
});

type Props = {
  handleSubmit: (email: string, password: string) => void;
  isLogin?: boolean;
  setChosenStep?: (chosenStep: string) => void;
  className?: string;
};
export const AuthCard: React.FC<Props> = ({ handleSubmit, isLogin, setChosenStep, className }) => {
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: ({ email, password }, { resetForm }) => {
      if (isLogin) {
        setChosenStep && setChosenStep('second');
      }
      handleSubmit(email, password);
      resetForm();
    }
  });

  const onLogInWithYandexId = () => {};
  const onLogInWithVK = () => {};
  const onLogInWithFacebook = () => {};

  const inputs = [
    {
      name: 'email',
      value: formik.values.email,
      placeholder: 'Почта',
      type: 'email'
    },
    {
      name: 'password',
      value: formik.values.password,
      placeholder: 'Пароль',
      type: 'password'
    }
  ];
  const buttons = [
    {
      type: 'submit' as 'submit' | 'reset' | 'button',
      title: isLogin ? 'Авторизоваться' : 'Зарегистрироваться',
      btnType: ButtonType.Default
    },
    {
      onClick: onLogInWithYandexId,
      title: 'Войти с Яндекс ID',
      btnType: ButtonType.Outlined
    }
  ];
  const icons = [
    { onClick: onLogInWithVK, type: 'vk' },
    { onClick: onLogInWithFacebook, type: 'facebook' }
  ];

  const showInputs = inputs.map((input) => {
    return input.name !== 'password' ? (
      <Input
        onChange={formik.handleChange}
        {...input}
        key={input.name}
        className={s.AuthCard__input}
        isDanger={formik.touched && formik.errors.email ? true : false}
      />
    ) : (
      <InputPassword
        onChange={formik.handleChange}
        {...input}
        key={input.name}
        className={s.AuthCard__input}
        isDanger={formik.touched && formik.errors.password ? true : false}
      />
    );
  });
  const showButtons = buttons.map((button) => (
    <Button
      onClick={button.onClick}
      btnType={button.btnType}
      type={button.type}
      key={button.title}
      className={s.AuthCard__button}>
      {button.title}
    </Button>
  ));
  const showIcons = icons.map((icon) => (
    <div className={s.AuthCard__networksIconWrapper}>
      <Icon {...icon} key={icon.type} className={s.AuthCard__networksIcon} />
    </div>
  ));

  return (
    <div className={clsx(s.AuthCard__wrapper, className)}>
      <Card
        className={clsx(s.AuthCard__card, {
          [s.AuthCard__cardLogin]: isLogin,
          [s.AuthCard__cardRegister]: !isLogin
        })}
        shadow>
        <form onSubmit={formik.handleSubmit} className={s.AuthCard__form}>
          <Logo className={s.AuthCard__logo} />
          {isLogin && <img src={OopsIcon} className={s.AuthCard__iconOops} />}
          <div className={clsx(s.AuthCard__title, { [s.AuthCard__titleRegister]: !isLogin })}>
            {isLogin ? 'Войдите в систему, чтобы подать заявку' : 'Регистрация в системе'}
          </div>
          <div className={s.AuthCard__inputs}>{showInputs}</div>
          <div className={s.AuthCard__linkWrapper}>
            <Link to="/register" className={s.AuthCard__link}>
              {isLogin && 'Зарегистрироваться'}
            </Link>
          </div>
          <div className={s.AuthCard__buttons}>{showButtons}</div>
          <div className={s.AuthCard__networks}>
            <div className={s.AuthCard__networksTitle}>Войти с помощью</div>
            <div className={s.AuthCard__networksIcons}>{showIcons}</div>
          </div>
        </form>
      </Card>
    </div>
  );
};
