import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

import { Button, ButtonSize, ButtonType } from 'common/components/ui/Button/Button';
import { Title } from 'common/components/ui/Title/Title';
import { Tag } from 'common/components/ui/Tags/Tags';
import { AvatarsSmall } from 'common/components/ui/AvatarsSmall/AvatarsSmall';
import s from './LMSLessonHeader.module.scss';

export type LMSLessonHeaderProps = {
  title: string;
  description: string;
  address: string;
  date: number;
  author: string;
  allParticipants: number;
  participantsAvatars: Array<string>;
};
export const LMSLessonHeader: React.FC<LMSLessonHeaderProps> = ({
  title,
  description,
  date,
  address,
  allParticipants,
  participantsAvatars
}) => {
  const history = useHistory();
  const baseTagProps = { color: '#6B7A99', backgroundColor: '#F5F8FE' };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={s.LMSLessonHeader__wrapper}>
      <Button
        onClick={goBack}
        btnType={ButtonType.Outlined}
        size={ButtonSize.Small}
        iconStartType="arrow"
        iconStyle={s.LMSLessonHeader__button_icon}>
        Назад
      </Button>
      <Title title={title} className={s.LMSLessonHeader__title} />
      <div className={s.LMSLessonHeader__description}>{description}</div>
      <div className={s.LMSLessonHeader__tags}>
        <Tag title={address} {...baseTagProps} />
        <Tag title={'5 января 2022'} {...baseTagProps} />
        <Tag title={moment(date).format('hh:mm')} {...baseTagProps} />
        <Tag title="" {...baseTagProps} className={s.LMSLessonHeader__tag}>
          Участники:
          <AvatarsSmall avatars={participantsAvatars} />
          <span className={s.LMSLessonHeader__participantsCount}>+{allParticipants}</span>
        </Tag>
      </div>
    </div>
  );
};
