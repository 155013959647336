
import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Input } from 'common/components/ui/Forms/Input/Input';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import s from './Enrollment2GeneralInformation.module.scss';

type Props = {
  values: {
    surname: string;
    name: string;
    patronymic: string;
    citizenship: string;
    phone: string;
  };
  onChange: (e: React.ChangeEvent<any>) => void;
};
export const Enrollment2GeneralInformation: React.FC<Props> = ({ values, onChange }) => {
  const LABELS_MAP = {
    surname: 'Фамилия',
    name: 'Имя',
    patronymic: 'Отчество',
    citizenship: 'Гражданство',
    phone: 'Телефон'
  };

  const showInputs = Object.entries(values).map(([key, value], index) => (
    <Field>
      <FieldTitle title={LABELS_MAP[key]} />
      <Input name={key} value={value} onChange={onChange} size={FieldSize.Small} />
    </Field>
  ));

  return <div className={s.Enrollment2GeneralInformation}>{showInputs}</div>;
};
