import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function FolderInShapeIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6694 28.005H9.33055C6.38399 28.005 3.995 25.616 3.995 22.6694V9.33055C3.995 6.38399 6.38399 3.995 9.33055 3.995H22.6694C25.616 3.995 28.005 6.38399 28.005 9.33055V22.6694C28.005 25.616 25.616 28.005 22.6694 28.005Z"
          stroke={color || '#F43F5E'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6686 12.6252H16L15.0636 11.2473C14.8142 10.8832 14.402 10.6644 13.9605 10.6644H11.3314C10.5951 10.6644 9.9975 11.262 9.9975 11.9983V20.0017C9.9975 20.738 10.5951 21.3355 11.3314 21.3355H20.6686C21.4049 21.3355 22.0025 20.738 22.0025 20.0017V13.9591C22.0025 13.2228 21.4049 12.6252 20.6686 12.6252Z"
          stroke={color || '#F43F5E'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
