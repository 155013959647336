import clsx from 'clsx';
import React from 'react';
import { Icon } from '../../Icons/Icon';
import { FieldSize } from '../basicTypes';
import s from './Input.module.scss';

export interface IInputProps {
  name?: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  hasBackground?: boolean;
  className?: string;
  style?: React.CSSProperties;
  size?: FieldSize;
  isCopy?: boolean;
  isDanger?: boolean;
}

export function Input({
  name,
  value,
  onChange,
  placeholder,
  hasBackground,
  className,
  style,
  size = FieldSize.Large,
  isCopy,
  isDanger
}: IInputProps) {
  return (
    <div
      className={clsx(
        s.Input,
        {
          [s.Input_danger]: isDanger,
          [s.Input_sm]: size === FieldSize.Small,
          [s.Input_md]: size === FieldSize.Medium,
          [s.Input_lg]: size === FieldSize.Large
        },
        className
      )}
      style={{ height: `${size}px` }}>
      <input
        className={clsx(s.Input__input)}
        style={style}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {isCopy && <Icon onClick={() => navigator.clipboard.writeText(value)} type="copy" className={s.Input__icon} />}
    </div>
  );
}
