import { useState } from 'react';

import { Message } from 'routes/lmsMessages/_temporaryDialogData';

import { CreateMessageField } from 'common/components/ui/CreateMessageField/CreateMessageField';
import { ReactComponent as WriteIcon } from '../assets/write.svg';
import { LMSMessage } from './LMSMessage';
import s from './LMSChat.module.scss';

export type LMSChatProps = {
  messages: Array<Message>;
  myId: string;
};
export const LMSChat: React.FC<LMSChatProps> = ({ messages, myId }) => {
  const [text, setText] = useState('');

  const showMessages = messages.map((message) => (
    <LMSMessage {...message} isOnline={true} isWrite={false} isMyMessage={message.from_id === myId} />
  ));

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const onSend = () => {};

  return (
    <div className={s.LMSChat__wrapper}>
      <div className={s.LMSChat__content}>
        <LMSMessage
          id="64"
          from_id="5"
          chat_id="2"
          avatar={messages[0].avatar}
          isOnline={true}
          isWrite={true}
          isMyMessage={false}
        />
        {showMessages}
      </div>
      <CreateMessageField value={text} onChange={handleChangeText} onSend={onSend} className={s.LMSChat__footer} />
    </div>
  );
};
