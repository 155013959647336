import React from 'react';

import clsx from 'clsx';
import { Button, ButtonType } from '../Button/Button';
import s from './Filter2.module.scss';

type ItemType = { slug: string; text: string; onClick?: (e: React.MouseEvent) => void };
type Props = {
  items: Array<ItemType>;
  setChosenItem: (slug: string) => void;
  chosenItem: string;
  startChildren?: React.ReactNode;
  endChildren?: React.ReactNode;
  className?: string;
  classNameItem?: string;
};
export const Filter2: React.FC<Props> = ({
  startChildren,
  endChildren,
  items,
  setChosenItem,
  chosenItem,
  className,
  classNameItem
}) => {
  const onClick = (e: React.MouseEvent, item: ItemType, onClick?: (e: React.MouseEvent) => void) => {
    setChosenItem(item.slug);
    onClick && onClick(e);
  };

  const showActionsData = items.map((item) => (
    <Button
      onClick={(e) => onClick(e, item, item.onClick)}
      className={clsx(s.Filter2__button, classNameItem)}
      key={item.slug}
      btnType={ButtonType.Filter}
      active={chosenItem === item.slug}>
      {item.text}
    </Button>
  ));

  return (
    <div className={clsx(s.Filter2, className)}>
      {startChildren}
      {showActionsData}
      {endChildren}
    </div>
  );
};
