import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function EyeIcon2({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.99987 4.00475C12.209 4.00475 13.9999 5.79561 13.9999 8.00475C13.9999 10.2139 12.209 12.0047 9.99987 12.0047C7.79073 12.0047 5.99987 10.2139 5.99987 8.00475C5.99987 5.79561 7.79073 4.00475 9.99987 4.00475ZM9.99987 0.500122C14.6134 0.500122 18.596 3.65013 19.701 8.06451C19.8016 8.46632 19.5574 8.8736 19.1556 8.97418C18.7537 9.07477 18.3465 8.83057 18.2459 8.42876C17.307 4.67808 13.9212 2.00012 9.99987 2.00012C6.07681 2.00012 2.68997 4.68039 1.75273 8.43327C1.65237 8.83514 1.24523 9.07956 0.843357 8.9792C0.441486 8.87884 0.197065 8.4717 0.297428 8.06983C1.40052 3.65284 5.38436 0.500122 9.99987 0.500122Z"
          fill={color || '#5C617C'}
        />
      </svg>
    </div>
  );
}
