import { useEffect, useState } from 'react';

export function useDropdown(dropdownId: string) {
  const state = useState(false);
  const [dropdown, setDropdown] = state;
  // dropdown effect
  useEffect(() => {
    const handleClick = (e) => !e.target.closest(`#${dropdownId || 'dropdown'}`) && setDropdown(false);
    if (dropdown) window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [dropdown, dropdownId, setDropdown]);
  // makes it easier for TypeScript
  return state;
}
