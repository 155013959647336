import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function TwoLinesIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9983 18.6677H20.0017"
          stroke={color || '#1ABCDF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.4889 7.51109C29.1775 12.1997 29.1775 19.8002 24.4889 24.4888C19.8002 29.1775 12.1997 29.1775 7.51112 24.4888C2.8225 19.8002 2.8225 12.1997 7.51112 7.51109C12.1997 2.82247 19.8002 2.82247 24.4889 7.51109"
          stroke={color || '#1ABCDF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6644 13.3322H21.3356"
          stroke={color || '#1ABCDF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
