import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};
export const ClipIcon2: React.FC<Props> = ({ onClick, className, rounded, color }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 12C3.90659 11.998 2.85853 11.5628 2.08537 10.7896C1.31222 10.0165 0.876983 8.96841 0.875 7.875V3C0.875 2.20435 1.19107 1.44129 1.75368 0.87868C2.31629 0.316071 3.07935 0 3.875 0C4.67065 0 5.43371 0.316071 5.99632 0.87868C6.55893 1.44129 6.875 2.20435 6.875 3V7.125C6.875 7.62228 6.67746 8.09919 6.32583 8.45082C5.97419 8.80246 5.49728 9 5 9C4.50272 9 4.02581 8.80246 3.67417 8.45082C3.32254 8.09919 3.125 7.62228 3.125 7.125V3H4.625V7.125C4.625 7.22446 4.66451 7.31984 4.73483 7.39017C4.80516 7.46049 4.90054 7.5 5 7.5C5.09946 7.5 5.19484 7.46049 5.26517 7.39017C5.33549 7.31984 5.375 7.22446 5.375 7.125V3C5.375 2.60218 5.21696 2.22064 4.93566 1.93934C4.65436 1.65804 4.27282 1.5 3.875 1.5C3.47718 1.5 3.09564 1.65804 2.81434 1.93934C2.53304 2.22064 2.375 2.60218 2.375 3V7.875C2.375 8.57119 2.65156 9.23887 3.14384 9.73116C3.63613 10.2234 4.30381 10.5 5 10.5C5.69619 10.5 6.36387 10.2234 6.85616 9.73116C7.34844 9.23887 7.625 8.57119 7.625 7.875V3H9.125V7.875C9.12302 8.96841 8.68778 10.0165 7.91463 10.7896C7.14147 11.5628 6.09341 11.998 5 12Z"
        fill={color || '#605BFF'}
      />
    </svg>
  );
};
