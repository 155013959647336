import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function PencilIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.793 15.619L16.119 5.29299C16.509 4.90299 17.142 4.90299 17.532 5.29299L19.208 6.96899C19.598 7.35899 19.598 7.99199 19.208 8.38199L8.881 18.707C8.694 18.895 8.44 19 8.175 19H5.5V16.325C5.5 16.06 5.605 15.806 5.793 15.619Z"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 7.15991L17.34 10.2499"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
