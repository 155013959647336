import clsx from 'clsx';
import React, { useState } from 'react';

import { CourseTheme } from 'routes/lmsLesson/_temporaryCourseThemesData';

import { Icon } from 'common/components/ui/Icons/Icon';
import { PlayerModal } from 'common/components/ui/PlayerModal/PlayerModal';
import { LMSLessonThemeItem } from '../LMSLessonThemeItem/LMSLessonThemeItem';
import s from './LMSLessonItem.module.scss';

export type LMSLessonItemProps = {
  active: boolean;
  onActive: () => void;
};
export const LMSLessonItem: React.FC<LMSLessonItemProps & CourseTheme> = ({ id, title, items, active, onActive }) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [chosenSubitems, setChosenSubitems] = useState<string | null>(null);

  const onShowSubitems = (id: string) => {
    if (!chosenSubitems) {
      setChosenSubitems(id);
    } else {
      setChosenSubitems(null);
    }
  };

  const onSetVideoUrl = (url: string) => {
    setVideoUrl(url);
  };
  const onClosePlayerModal = () => {
    setVideoUrl(null);
  };

  const showItems = items?.map((item, index) => (
    <LMSLessonThemeItem
      key={item.id}
      {...item}
      chosenSubitems={chosenSubitems}
      onShowSubitems={onShowSubitems}
      onSetVideoUrl={onSetVideoUrl}
    />
  ));

  return (
    <div className={s.LMSLessonItem__wrapper}>
      {videoUrl && <PlayerModal url={videoUrl} onClose={onClosePlayerModal} />}
      <div onClick={onActive} className={s.LMSLessonItem__header}>
        <div className={s.LMSLessonItem__header_col}>
          <span className={s.LMSLessonItem__number}>{id}</span>
          <span className={s.LMSLessonItem__title}>
            Тема: {id}. {title}
          </span>
        </div>
        <Icon
          type="arrow-2"
          color="#fff"
          className={clsx(s.LMSLessonItem__iconArrow, { [s.LMSLessonItem__iconArrow_show]: active })}
        />
      </div>
      <div className={clsx(s.LMSLessonItem__items, { [s.LMSLessonItem__items_show]: items?.length && active })}>
        <div className={clsx(s.LMSLessonItem__items_titles)}>
          <span>Название</span>
          <span />
          <span>Дата</span>
          <span />
          <span>Тип</span>
          <span />
          <span>Записи</span>
        </div>
        {showItems}
      </div>
    </div>
  );
};
