import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import logoIcon from '../../assets/icon.png';

import { Logo } from '../../common/components/ui/Icons/Logo';
import { TopBar } from '../../common/components/ui/TopBar/TopBar';
import { ButtonLink } from '../../common/components/ui/Button/Button';
import { Footer } from '../../common/components/ui/Footer/Footer';
import { StarIcon } from '../../common/components/ui/Icons/StarIcon';
import { BellIcon } from '../../common/components/ui/Icons/BellIcon';
import { ArrowIcon2 } from '../../common/components/ui/Icons/ArrowIcon2';
import { ArrowIcon3 } from '../../common/components/ui/Icons/ArrowIcon3';
import { MenuLeft } from '../../common/components/ui/MenuLeft/MenuLeft';
import Avatar from './assets/Avatar.png';
import s from './MainLayout.module.scss';

export enum MainLayoutPageTypeEnum {
  Default = 'default',
  Manager = 'manager',
  LMS = 'lms'
}
const userAuthNavItems = [
  { title: 'Мои вакансии', href: '#' },
  { title: 'Кандидаты', href: '#' },
  { title: 'Тарифы', href: '#' },
  { title: 'Еще', items: [{}] }
];
export function withMainLayout<Props>(
  Comp: React.ComponentType<Props>,
  pageType: MainLayoutPageTypeEnum = MainLayoutPageTypeEnum.Default
) {
  const isAuth = true;
  const activeItem = 'Главная';

  const showUserAuthNavItems = userAuthNavItems.map((item) =>
    item.href ? (
      <Link to={item.href} className={s.MainLayout__navItem} key={item.title}>
        {item.title}
      </Link>
    ) : (
      item.items && (
        <div className={clsx(s.MainLayout__navDropdown__wrapper, s.MainLayout__navItem)} key={item.title}>
          <div className={s.MainLayout__navDropdown}>
            {item.title}
            <ArrowIcon3 className={s.MainLayout__navDropdown__arrow} />
          </div>
          <div className={s.MainLayout__navDropdown__content}> </div>
        </div>
      )
    )
  );

  const topBarSlotRight = () =>
    isAuth ? (
      <div className={s.MainLayout__topBar__right}>
        <div className={s.MainLayout__topBar__icons}>
          <StarIcon className={s.MainLayout__topBar__icon} />
          <div className={s.MainLayout__topBar__line} />
          <BellIcon className={s.MainLayout__topBar__icon} />
        </div>
        <div className={s.MainLayout__topBar__userDropdown__wrapper}>
          <div className={s.MainLayout__topBar__userDropdown}>
            <img src={Avatar} alt="Аватар" className={s.MainLayout__topBar__avatar} />
            <ArrowIcon2 />
          </div>
          <div className={s.MainLayout__topBar__userDropdown__content}></div>
        </div>
      </div>
    ) : (
      <ButtonLink secondary small to={'/login'}>
        Войти
      </ButtonLink>
    );
  const topBarSloLeft = () =>
    isAuth ? <div className={s.MainLayout__topBar__left}>{showUserAuthNavItems}</div> : null;

  return function (props: Props) {
    return (
      <div className={s.MainLayout}>
        <TopBar
          logoLarge={<Logo />}
          logoSmall={<img src={logoIcon} alt={'Цифровой университет'} />}
          // slotLeft={topBarSloLeft()}
          slotRight={topBarSlotRight()}
        />
        <MenuLeft activeItem={activeItem} pageType={pageType} />
        <div className={s.MainLayout__content}>
          <Comp {...props} />
        </div>
        <Footer />
      </div>
    );
  };
}
