import { Checkbox } from 'common/components/ui/Forms/Checkbox/Checkbox';
import { Card } from 'common/components/ui/Card/Card';

import s from './Enrollment1Info.module.scss';

const items = [
  { title: 'Программа', body: 'Бизнес-информатика: цифровое предприятие и управление информационными системами' },
  { title: 'Кампус', body: 'Москва' },
  { title: 'Направление', body: '38.04.05 Бизнес-информатика' },
  { title: 'Форма обучения', body: 'Очная' }
];

type Props = {
  budget: boolean;
  commerce: boolean;
  setFieldValue?: any;
};
export const Enrollment1Info: React.FC<Props> = ({ budget, commerce, setFieldValue }) => {
  const checkboxes = [
    { label: 'Бюджет', checked: budget, name: 'budget' },
    { label: 'Коммерция', checked: commerce, name: 'commerce' }
  ];

  const cd = (checked: any) => {
    console.log('kekek', checked);
    setFieldValue('is_budget', !checked);
  };

  const showItems = items.map((item) => (
    <div className={s.Enrollment1Info__col} key={item.title}>
      <div className={s.Enrollment1Info__title}>{item.title}</div>
      <div className={s.Enrollment1Info__body}>{item.body}</div>
    </div>
  ));
  const showCheckboxes = checkboxes.map((checkbox) => (
    <Checkbox
      onChange={() => setFieldValue(checkbox.name, !checkbox.checked)}
      {...checkbox}
      key={checkbox.label}
      className={s.Enrollment1Info__checkbox}
    />
  ));

  return (
    <Card className={s.Enrollment1Info}>
      <div className={s.Enrollment1Info__content}>{showItems}</div>
      <div className={s.Enrollment1Info__checkboxes}>{showCheckboxes}</div>
    </Card>
  );
};
