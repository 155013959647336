import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function FolderIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.6667 2.66663H7.99999C7.29275 2.66663 6.61447 2.94758 6.11438 3.44767C5.61428 3.94777 5.33333 4.62605 5.33333 5.33329V26.6666C5.33333 27.3739 5.61428 28.0521 6.11438 28.5522C6.61447 29.0523 7.29275 29.3333 7.99999 29.3333H24C24.7072 29.3333 25.3855 29.0523 25.8856 28.5522C26.3857 28.0521 26.6667 27.3739 26.6667 26.6666V10.6666L18.6667 2.66663Z"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6667 2.66663V10.6666H26.6667"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3333 17.3334H10.6667"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3333 22.6666H10.6667"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 12H12H10.6667"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
