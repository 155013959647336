import React, { useState } from 'react';
import { Button, ButtonLink, ButtonType } from '../../../../common/components/ui/Button/Button';
import { Notification } from '../../../../common/components/ui/Notification/Notification';
import s from './FilingAnApplication2.module.scss';

const items = [
  { isValid: true, title: 'Согласие на сбор и обработку персональных данных' },
  { isValid: true, title: 'Печатная форма согласия' },
  { isValid: true, title: 'Основная информация' },
  { isValid: true, title: 'Документ удостоверяющий личность, гражданство' },
  { isValid: true, title: 'Данные о месте регистрации и о месте проживания' },
  { isValid: true, title: 'Статус соотечественника' },
  { isValid: true, title: 'Образование' },
  { isValid: true, title: 'Участие в олимпиадах' },
  { isValid: true, title: 'Индивидуальные достижения' },
  { isValid: true, title: 'Поступление на места в пределах особой квоты' },
  { isValid: true, title: 'Целевое обучение' },
  { isValid: true, title: 'Выбор образовательной программы и кампуса' },
  { isValid: true, title: 'Использование достижений' },
  { isValid: true, title: 'Контактные лица' },
  { isValid: true, title: 'Дополнительная информация' },
  { isValid: true, title: 'Заявления о приеме на обучение' }
];
type Props = {
  setChosenProgress: (number: number) => void;
};
export const FilingAnApplication2: React.FC<Props> = ({ setChosenProgress }) => {
  const [isNotification, setIsNotification] = useState(true);

  const onTogglIsNotification = () => {
    setIsNotification(!isNotification);
  };

  const changeChosenProgress = () => {
    setChosenProgress(4);
  };

  const showItems = items.map((item) => (
    <div className={s.FilingAnApplication2__item}>
      <div
        className={s.FilingAnApplication2__status}
        style={{ borderLeft: `16px solid ${item.isValid ? '#24D957' : '#F43F5E'}` }}>
        {item.title}
      </div>
    </div>
  ));

  return (
    <div className={s.FilingAnApplication2__wrapper}>
      <Notification visible={isNotification} onToggle={onTogglIsNotification}>
        <p className={s.FilingAnApplication2__notification_text}>Как поступит сотрудник приемной комиссии?</p>
        <ButtonLink
          to="/foster-companies"
          btnType={ButtonType.Outlined}
          className={s.FilingAnApplication2__notification_button}>
          Смотреть
        </ButtonLink>
      </Notification>
      {showItems}
      <Button onClick={changeChosenProgress} className={s.FilingAnApplication2__butttonSubmit}>
        Отправить заявление
      </Button>
    </div>
  );
};
