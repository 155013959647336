import { Routes, RouteType } from '../common/components/utils/Routes';
import { LMSMessagesPage } from './lmsMessages/LMSMessagesPage';
import { DefaultLayout } from './_layouts/DefaultLayout';
import { withUserLayout } from './_layouts/UserLayout';
import { MainLayoutPageTypeEnum, withMainLayout } from './_layouts/MainLayout';
import IndexPage from './index/IndexPage';
import ServicesPage from './services/ServicesPage';
import ServicePage from './service/ServicePage';
import EmployeePage from './employee/EmployeePage';
import StudentPage from './student/StudentPage';
import SignInPage from './signIn/SignInPage';
import NotFoundPage from './notFound/NotFoundPage';
import SubRoutePage from './subRoute/SubRoutePage';
import UniversityAdmission from './universityAdmission/UniversityAdmission';
import Register from './register/Register';
import { FosterСompanies } from './fosterСompanies/FosterСompanies';
import { ApplicationsOfApplicants } from './applicationsOfApplicants/ApplicationsOfApplicants';
import { withManagerLayout } from './_layouts/ManagerLayout/ManagerLayout';
import { ApplicantAgreements } from './applicantAgreements/ApplicantAgreements';
import { CalendarPage } from './calendar/CalendarPage';
import { LessonPage } from './lesson/LessonPage';
import { LMSHomePage } from './lmsHome/LMSHomePage';
import { LMSLessonPage } from './lmsLesson/LMSLessonPage';
import { LMSAdsPage } from './lmsAds/LMSAdsPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: DefaultLayout(withMainLayout(IndexPage))
  },
  {
    path: '/sub-route',
    exact: true,
    component: DefaultLayout(withMainLayout(SubRoutePage))
  },
  {
    path: '/university-admission',
    exact: true,
    component: DefaultLayout(withMainLayout(UniversityAdmission))
  },
  {
    path: '/calendar',
    exact: true,
    component: DefaultLayout(withMainLayout(CalendarPage))
  },
  {
    path: '/lesson/:id',
    exact: true,
    component: DefaultLayout(withMainLayout(LessonPage))
  },
  {
    path: '/foster-companies',
    exact: true,
    component: DefaultLayout(withManagerLayout(FosterСompanies))
  },
  {
    path: '/applications-of-applicants',
    exact: true,
    component: DefaultLayout(withManagerLayout(ApplicationsOfApplicants))
  },
  {
    path: '/applicant-agreements',
    exact: true,
    component: DefaultLayout(withManagerLayout(ApplicantAgreements))
  },
  {
    path: '/register',
    exact: true,
    component: Register
  },
  {
    path: '/services',
    exact: true,
    component: DefaultLayout(withMainLayout(ServicesPage))
  },
  {
    path: '/service/:service',
    exact: true,
    component: DefaultLayout(withMainLayout(ServicePage))
  },
  {
    path: '/employee',
    exact: true,
    component: DefaultLayout(withUserLayout(EmployeePage))
  },
  {
    path: '/student',
    exact: true,
    component: DefaultLayout(withUserLayout(StudentPage))
  },
  {
    path: '/login',
    exact: true,
    component: DefaultLayout(SignInPage)
  },
  {
    path: '/lms',
    exact: true,
    component: DefaultLayout(withMainLayout(LMSHomePage, MainLayoutPageTypeEnum.LMS))
  },
  {
    path: '/lms-lesson/:id',
    exact: true,
    component: DefaultLayout(withMainLayout(LMSLessonPage, MainLayoutPageTypeEnum.LMS))
  },
  {
    path: '/lms-messages',
    exact: true,
    component: DefaultLayout(withMainLayout(LMSMessagesPage, MainLayoutPageTypeEnum.LMS))
  },
  {
    path: '/lms-ads',
    exact: true,
    component: DefaultLayout(withMainLayout(LMSAdsPage, MainLayoutPageTypeEnum.LMS))
  },
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
