import React from 'react';
import { Helmet } from 'react-helmet';
import { fakeServices as services } from '../services/_temporaryServicesData';
import { UserInfo } from '../../common/components/ui/UserInfo/UserInfo';
import { Box } from '../../common/components/ui/Box/Box';
import { DashboardCard } from '../../common/components/ui/DashboardCard/DashboardCard';
import { Button, ButtonSize } from '../../common/components/ui/Button/Button';
import { DashboardServices } from '../../common/components/ui/DashboardServices/DashboardServices';
import { DashboardCardContent } from '../../common/components/ui/DashboardCardContent/DashboardCardContent';
import { LinkPrimary } from '../../common/components/ui/LinkPrimary/LinkPrimary';
import student from '../../assets/student.png';
import employee from '../../assets/employee.png';
import s from './StudentPage.module.scss';

export default function StudentPage() {
  const dashboardCardContent = (
    <DashboardCardContent
      tag={'ауд. 512'}
      slotBottomRight={
        <React.Fragment>
          <div className={s.StudentPage__calendarName}>Константинопольский К.К</div>
          <img className={s.StudentPage__calendarPhoto} src={employee} alt={''} />
        </React.Fragment>
      }>
      <div className={s.StudentPage__calendarEntryTitle}>Основы математического анализа</div>
      <div className={s.StudentPage__calendarEntryTime}>09:00 - 10:30</div>
    </DashboardCardContent>
  );

  return (
    <React.Fragment>
      <Helmet title={'Васильев Виктор Валерьевич'} />
      <Box className={s.StudentPage}>
        <UserInfo
          name={'Васильев Виктор Валерьевич'}
          about={'Студент МФТИ, кафедра кибернетики, группа К-31'}
          photo={student}
        />
        <div className={s.StudentPage__cards}>
          <DashboardCard className={s.StudentPage__about} title={'Информация о МФТИ'}>
            <h4 className={s.StudentPage__aboutHeading}>Полное наименование:</h4>
            <p className={s.StudentPage__aboutText}>
              Федеральное государственное автономное образовательное учреждение высшего образования «Московский
              физико-технический институт (национальный исследовательский университет)», действует&nbsp;с
              31&nbsp;января&nbsp;2019&nbsp;г.
            </p>
            <div className={s.StudentPage__aboutGroup}>
              <div className={s.StudentPage__aboutGroupItem}>
                <h4 className={s.StudentPage__aboutHeading}>Сокращенное наименование:</h4>
                <p className={s.StudentPage__aboutText}>МФТИ</p>
              </div>
              <div>
                <h4 className={s.StudentPage__aboutHeading}>E-mail:</h4>
                <p className={s.StudentPage__aboutText}>info@mipt.ru</p>
              </div>
            </div>
            <h4 className={s.StudentPage__aboutHeading}>Юридический адрес:</h4>
            <p className={s.StudentPage__aboutText}>17303, г. Москва, ул. Керченская, д. 1А, корп. 1</p>
            <h4 className={s.StudentPage__aboutHeading}>Почтовый адрес:</h4>
            <p className={s.StudentPage__aboutText}>
              141700, Московская обл., г. Долгопрудный, Институтский пер., д. 9
            </p>
            <Button className={s.StudentPage__aboutButton} size={ButtonSize.Small}>
              Подробнее
            </Button>
          </DashboardCard>
          <DashboardCard title={'Расписание'} slotRight={<div className={s.StudentPage__day}>21 января</div>}>
            <div className={s.StudentPage__calendar}>
              {dashboardCardContent}
              {dashboardCardContent}
              {dashboardCardContent}
            </div>
            <LinkPrimary className={s.StudentPage__calendarMore}>Еще</LinkPrimary>
          </DashboardCard>
        </div>
        <DashboardServices title={'Полезные сервисы'} services={services} />
      </Box>
    </React.Fragment>
  );
}
