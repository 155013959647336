import { useFormik } from 'formik';
import { Option } from 'react-select';

import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import s from './ApplicationsOfApplicantsFilter.module.scss';

const sources = [{ value: 'all', label: 'Все' }];
const statuses = [{ value: 'all', label: 'Все' }];
export const ApplicationsOfApplicantsFilter = () => {
  const initialValues = { source: sources[0], status: statuses[0] };
  const formik = useFormik({ initialValues, onSubmit: () => {} });

  const selects = [
    { label: 'Источник', name: 'source', value: formik.values.source, options: sources },
    { label: 'Дата начала действия', name: 'status', value: formik.values.source, options: statuses }
  ];
  const showSelects = selects.map(({ label, name, value, options }) => (
    <Field className={s.AdmissionsOfficerFilter_field}>
      <FieldTitle title={label} />
      <Select
        onChange={(option: Option) => formik.setFieldValue(name, option)}
        name={name}
        value={value}
        options={options}
        size={FieldSize.Small}
      />
    </Field>
  ));

  return <>{showSelects}</>;
};
