import React, { useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button, ButtonType } from '../../../common/components/ui/Button/Button';
import { CloudIcon } from '../../../common/components/ui/Icons/CloudIcon';
import { ConnectIcon } from '../../../common/components/ui/Icons/ConnectIcon';
import { FileInShapeIcon } from '../../../common/components/ui/Icons/FileInShapeIcon';
import { PeopleInShapeIcon } from '../../../common/components/ui/Icons/PeopleInShapeIcon';
import { TwoLinesIcon } from '../../../common/components/ui/Icons/TwoLinesIcon';
import { WriteIcon } from '../../../common/components/ui/Icons/WriteIcon';
import { Title } from '../../../common/components/ui/Title/Title';
import { Card } from '../../../common/components/ui/Card/Card';
import { ServiceGrid } from '../../../common/components/ui/ServiceGrid/ServiceGrid';
import s from './Actions.module.scss';

const actionsButtons = [
  { slug: 'my_recent', text: 'Мои недавние' },
  { slug: 'popular', text: 'Популярные' }
];

export const Actions = () => {
  const history = useHistory();

  const [chosenAction, setChosenAction] = useState('my_recent');

  const actionsData = [
    {
      id: '1',
      icon: <CloudIcon color="#605BFF" rounded className={s.SubRoutePage__action__icon} />,
      text: 'Подать документы на поступление',
      onClick: () => {
        history.push('/university-admission');
        localStorage.setItem('university-admission', '0');
      }
    },
    {
      id: '2',
      icon: <TwoLinesIcon color="#1ABCDF" rounded className={s.SubRoutePage__action__icon} />,
      text: 'Посмотреть расписание занятий',
      onClick: () => history.push('/')
    },
    {
      id: '3',
      icon: <FileInShapeIcon color="#F43F5E" rounded className={s.SubRoutePage__action__icon} />,
      text: 'Посмотреть материалы к курсу',
      onClick: () => history.push('/')
    },
    {
      id: '4',
      icon: <PeopleInShapeIcon color="#FF9BE9" rounded className={s.SubRoutePage__action__icon} />,
      text: 'Найти аудиторию/преподавателя',
      onClick: () => history.push('/')
    },
    {
      id: '5',
      icon: <ConnectIcon color="#33BA04" rounded className={s.SubRoutePage__action__icon} />,
      text: 'Написать преподавателю',
      onClick: () => history.push('/')
    },
    {
      id: '6',
      icon: <WriteIcon color="#9D57D3" rounded className={s.SubRoutePage__action__icon} />,
      text: 'Отметиться на занятии',
      onClick: () => history.push('/')
    }
  ];

  const showActionsButtons = actionsButtons.map((item, index) => (
    <Button
      onClick={() => setChosenAction(item.slug)}
      className={s.SubRoutePage__button}
      key={item.slug}
      btnType={ButtonType.Filter}
      active={chosenAction === item.slug}>
      {item.text}
    </Button>
  ));
  const showActionsData = actionsData.map((action) => (
    <Card onClick={action.onClick} key={action.id} className={s.SubRoutePage__actionCard}>
      {action.icon}
      {action.text}
    </Card>
  ));

  return (
    <>
      <Title title="Действия" tag="h2">
        <div className={s.SubRoutePage__actions__buttons}>{showActionsButtons}</div>
      </Title>
      <ServiceGrid className={s.SubRoutePage__actions}>{showActionsData}</ServiceGrid>
    </>
  );
};
