type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function ListIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        d="M10.6667 8H28"
        stroke={color || '#FF9BE9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 16H28"
        stroke={color || '#FF9BE9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 24H28"
        stroke={color || '#FF9BE9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8H4.01333"
        stroke={color || '#FF9BE9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 16H4.01333"
        stroke={color || '#FF9BE9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 24H4.01333"
        stroke={color || '#FF9BE9'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
