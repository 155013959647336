import clsx from 'clsx';
import React from 'react';

import s from './Field.module.scss';

type FieldProps = {
  children: React.ReactNode;
  flex?: boolean;
  className?: string;
};
export const Field: React.FC<FieldProps> = ({ children, flex, className }) => (
  <div className={clsx(s.Field, { [s.Field_flex]: flex }, className)}>{children}</div>
);

type FieldTitleProps = {
  title: string;
  flex?: boolean;
  className?: string;
};
export const FieldTitle: React.FC<FieldTitleProps> = ({ title, flex, className }) => (
  <div className={clsx(s.FieldTitle, { [s.FieldTitle__flex]: flex }, className)}>{title}</div>
);

type FormsGroupProps = {
  children: React.ReactNode;
  items: number;
};
export const FormsGroup: React.FC<FormsGroupProps> = ({ children, items }) => {
  return (
    <div className={s.FormsGroup} style={{ gridTemplateColumns: `repeat(${items}, 1fr)` }}>
      {children}
    </div>
  );
};
