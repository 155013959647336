import clsx from 'clsx';
import React, { useState } from 'react';
import { Icon } from '../../Icons/Icon';
import { IInputProps, Input } from '../Input/Input';

import { FieldSize } from '../basicTypes';
import s from './InputPassword.module.scss';
import s2 from '../Input/Input.module.scss';

interface Props extends IInputProps {
  hasBackground?: boolean;
  size?: FieldSize;
}
export const InputPassword: React.FC<Props> = ({
  name,
  value,
  onChange,
  placeholder,
  hasBackground,
  className,
  size = FieldSize.Large,
  isDanger
}) => {
  const [isPassword, setIsPassword] = useState(false);

  const onTogglePassword = () => {
    setIsPassword(!isPassword);
  };

  return (
    <div
      className={clsx(
        s2.Input,
        s.InputPassword__wrapper,
        {
          [s2.Input_danger]: isDanger
        },
        className
      )}
      style={{ height: `${size}px` }}>
      <input
        type={isPassword ? 'text' : 'password'}
        className={s.InputPassword}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {isPassword ? (
        <Icon onClick={onTogglePassword} type="eye-2" className={s.InputPasswordIcon} />
      ) : (
        <Icon onClick={onTogglePassword} type="eye-close" className={s.InputPasswordIcon} />
      )}
    </div>
  );
};
