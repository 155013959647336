import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function ConnectIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.99707 15.333C5.49781 15.333 2.6611 12.4962 2.6611 8.99698C2.6611 5.49772 5.49781 2.66101 8.99707 2.66101C12.4963 2.66101 15.333 5.49772 15.333 8.99698C15.333 10.6774 14.6655 12.289 13.4773 13.4772C12.2891 14.6654 10.6775 15.333 8.99707 15.333"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.489 6.72766C24.0503 7.33267 28.2001 12.13 27.9981 17.7204C27.796 23.3108 23.3108 27.796 17.7204 27.998C12.13 28.2001 7.33269 24.0502 6.72768 18.489"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1753 9.00044C11.1753 9.02741 11.1591 9.05173 11.1341 9.06206C11.1092 9.07238 11.0805 9.06667 11.0615 9.0476C11.0424 9.02852 11.0367 8.99984 11.047 8.97492C11.0573 8.94999 11.0817 8.93374 11.1086 8.93374"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1086 8.93365C11.1455 8.93365 11.1753 8.96351 11.1753 9.00034"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.95224 9.00044C6.95224 9.02741 6.93599 9.05173 6.91107 9.06206C6.88614 9.07238 6.85746 9.06667 6.83838 9.0476C6.81931 9.02852 6.8136 8.99984 6.82393 8.97492C6.83425 8.94999 6.85857 8.93374 6.88554 8.93374"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.88554 8.93365C6.92237 8.93365 6.95223 8.96351 6.95223 9.00034"
          stroke={color || '#33BA04'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
