import clsx from 'clsx';
import { CreateMessageField } from 'common/components/ui/CreateMessageField/CreateMessageField';
import { Icon } from 'common/components/ui/Icons/Icon';
import React, { useState } from 'react';
import { User } from 'routes/lmsAds/_temporaryUsersData';
import { LMSAdsUser } from '../LMSAdsUser/LMSAdsUser';

import s from './LMSAdsModal.module.scss';

export type LMSAdsModalProps = {
  visible: boolean;
  onClose: () => void;
  user: User;
};
export const LMSAdsModal: React.FC<LMSAdsModalProps> = ({ visible, onClose, user }) => {
  const [message, setMessage] = useState('');

  const handleChangeText = (e) => {
    setMessage(e.target.value);
  };

  const onSend = () => {};

  return (
    <div className={clsx(s.LMSAdsModal__wrapper, { [s.LMSAdsModal__wrapper_show]: visible })}>
      <div className={s.LMSAdsModal__header}>
        <LMSAdsUser
          {...user}
          className={s.LMSAdsModal__user}
          classNameUsername={s.LMSAdsModal__username}
          classNameRole={s.LMSAdsModal__username}
        />
        <Icon onClick={onClose} type="close" className={s.LMSAdsModal__close} />
      </div>
      <div className={s.LMSAdsModal__content}></div>
      <CreateMessageField value={message} onChange={handleChangeText} onSend={onSend} />
    </div>
  );
};
