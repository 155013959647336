import { useState } from 'react';

import { Link } from 'react-router-dom';
import { SearchInput } from '../../common/components/ui/Forms/SearchInput/SearchInput';
import { Icon } from '../../common/components/ui/Icons/Icon';
import s from './FosterСompanies.module.scss';

const items = [
  {
    items: [
      { to: '/', text: 'APM Менеджера ОП' },
      { to: '/', text: 'Мои задачи' },
      { to: '/', text: 'Работа с рекомендациями' },
      { to: '/', text: 'Создание конкурсов' }
    ]
  },
  { title: 'Приемные кампании иностранные абитуриенты бакалавриата' },
  {
    title: 'Регистрация',
    items: [
      { to: '/', text: 'APM по заявкам иностранцев' },
      { to: '/applications-of-applicants', text: 'Заявки абитуриентов' },
      { to: '/', text: 'Отказ от поступления (Бакалавриат)' },
      { to: '/', text: 'Признание документов об иностранном образовании' },
      { to: '/', text: 'Пользователи информационных систем' },
      { to: '/', text: 'Заявка на признание документов об иностранном образовании (Бакалавриат)' },
      { to: '/', text: 'Согласие на обучение (Бакалавриат)' },
      { to: '/', text: 'Отказ от поступления (Магистратура РФ)' }
    ]
  },
  {
    title: 'Вступительные испытания',
    items: [
      { to: '/', text: 'Ведомость' },
      { to: '/', text: 'Расписание вступительных испытаний' },
      { to: '/', text: 'Отказ от поступления (Бакалавриат)' }
    ]
  },
  {
    title: 'Заявки на изменение',
    items: [
      { to: '/', text: 'Заявка на признание документов (Заявка на изменение)' },
      { to: '/', text: 'Заявка поступающего в аспирантуру (Заявка на изменение)' },
      { to: '/', text: 'Заявки абитуриентов (Заявки на изменение)' },
      { to: '/', text: 'Отказ от поступления' },
      { to: '/', text: 'Отказ от поступления (Бакалавриат РФ)' },
      { to: '/', text: 'Отказ от поступления (Магистратура РФ)' },
      { to: '/', text: 'Согласие на зачисление (Заявки на изменение)' }
    ]
  },
  { title: 'Образовательные программы' },
  { title: 'Рабочее место Менеджера ОП', items: [{ to: '/', text: 'APM по заявкам иностранцев' }] },
  { title: 'Рекомендация', items: [{ to: '/', text: 'Работа с рекомендациями' }] },
  {
    title: 'Общие справочники',
    items: [
      { to: '/', text: 'Абитуриенты' },
      { to: '/', text: 'Виды документов' }
    ]
  }
];
export const FosterСompanies = () => {
  const [searchVal, setSearchVal] = useState('');

  const showItems = (type: 'left' | 'right') => {
    return items.map((item, index) => {
      const showSubItems =
        item.items &&
        item.items.map((subItem) => (
          <Link to={subItem.to} key={subItem.text} className={s.FosterСompanies__subItem}>
            {subItem.text}
          </Link>
        ));
      const content = () => (
        <div key={index} className={s.FosterСompanies__item}>
          <div className={s.FosterСompanies__item_title}>{item.title}</div>
          {item.items && <div className={s.FosterСompanies__subItems}>{showSubItems}</div>}
        </div>
      );

      return (
        <>
          {type === 'left' && index < 4 && content()}
          {type === 'right' && index >= 4 && content()}
        </>
      );
    });
  };

  const handleChangeSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <>
      <div className={s.FosterСompanies__header}>
        <SearchInput onChange={handleChangeSearchVal} value={searchVal} className={s.FosterСompanies__search} />
        <div className={s.FosterСompanies__header_actions}>
          <Icon type="clip" rounded className={s.FosterСompanies__icon} />
          <Icon type="menu" rounded className={s.FosterСompanies__icon} />
        </div>
      </div>
      <div className={s.FosterСompanies__items}>
        <div className={s.FosterСompanies__items_left}>{showItems('left')}</div>
        <div>{showItems('right')}</div>
      </div>
    </>
  );
};
