import React from 'react';
import { Helmet } from 'react-helmet';
import { fakeServices as services } from '../services/_temporaryServicesData';
import { Box } from '../../common/components/ui/Box/Box';
import { UserInfo } from '../../common/components/ui/UserInfo/UserInfo';
import { DashboardCard } from '../../common/components/ui/DashboardCard/DashboardCard';
import { LinkPrimary } from '../../common/components/ui/LinkPrimary/LinkPrimary';
import { DashboardServices } from '../../common/components/ui/DashboardServices/DashboardServices';
import { DashboardCardContent } from '../../common/components/ui/DashboardCardContent/DashboardCardContent';
import photo from '../../assets/employee.png';
import vk from '../../assets/social/vk.svg';
import instagram from '../../assets/social/instagram.svg';
import facebook from '../../assets/social/facebook.svg';
import colleague1 from './colleague-1.png';
import colleague2 from './colleague-2.png';
import { ReactComponent as Chart } from './chart.svg';
import s from './EmployeePage.module.scss';

export default function EmployeePage() {
  return (
    <React.Fragment>
      <Helmet title={'Тарасов Андрей Константинович'} />
      <Box className={s.EmployeePage}>
        <UserInfo
          name={'Тарасов Андрей Константинович'}
          about={'Специалист по работе со студентами, кафедра кибернетики'}
          photo={photo}
        />
        <div className={s.EmployeePage__cards}>
          <DashboardCard title={'Заявки'} slotRight={<LinkPrimary iconNext>Смотреть все</LinkPrimary>}>
            <div className={s.EmployeePage__applications}>
              <Chart />
              <div className={s.EmployeePage__applicationGroups}>
                <div className={s.EmployeePage__applicationGroup}>
                  <div className={s.EmployeePage__applicationGroupTitle}>Открытые</div>
                  <div className={s.EmployeePage__applicationGroupValue}>
                    <span style={{ backgroundColor: '#FF3772' }} /> 11
                  </div>
                </div>
                <div className={s.EmployeePage__applicationGroup}>
                  <div className={s.EmployeePage__applicationGroupTitle}>Обработанные</div>
                  <div className={s.EmployeePage__applicationGroupValue}>
                    <span style={{ backgroundColor: '#818FFF' }} /> 10
                  </div>
                </div>
              </div>
            </div>
          </DashboardCard>
          <DashboardCard title={'Задачи'} slotRight={<LinkPrimary iconNext>Смотреть все</LinkPrimary>}>
            <div className={s.EmployeePage__cardContent}>
              <DashboardCardContent tag={'Константинопольский К.К'}>
                <div className={s.EmployeePage__taskTitle}>Выдать студенческие группе К-31</div>
                <div className={s.EmployeePage__taskTime}>До завтра</div>
              </DashboardCardContent>
              <DashboardCardContent tag={'Константинопольский К.К'}>
                <div className={s.EmployeePage__taskTitle}>Выдать студенческие группе К-31</div>
                <div className={s.EmployeePage__taskTime}>До завтра</div>
              </DashboardCardContent>
            </div>
          </DashboardCard>
          <DashboardCard title={'Коллеги'} slotRight={<LinkPrimary iconNext>Смотреть все</LinkPrimary>}>
            <div className={s.EmployeePage__cardContent}>
              <DashboardCardContent
                tag={'+7 926 234 22 15'}
                slotBottomRight={
                  <div className={s.EmployeePage__social}>
                    <img className={s.EmployeePage__socialIcon} src={vk} alt={''} />
                    <img className={s.EmployeePage__socialIcon} src={instagram} alt={''} />
                    <img className={s.EmployeePage__socialIcon} src={facebook} alt={''} />
                  </div>
                }>
                <div className={s.EmployeePage__colleague}>
                  <img className={s.EmployeePage__colleaguePhoto} src={colleague1} alt={'Елена Петрова'} />
                  <div>
                    <div className={s.EmployeePage__colleagueName}>Елена Петрова</div>
                    <div className={s.EmployeePage__colleagueRole}>Рекрутер</div>
                  </div>
                </div>
              </DashboardCardContent>
              <DashboardCardContent
                tag={'+7 926 234 22 15'}
                slotBottomRight={
                  <div className={s.EmployeePage__social}>
                    <img className={s.EmployeePage__socialIcon} src={vk} alt={''} />
                    <img className={s.EmployeePage__socialIcon} src={instagram} alt={''} />
                    <img className={s.EmployeePage__socialIcon} src={facebook} alt={''} />
                  </div>
                }>
                <div className={s.EmployeePage__colleague}>
                  <img className={s.EmployeePage__colleaguePhoto} src={colleague2} alt={'Сергей Тарасов'} />
                  <div>
                    <div className={s.EmployeePage__colleagueName}>Сергей Тарасов</div>
                    <div className={s.EmployeePage__colleagueRole}>Менеджер проектов</div>
                  </div>
                </div>
              </DashboardCardContent>
            </div>
          </DashboardCard>
        </div>
        <DashboardServices title={'Административные сервисы'} services={services} />
      </Box>
    </React.Fragment>
  );
}
