import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as VkIcon } from '../../../../assets/social/vk.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/social/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/social/facebook.svg';
import logo from '../../../../assets/logo.png';
import { LandingBox } from '../Box/Box';
import s from './Footer.module.scss';

const OldFooter = () => {
  return (
    <LandingBox className={s.Footer__box}>
      <footer className={s.Footer}>
        <section className={s.Footer__content}>
          <Link className={s.Footer__logo} to={'/'}>
            <img src={logo} alt={''} />
          </Link>
          <div className={s.Footer__menu}>
            <div className={s.Footer__menuSection} style={{ marginRight: 64 }}>
              <h4 className={s.Footer__menuHeading}>Информация</h4>
              <div className={s.Footer__menuItem}>О нас</div>
              <div className={s.Footer__menuItem}>Правила использования</div>
              <div className={s.Footer__menuItem}>Политика конфиденциальности</div>
            </div>
            <div className={s.Footer__menuSection} style={{ marginRight: 88 }}>
              <h4 className={s.Footer__menuHeading}>Контакты</h4>
              <div className={s.Footer__menuItem}>WhatsApp</div>
              <div className={s.Footer__menuItem}>Чат 24/7</div>
            </div>
            <div className={s.Footer__menuSection}>
              <h4 className={s.Footer__menuHeading}>Поддержка</h4>
              <div className={s.Footer__menuItem}>Тех. поддержка</div>
              <div className={s.Footer__menuItem}>Круглосуточный центр</div>
            </div>
          </div>
        </section>
        <section className={s.Footer__info}>
          <div className={s.Footer__social}>
            <div className={s.Footer__socialButton}>
              <VkIcon />
            </div>
            <div className={s.Footer__socialButton}>
              <InstagramIcon />
            </div>
            <div className={s.Footer__socialButton}>
              <FacebookIcon />
            </div>
          </div>
          <p className={s.Footer__text}>Цифровая группа, 2021</p>
        </section>
      </footer>
    </LandingBox>
  );
};

export function Footer() {
  return (
    <footer className={s.Footer}>
      <section className={s.Footer__content}>
        <div className={s.Footer__menu}>
          <div className={s.Footer__menuSection}>
            <h4 className={s.Footer__menuHeading}>Техническая поддержка </h4>
            <div className={s.Footer__menuItem}>
              <span>+7 (495) 539-56-56</span>
            </div>
          </div>
          <div className={s.Footer__menuSection}>
            <h4 className={s.Footer__menuHeading}>Режим работы</h4>
            <div className={s.Footer__menuItem}>
              <span>пн.-пт. — с 9.00 до 18.00</span>
              <span>сб.-вс. — выходной</span>
            </div>
          </div>
          <div className={s.Footer__menuSection}>
            <h4 className={s.Footer__menuHeading}>Контактные данные</h4>
            <div className={s.Footer__menuItem}>Верные данные</div>
          </div>
        </div>
      </section>
    </footer>
  );
}
