import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function MessageIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.6665 8.66667H12.1865"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.6665 13.3333H13.3598"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.6665 18H14.5332"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3335 18H19.2002"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.3332 13.3333H18.5332"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9093 27.624L18.924 23.6093C19.5173 23.016 19.7973 22.1773 19.6787 21.3467L17.5267 6.28933C17.3387 4.976 16.2133 4 14.8867 4H6.66667C5.19333 4 4 5.19333 4 6.66667V20C4 21.4733 5.19333 22.6667 6.66667 22.6667H19.544"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2002 22.6667L13.6349 25.7107C13.8215 27.024 14.9469 28 16.2749 28H25.3335C26.8069 28 28.0002 26.8067 28.0002 25.3333V10.6667C28.0002 9.19333 26.8069 8 25.3335 8H18.5335"
          stroke={color || '#EE61E0'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
