import { useState } from 'react';

import { Enrollment1 } from './components/Enrollment1/Enrollment1';
import { Enrollment2 } from './components/Enrollment2/Enrollment2';
import { Enrollment3 } from './components/Enrollment3/Enrollment3';
import { Enrollment4 } from './components/Enrollment4/Enrollment4';

export const Enrollment = () => {
  const [chosenItem, setChosenItem] = useState(parseInt(localStorage.getItem('university-admission-last') || '0'));

  return (
    <div>
      {chosenItem === 0 && <Enrollment1 setChosenItem={setChosenItem} />}
      {chosenItem === 1 && <Enrollment2 setChosenItem={setChosenItem} />}
      {chosenItem === 2 && <Enrollment3 setChosenItem={setChosenItem} />}
      {chosenItem === 3 && <Enrollment4 setChosenItem={setChosenItem} />}
    </div>
  );
};
