import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function PinIcon({ onClick, className, rounded, color }: Props) {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00033 20C4.45323 20 2.9695 19.3854 1.87554 18.2915C0.781574 17.1975 0.166992 15.7138 0.166992 14.1667V4.16667C0.166992 3.0616 0.605979 2.00179 1.38738 1.22039C2.16878 0.438987 3.22859 0 4.33366 0C5.43873 0 6.49854 0.438987 7.27994 1.22039C8.06134 2.00179 8.50033 3.0616 8.50033 4.16667V14.1667C8.50033 14.8297 8.23693 15.4656 7.76809 15.9344C7.29925 16.4033 6.66337 16.6667 6.00033 16.6667C5.33728 16.6667 4.7014 16.4033 4.23256 15.9344C3.76372 15.4656 3.50033 14.8297 3.50033 14.1667V4.16667H5.16699V14.1667C5.16699 14.3877 5.25479 14.5996 5.41107 14.7559C5.56735 14.9122 5.77931 15 6.00033 15C6.22134 15 6.4333 14.9122 6.58958 14.7559C6.74586 14.5996 6.83366 14.3877 6.83366 14.1667V4.16667C6.83366 3.50363 6.57027 2.86774 6.10143 2.3989C5.63259 1.93006 4.9967 1.66667 4.33366 1.66667C3.67062 1.66667 3.03473 1.93006 2.56589 2.3989C2.09705 2.86774 1.83366 3.50363 1.83366 4.16667V14.1667C1.83366 15.2717 2.27265 16.3315 3.05405 17.1129C3.83545 17.8943 4.89526 18.3333 6.00033 18.3333C7.10539 18.3333 8.1652 17.8943 8.9466 17.1129C9.72801 16.3315 10.167 15.2717 10.167 14.1667V4.16667H11.8337V14.1667C11.8337 15.7138 11.2191 17.1975 10.1251 18.2915C9.03115 19.3854 7.54742 20 6.00033 20Z"
        fill={color || '#6B7A99'}
      />
    </svg>
  );
}
