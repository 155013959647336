import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { ServiceGrid } from '../../common/components/ui/ServiceGrid/ServiceGrid';
import { ServiceCard } from '../../common/components/ui/ServiceCard/ServiceCard';
import { LandingBox } from '../../common/components/ui/Box/Box';
import { Title } from '../../common/components/ui/Title/Title';
import { Button, ButtonType } from '../../common/components/ui/Button/Button';
import { Breadcrumbs } from '../../common/components/ui/Breadcrumbs/Breadcrumbs';
import { services } from './_temporaryServicesData';

import s from './ServicesPage.module.scss';

const breadcrumbsData = [
  { href: '/', title: 'Главная' },
  { href: '/services', title: 'Все сервисы' }
];
const filterButtons = [
  { slug: 'all', title: 'Все' },
  { slug: 'enrollee', title: 'Абитуриент' },
  { slug: 'student', title: 'Студент' },
  { slug: 'teacher', title: 'Преподаватель' },
  { slug: 'university_employee', title: 'Сотрудник вуза' }
];
export default function ServicesPage() {
  const [chosenFilter, setChosenFilter] = useState('all');

  const showButtons = filterButtons.map((item) => (
    <Button
      onClick={() => setChosenFilter(item.slug)}
      btnType={ButtonType.Filter}
      active={chosenFilter === item.slug}
      key={item.slug}
      className={s.ServicePage__buttonFilter}>
      {item.title}
    </Button>
  ));

  return (
    <LandingBox className={s.ServicesPage__wrapper}>
      <Helmet title={'Сервисы'} />
      <Breadcrumbs items={breadcrumbsData} />
      <Title
        title={
          <>
            Все сервисы <span className={s.ServicePage__counter}>{services.length}</span>
          </>
        }>
        <div className={s.ServicePage_buttonsFilter}>{showButtons}</div>
      </Title>
      <ServiceGrid>
        {services.map((service) => (
          <ServiceCard key={service.title} {...service} />
        ))}
      </ServiceGrid>
    </LandingBox>
  );
}
