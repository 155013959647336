import { ReactComponent as ArrowUpRightIcon } from 'assets/icons/arrowUpRight.svg';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import s from './LMSHomeHeader.module.scss';

export type LMSHomeHeaderProps = {
  username: string;
  averageRating: number;
  coursesCompleted: number;
  allCourses: number;
  courseProgress: number;
  visit: number;
};
export const LMSHomeHeader: React.FC<LMSHomeHeaderProps> = ({
  username,
  averageRating,
  coursesCompleted,
  allCourses,
  courseProgress,
  visit
}) => {
  const statusItems = [
    { title: 'Средняя оценка по всем курсам', status: averageRating, isArrowIcon: true, linkTo: '/' },
    {
      title: 'Пройденные курсы',
      status: `${coursesCompleted}/${allCourses}`,
      isArrowIcon: false,
      linkTo: '/'
    },
    {
      title: 'Прогресс по курсам',
      status: `${courseProgress}%`,
      isArrowIcon: false,
      linkTo: '/'
    },
    {
      title: 'Посещение',
      status: `${visit}%`,
      isArrowIcon: true,
      linkTo: '/'
    }
  ];
  const showStatusItems = statusItems.map(({ title, status, isArrowIcon, linkTo }) => (
    <div className={s.LMSHomeHeader__statusItem}>
      <div className={s.LMSHomeHeader__statusItem_title}>{title}</div>
      <div className={s.LMSHomeHeader__statusItem_footer}>
        <div className={s.LMSHomeHeader__statusItem_footer_col}>
          <div className={s.LMSHomeHeader__statusItem_status}>{status}</div>
          <ArrowUpRightIcon
            className={clsx(s.LMSHomeHeader__statusItem_iconArrow, {
              [s.LMSHomeHeader__statusItem_iconArrow_show]: isArrowIcon
            })}
          />
        </div>
        <div className={s.LMSHomeHeader__statusItem_footer_col}>
          <Link to={linkTo} className={s.LMSHomeHeader__statusItem_link}>
            Подробнее
          </Link>
        </div>
      </div>
    </div>
  ));

  return (
    <div className={s.LMSHomeHeader__wrapper}>
      <div className={s.LMSHomeHeader__title}>Добро пожаловать, {username}</div>
      <div className={s.LMSHomeHeader__statusItems}>{showStatusItems}</div>
    </div>
  );
};
