import { useState } from 'react';

// common components
import { AdmissionsOfficer } from 'common/components/ui/AdmissionsOfficer/AdmissionsOfficer';
import { ButtonType } from 'common/components/ui/Button/Button';
import { TableItem, TableRow } from 'common/components/ui/Table/Table';

// components for this page
import { Tag } from 'common/components/ui/Tags/Tags';
import { useHistory } from 'react-router';
import { ApplicationsOfApplicantsFilter } from './components/ApplicationsOfApplicantsFilter';

// components for card
import { ApplicationsOfApplicantsConsent } from './cardItems/ApplicationsOfApplicantsConsent';
import { ApplicationsOfApplicantsPrintedConsentForm } from './cardItems/ApplicationsOfApplicantsPrintedConsentForm';
import { ApplicationsOfApplicantsBasicInfo } from './cardItems/ApplicationsOfApplicantsBasicInfo';
import { ApplicationsOfApplicantsAddress } from './cardItems/ApplicationsOfApplicantsAddress';
import { ApplicationsOfApplicantsIdentityDocument } from './cardItems/ApplicationsOfApplicantsIdentityDocument';
import { ApplicationsOfApplicantsCompatriotsDocuments } from './cardItems/ApplicationsOfApplicantsCompatriotsDocuments';
import { ApplicationsOfApplicantsEducation } from './cardItems/ApplicantAgreementsEducation';

import { ApplicationsOfApplicantsForm } from './components/ApplicationsOfApplicantsForm';
import s from './ApplicationsOfApplicants.module.scss';

const buttonActions = [
  { title: 'Создать', btnType: ButtonType.Default, iconStartType: 'plus' },
  { title: 'Найти', btnType: ButtonType.Outlined },
  { iconStartType: 'pencil', btnType: ButtonType.Outlined, width: 32, height: 32 },
  { title: 'Согласование', iconEndType: 'arrow-2', iconColor: '#605BFF', btnType: ButtonType.Outlined }
];
const cardActionsLeft = [
  { title: 'Записать', btnType: ButtonType.Outlined },
  { iconStartType: 'pencil', btnType: ButtonType.Outlined, width: 32, height: 32 },
  { title: 'Согласование', iconEndType: 'arrow-2', iconColor: '#605BFF', btnType: ButtonType.Outlined }
];
const cardActionsRight = [{ title: 'Еще', iconEndType: 'arrow-2', iconColor: '#605BFF', btnType: ButtonType.Outlined }];
const tableTitles = ['Источник', 'Статус', 'Дата', 'Номер', 'Направление', 'Фамилия', 'Имя'];
const tableItems = [
  {
    id: '1',
    source: 'ЕПГУ',
    status: 'Одобрено',
    date: '12.07.2021, 17:18',
    number: '000016999',
    direction: 'Бакалавриат РФ',
    surname: 'Андронов',
    name: 'Дмитрий'
  },
  {
    id: '3',
    source: 'ЛК',
    status: 'Новое',
    date: '12.07.2021, 17:18',
    number: '000016999',
    direction: 'Бакалавриат РФ',
    surname: 'Андронов',
    name: 'Дмитрий'
  },
  {
    id: '4',
    source: 'ЕПГУ',
    status: 'Отклонено',
    date: '12.07.2021, 17:18',
    number: '000016999',
    direction: 'Бакалавриат РФ',
    surname: 'Андронов',
    name: 'Дмитрий'
  },
  {
    id: '5',
    source: 'ЛК',
    status: 'Одобрено',
    date: '09.07.2022, 15:20',
    number: '000016999',
    direction: 'Бакалавриат РФ',
    surname: 'Иванова',
    name: 'Ирина'
  },
  {
    id: '6',
    source: 'ЕПГУ',
    status: 'Одобрено',
    date: '09.07.2022, 15:20',
    number: '000010915',
    direction: 'Бакалавриат РФ',
    surname: 'Орлов',
    name: 'Богдан'
  },
  {
    id: '7',
    source: 'ПК',
    status: 'Одобрено',
    date: '09.07.2022, 15:20',
    number: '000010915',
    direction: 'Бакалавриат РФ',
    surname: 'Ахматова',
    name: 'Анна'
  },
  {
    id: '9',
    source: 'ЛК',
    status: 'Одобрено',
    date: '09.07.2022, 15:20',
    number: '000010915',
    direction: 'Бакалавриат РФ',
    surname: 'Александровна',
    name: 'Лидия'
  }
];

export const ApplicationsOfApplicants = () => {
  const history = useHistory();

  const [isApplicationCard, setIsApplicationCard] = useState(false);

  const [isOverview, setIsOverview] = useState(false);
  const [isSolution, setIsSolution] = useState(false);
  const [isConditions, setIsConditions] = useState(false);
  const [isCostOfEducation, setIsCostOfEducation] = useState(false);
  const [isApprovalSheet, setIsApprovalSheet] = useState(false);
  const [isMailingList, setIsMailingList] = useState(false);
  const [isOriginals, setIsOriginals] = useState(false);

  const cardItems = [
    {
      title: 'Согласие на сбор и обработку персональных данных',
      setVal: setIsOverview,
      active: isOverview,
      children: <ApplicationsOfApplicantsConsent />
    },
    {
      title: 'Печатная форма согласия',
      setVal: setIsSolution,
      active: isSolution,
      children: <ApplicationsOfApplicantsPrintedConsentForm />
    },
    {
      title: 'Основная информация',
      setVal: setIsConditions,
      active: isConditions,
      children: <ApplicationsOfApplicantsBasicInfo />
    },
    {
      title: 'Документ, удостоверяющий личность, гражданство',
      setVal: setIsCostOfEducation,
      active: isCostOfEducation,
      children: <ApplicationsOfApplicantsIdentityDocument />
    },
    {
      title: 'Данные о месте регистрации и о месте проживания',
      setVal: setIsApprovalSheet,
      active: isApprovalSheet,
      children: <ApplicationsOfApplicantsAddress />
    },
    {
      title: 'Документы соотечественника',
      setVal: setIsMailingList,
      active: isMailingList,
      children: <ApplicationsOfApplicantsCompatriotsDocuments />
    },
    {
      title: 'Образование',
      setVal: setIsOriginals,
      active: isOriginals,
      children: <ApplicationsOfApplicantsEducation />
    }
  ];

  const tableChildren = tableItems.map((item) => {
    const showSubItems = Object.entries(item).map(
      ([key, value], index) =>
        key !== 'id' && (
          <TableItem key={index} className={s.ApplicationsOfApplicants__table__item}>
            {key === 'status' ? (
              <Tag
                color={
                  value === 'Одобрено'
                    ? '#53C120'
                    : value === 'Ошибка'
                    ? '#F43F5E'
                    : value === 'Новое'
                    ? '#1ABCDF'
                    : value === 'Отклонено'
                    ? '#6B7A99'
                    : ''
                }
                title={value}
                className={s.ApplicationsOfApplicants__table__tag}
              />
            ) : (
              value
            )}
          </TableItem>
        )
    );

    return (
      <TableRow
        onClick={() => setIsApplicationCard(true)}
        className={s.ApplicationsOfApplicants__table__row}
        key={item.id}>
        {showSubItems}
      </TableRow>
    );
  });

  const onNotification = () => {
    localStorage.setItem('university-admission', '4');
    history.push('/university-admission');
    window.location.reload();
  };

  return (
    <AdmissionsOfficer
      title="Заявки абитуриентов"
      actionsLeft={buttonActions}
      cardActionsLeft={cardActionsLeft}
      cardActionsRight={cardActionsRight}
      cardTitle="Заявка абитуриентa 000016953 от 12.07.2021"
      filterChildren={<ApplicationsOfApplicantsFilter />}
      tableTitles={tableTitles}
      tableChildren={tableChildren}
      cardItems={cardItems}
      cardTopChildren={<ApplicationsOfApplicantsForm setIsApplicationCard={setIsApplicationCard} />}
      isCard={isApplicationCard}
      setCard={setIsApplicationCard}
      onNotification={onNotification}
    />
  );
};
