import React from 'react';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { EventItem } from 'routes/calendar/_temporaryEventsData';

import { ScheduleEvent } from 'common/components/ui/ScheduleEvent/ScheduleEvent';
import s from './Schedule.module.scss';

export type ScheduleProps = {
  items: Array<EventItem>;
  className?: string;
};
export const Schedule: React.FC<ScheduleProps> = ({ items, className }) => {
  const time: Array<{ dateFrom: number; dateTo: number }> = [];
  items.map((item, index) => {
    if (index < 3) {
      if (time.length) {
        if (
          !time.some(
            (timeItem) => moment.unix(timeItem.dateFrom).format('hh:mm') === moment.unix(item.dateFrom).format('hh:mm')
          )
        ) {
          time.push({ dateFrom: item.dateFrom, dateTo: item.dateTo });
        }
      } else {
        time.push({ dateFrom: item.dateFrom, dateTo: item.dateTo });
      }
    }
  });

  const comparisonTime = (myTime: Moment, item: { dateFrom: number; dateTo: number }) => {
    return myTime.isSameOrAfter(moment.unix(item.dateFrom)) && myTime.isSameOrBefore(moment.unix(item.dateTo));
  };

  const timeNum = (myTime: Moment) => {
    let indexNum: any = 0;
    time.filter((item) => {
      if (comparisonTime(myTime, item)) {
        indexNum = item.dateFrom;
      }
    });
    const index = time.map((item) => item.dateFrom).indexOf(indexNum);
    return index;
  };

  const isSomeDateFrom = (dateFrom, id) => {
    return items.some((i) => i.dateFrom === dateFrom && i.id !== id);
  };

  const showTime = time
    .sort((left, right) => moment.utc(left.dateFrom).diff(moment.utc(right.dateFrom)))
    .map((item, index) => (
      <div
        key={index}
        className={clsx(s.Schedule__item, s.Schedule__time, {
          // [s.Schedule__time_active]: comparisonTime(moment(), item)
        })}>
        {moment.unix(item.dateFrom).format('hh:mm')} - {moment.unix(item.dateTo).format('hh:mm')}
      </div>
    ));

  const showEvents = items
    .sort((left, right) => moment.utc(left.dateFrom).diff(moment.utc(right.dateFrom)))
    .map((event, index) => index < 3 && <ScheduleEvent {...event} className={s.Schedule__eventItem} />);

  return (
    <div className={clsx(s.Schedule__wrapper, className)}>
      <div className={s.Schedule__dateWrapper}>
        {moment().calendar(null, {
          sameDay: '[Сегодня]',
          sameElse: () => moment().format('D.MMMM.YYYY')
        })}{' '}
        {moment().format('D MMMM')}, четная неделя
      </div>
      <div className={s.Schedule__body}>
        <div className={clsx(s.Schedule__line, { [s.Schedule__line_show]: timeNum(moment()) !== -1 })} />
        <div className={s.Schedule__tasks}>{showTime}</div>
        <div className={s.Schedule__tasks}>{showEvents}</div>
      </div>
    </div>
  );
};
