import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function ArrowIcon3({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.94519 0L5.94519 5L10.9452 0H0.94519Z"
        fill={color || '#222845'}
      />
    </svg>
  );
}
