import { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../common/components/ui/Button/Button';
import { TestTask2Dropdown } from './TestTask2Dropdown';
import s2 from '../TestTask.module.scss';
import s from './TestTask2.module.scss';

type Props = {
  setItem: (number: number) => void;
  setChosenProgress: (number: number) => void;
};
export const TestTask2: React.FC<Props> = ({ setItem, setChosenProgress }) => {
  const [resultsForArcheology, setResultsForArcheology] = useState(false);
  const [resultsForGeography, setResultsForGeography] = useState(false);

  const onToggleResultsForArcheology = () => {
    setResultsForArcheology(!resultsForArcheology);
  };
  const onToggleResultsForGeography = () => {
    setResultsForGeography(!resultsForGeography);
  };

  const items = [
    {
      title: 'Результаты по направлению Археология',
      status: 'Заполнено',
      items: [
        { title: 'Балл за русский язык', number: 96 },
        { title: 'Балл за английский язык', number: 87 },
        { title: 'Балл за историю', number: 89 }
      ],
      allResults: 272,
      onToggle: onToggleResultsForArcheology,
      active: resultsForArcheology
    },
    {
      title: 'Результаты по направлению География',
      status: 'Заполнено',
      items: [
        { title: 'Балл за русский язык', number: 96 },
        { title: 'Балл за английский язык', number: 87 },
        { title: 'Балл за историю', number: 89 }
      ],
      allResults: 272,
      onToggle: onToggleResultsForGeography,
      active: resultsForGeography
    }
  ];

  const showItems = items.map((item) => (
    <TestTask2Dropdown {...item} key={item.title} className={s.TestTask2Dropdown} />
  ));

  return (
    <div className={s.TestTask2}>
      <div className={clsx(s.TestTask2__title, s2.TestTask__title)}>Мои результаты испытательных заданий</div>
      {showItems}
      <div className={s.TestTask2__footer}>
        <div onClick={() => setItem(2)} className={s.TestTask2__link}>
          Смотреть результаты других кандидатов
        </div>
        <Button onClick={() => setChosenProgress(5)}>Далее</Button>
      </div>
    </div>
  );
};
