import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IService } from '../../../../routes/services/_temporaryServicesData';

import { Card } from '../Card/Card';
import { Icon } from '../Icons/Icon';
import { Tags } from '../Tags/Tags';
import s from './ServiceCard.module.scss';

export interface IServiceCardProps extends IService {}
export function ServiceCard({
  id,
  to,
  title,
  description,
  tags,
  icon,
  iconColor,
  universityAdmission
}: IServiceCardProps) {
  const history = useHistory();

  const onClick = () => {
    const universityAdmissionNum = universityAdmission?.toString();
    if (to && universityAdmissionNum) {
      console.log('keke');
      localStorage.setItem('university-admission', universityAdmissionNum);
      history.push(to);
    }
  };

  const card = (
    <Card className={s.ServiceCard__card} onClick={onClick}>
      <div className={s.ServiceCard__content}>
        <div className={s.ServiceCard__header}>
          <Icon type={icon} rounded className={s.ServiceCard__icon} color={iconColor} />
          <div className={s.ServiceCard__title}>{title}</div>
        </div>
        <div className={s.ServiceCard__description}>{description}</div>
      </div>
      <div className={s.ServiceCard__footer}>
        <Tags tags={tags} />
      </div>
    </Card>
  );

  return <div className={s.ServiceCard}>{card}</div>;
}
