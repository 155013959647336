import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { LandingBox } from '../../common/components/ui/Box/Box';
import { Breadcrumbs } from '../../common/components/ui/Breadcrumbs/Breadcrumbs';
import { Title } from '../../common/components/ui/Title/Title';
import { ProgressBar } from './components/ProgressBar';
import { LevelOfPreparation } from './components/LevelOfPreparation';
import { Specialization } from './components/Specialization';
import { EducationalProgram } from './components/EducationalProgram/EducationalProgram';
import { FilingAnApplication } from './components/FilingAnApplication/FilingAnApplication';
import { TestTask } from './components/TestTask/TestTask';
import { Enrollment } from './components/Enrollment/Enrollment';
import s from './UniversityAdmission.module.scss';

const breadcrumbsData = [
  { href: '/', title: 'Главная' },
  { href: '/services', title: 'Все сервисы' },
  { href: '/univercity-admission', title: 'Поступи в вуз онлайн' }
];
export default function UniversityAdmission() {
  const [chosenProgress, setChosenProgress] = useState(parseInt(localStorage.getItem('university-admission') || '0'));

  const setItem = (number) => {
    setChosenProgress(number);
    localStorage.setItem('university-admission', number);
  };

  return (
    <LandingBox className={s.UniversityAdmission__wrapper}>
      <Helmet title={'Сервисы'} />
      <Breadcrumbs items={breadcrumbsData} />
      <Title title="Поступи в вуз онлайн" className={s.UniversityAdmission__title} />
      <ProgressBar chosenProgress={chosenProgress} type="first" setChosenProgress={setItem} />
      {chosenProgress === 0 && <LevelOfPreparation setChosenProgress={setItem} />}
      {chosenProgress === 1 && <Specialization setChosenProgress={setItem} />}
      {chosenProgress === 2 && <EducationalProgram setChosenProgress={setItem} />}
      {chosenProgress === 3 && <FilingAnApplication setChosenProgress={setItem} />}
      {chosenProgress === 4 && <TestTask setChosenProgress={setItem} />}
      {chosenProgress === 5 && <Enrollment />}
      <ProgressBar chosenProgress={chosenProgress} type="second" setChosenProgress={setItem} />
    </LandingBox>
  );
}
