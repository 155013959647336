import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};
export const ClipIcon: React.FC<Props> = ({ onClick, className, rounded, color }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.84766 12.651L13.151 7.34766"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8258 13.9771L9.61604 16.1868C8.91274 16.8899 7.95897 17.2848 6.96452 17.2847C5.97007 17.2846 5.01638 16.8896 4.31319 16.1864C3.61001 15.4832 3.21493 14.5295 3.21484 13.5351C3.21476 12.5406 3.60968 11.5868 4.31274 10.8835L6.52245 8.67383"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4771 11.3253L16.6868 9.11555C17.3899 8.41225 17.7848 7.45849 17.7847 6.46404C17.7847 5.46958 17.3896 4.51589 16.6864 3.81271C15.9832 3.10952 15.0295 2.71444 14.0351 2.71436C13.0406 2.71427 12.0868 3.10919 11.3835 3.81225L9.17383 6.02196"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
