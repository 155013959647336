import { LocationStore } from '@proscom/prostore-react-router';
import { appHistory } from './appHistory';
import { urlTransformers } from './store/urlKeys';
import { STORE_LOCATION } from './store/storeKeys';

// Создаем LocationStore для отслеживания изменений в адресной строке
// Передаем ему набор трансформеров, чтобы преобразовывать параметры
// из строки в значения и наоборот
export const locationStore = new LocationStore({
  history: appHistory,
  transformers: urlTransformers
});

export const appStores = {
  [STORE_LOCATION]: locationStore
};
