import { Filter } from './components/Filter';
import { EducationalProgramList } from './components/EducationalProgramList';
import s from './EducationalProgram.module.scss';

const items = [
  {
    id: '1',
    logo: 'coat-of-arms-moscow',
    title: 'Арабистика: язык, словесность, культура',
    city: 'Москва',
    educationalInstitution: 'Институт классического Востока и античности',
    duration: 5,
    formOfEducation: 'full-time',
    budgetPlaces: 35,
    paidPlaces: 40,
    placesForForeigners: 10
  },
  {
    id: '2',
    logo: 'coat-of-arms-piter',
    title: 'Востоковедение',
    city: 'Москва',
    educationalInstitution: 'Институт классического Востока и античности',
    duration: 5,
    formOfEducation: 'full-time',
    budgetPlaces: 35,
    paidPlaces: 40,
    placesForForeigners: 10
  },
  {
    id: '3',
    logo: 'coat-of-arms-moscow',
    title: 'Востоковедение',
    city: 'Москва',
    educationalInstitution: 'Институт классического Востока и античности',
    duration: 5,
    formOfEducation: 'full-time',
    budgetPlaces: 35,
    paidPlaces: 40,
    placesForForeigners: 10
  },
  {
    id: '4',
    logo: 'coat-of-arms-moscow',
    title: 'Христианский Восток',
    city: 'Москва',
    educationalInstitution: 'Институт классического Востока и античности',
    duration: 5,
    formOfEducation: 'full-time',
    budgetPlaces: 35,
    paidPlaces: 40,
    placesForForeigners: 10
  },
  {
    id: '5',
    logo: 'coat-of-arms-piter',
    title: 'Язык, словесность и культура Китая',
    city: 'Москва',
    educationalInstitution: 'Институт классического Востока и античности',
    duration: 5,
    formOfEducation: 'full-time',
    budgetPlaces: 35,
    paidPlaces: 40,
    placesForForeigners: 10
  }
];
type Props = {
  setChosenProgress: (number: number) => void;
};
export const EducationalProgram: React.FC<Props> = ({ setChosenProgress }) => {
  const chosenProgram = { code: '58.00.00', title: 'ВОСТОКОВЕДЕНИЕ И АФРИКАНИСТИКА' };

  return (
    <div className={s.EducationalProgram__wrapper}>
      <Filter />
      <div className={s.EducationalProgram__title}>
        <span className={s.EducationalProgram__programCode}>{chosenProgram.code}</span>
        <span className={s.EducationalProgram__programTitle}>{chosenProgram.title}</span>
      </div>
      <EducationalProgramList setChosenProgress={setChosenProgress} items={items} />
    </div>
  );
};
