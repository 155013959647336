import { useEffect, useState } from 'react';
import moment from 'moment';
import { useToggle } from '@proscom/ui-react';

import { EventItem } from 'routes/calendar/_temporaryEventsData';
import { CalendarHeader } from './CalendarHeader';
import { Participants } from './Participants';
import { CalendarGrid } from './CalendarGrid';
import s from './Calendar.module.scss';

moment.updateLocale('ru', {
  week: { dow: 0 },
  calendar: {
    lastDay: '[вчера]',
    sameDay: '[сегодня]',
    nextDay: '[завтра]',
    lastWeek: '[прошлая неделя]',
    nextWeek: '[следующая неделя]',
    sameElse: 'L'
  }
});

export enum CalendarType {
  Week = 7,
  Month = 21
}

type Props = {
  items: Array<EventItem>;
  isEvents: boolean;
  toggleNotification: () => void;
};
export const Calendar: React.FC<Props> = ({ items, isEvents, toggleNotification }) => {
  const [days, setDays] = useState(CalendarType.Week);
  const [today, setToday] = useState(moment());
  const [startDay, setStartDay] = useState(moment());
  const [dayArr, setDayArr] = useState<Array<any>>([]);
  const isParticipants = useToggle();

  useEffect(() => {
    setDayArr([...Array(days)].map((_, index) => moment(today.startOf('w')).clone().add(index, 'd').clone()));
  }, [days, startDay]);

  useEffect(() => {
    setStartDay(today.clone().startOf('month').startOf('week'));
  }, [today]);

  const beforeHandler = () => {
    if (days === CalendarType.Month) {
      setToday((prev) => moment(prev, 'd MMMM').clone().subtract(21, 'd'));
    } else {
      setToday((prev) => moment(prev, 'd MMMM').clone().subtract(1, 'w'));
    }
  };
  const todayHandler = () => {
    setToday(moment());
  };

  const onToggleParticipants = () => {
    isParticipants.toggle();
    // if (isParticipants.value) {
    //   window.location.reload();
    // }
  };

  const nextHandler = () => {
    if (days === CalendarType.Month) {
      setToday((prev) => moment(prev, 'd MMMM').clone().add(21, 'd'));
    } else {
      setToday((prev) => moment(prev, 'd MMMM').clone().add(1, 'w'));
    }
  };

  const onChangeCalendarType = (newDays: CalendarType) => {
    setDays(newDays);
  };

  const ddd = () => {
    console.log('kekekke');
    isParticipants.toggle();
  };

  return (
    <div>
      <Participants visible={isParticipants.value} toggle={onToggleParticipants} />
      <CalendarHeader
        days={dayArr}
        today={today}
        beforeHandler={beforeHandler}
        todayHandler={todayHandler}
        nextHandler={nextHandler}
        onChangeCalendarType={onChangeCalendarType}
        isMonth={days === CalendarType.Month}
      />
      <CalendarGrid
        dayArr={dayArr}
        days={days}
        items={items}
        startDay={startDay}
        today={today}
        isEvents={isEvents}
        toggleNotification={toggleNotification}
        toggleParticipants={isParticipants.toggle}
      />
      <div style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}></div>
    </div>
  );
};
