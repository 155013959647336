import clsx from 'clsx';
import { Card } from '../../../../../common/components/ui/Card/Card';
import s from './TestTask3Programms.module.scss';


type Props = {
  items: Array<{
    id: string;
    date: string;
    title: string;
  }>;
};
export const TestTask3Programms: React.FC<Props> = ({ items }) => {
  const showItems = items.map((item) => (
    <Card key={item.id} className={s.TestTask3Programms__card}>
      <span className={clsx(s.TestTask3Programms__card_item, s.TestTask3Programms__date)}>{item.date}</span>
      <span className={clsx(s.TestTask3Programms__card_item, s.TestTask3Programms__titl)}>{item.title}</span>
      <span className={clsx(s.TestTask3Programms__card_item, s.TestTask3Programms__showMore)}>Открыть список</span>
    </Card>
  ));

  return <div className={s.TestTask3Programms}>{showItems}</div>;
};
