import clsx from 'clsx';
import React from 'react';
import { ReactComponent as NextIcon } from './next.svg';
import s from './LinkPrimary.module.scss';

export interface ILinkPrimaryProps {
  children: string;
  iconNext?: boolean;
  className?: string;
}

export function LinkPrimary({ children, iconNext, className }: ILinkPrimaryProps) {
  return (
    <div className={clsx(s.LinkPrimary, className)}>
      {children}
      {iconNext && <NextIcon />}
    </div>
  );
}
