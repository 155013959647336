import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { IService } from '../../../../routes/services/_temporaryServicesData';
import { LinkPrimary } from '../LinkPrimary/LinkPrimary';
import { Tags } from '../Tags/Tags';
import s from './DashboardServices.module.scss';

export interface IDashboardServicesProps {
  title: string;
  services: IService[];
}

export function DashboardServices({ title, services }: IDashboardServicesProps) {
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const listRef = useRef<HTMLDivElement>(null);

  const handleRightArrowClick = () => {
    if (listRef.current) {
      const { offsetWidth, scrollLeft, scrollWidth } = listRef.current;
      if (1.25 * offsetWidth + scrollLeft >= scrollWidth) setShowRightArrow(false);
      listRef.current.scrollBy({ left: 0.25 * offsetWidth, behavior: 'smooth' });
      setShowLeftArrow(true);
    }
  };

  const handleLeftArrowClick = () => {
    if (listRef.current) {
      const { scrollLeft, offsetWidth } = listRef.current;
      if (scrollLeft - 0.25 * offsetWidth <= 0) setShowLeftArrow(false);
      listRef.current.scrollBy({ left: -0.25 * offsetWidth, behavior: 'smooth' });
      setShowRightArrow(true);
    }
  };

  useEffect(() => {
    const checkArrows = () => {
      if (listRef.current) {
        const { offsetWidth, scrollLeft, scrollWidth } = listRef.current;
        setShowLeftArrow(scrollLeft !== 0);
        setShowRightArrow(offsetWidth + scrollLeft < scrollWidth);
      }
    };

    window.addEventListener('resize', checkArrows);
    return () => {
      window.removeEventListener('resize', checkArrows);
    };
  }, []);

  return (
    <section className={s.DashboardServices}>
      <div className={s.DashboardServices__header}>
        <h3 className={s.DashboardServices__title}>{title}</h3>
        <LinkPrimary iconNext>Смотреть все</LinkPrimary>
      </div>

      {showLeftArrow && (
        <button
          className={clsx(s.DashboardServices__arrow, s.DashboardServices__arrow_left)}
          onClick={handleLeftArrowClick}>
          <i className={'ci-short_left'} />
        </button>
      )}

      {showRightArrow && (
        <button
          className={clsx(s.DashboardServices__arrow, s.DashboardServices__arrow_right)}
          onClick={handleRightArrowClick}>
          <i className={'ci-short_right'} />
        </button>
      )}

      <div ref={listRef} className={s.DashboardServices__services}>
        {services.map((service) => (
          <div key={service.title} className={s.DashboardService}>
            <div className={s.DashboardService__icon}>{/* <service.icon /> */}</div>
            <div className={s.DashboardService__content}>
              <h4 className={s.DashboardService__title}>{service.title}</h4>
              <p className={s.DashboardService__description}>{service.description}</p>
              <Tags tags={service.tags} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
