import React from 'react';
import { LandingHeading } from '../LandingHeading/LandingHeading';
import { Input } from '../Forms/Input/Input';
import { Button, ButtonSize } from '../Button/Button';
import { LandingBox } from '../Box/Box';
import { useMediaQueryResult } from '../../../hooks/useMediaQueryResult';
import s from './LandingApplication.module.scss';

export interface ILandingApplicationProps {
  background?: string;
}

export function LandingApplication({ background: backgroundColor }: ILandingApplicationProps) {
  const isDesktop = useMediaQueryResult('(min-width: 768px)');
  return (
    <section id={'application'} className={s.LandingApplication} style={{ backgroundColor }}>
      <LandingBox className={s.LandingApplication__content}>
        <LandingHeading>Оставить заявку на подключение</LandingHeading>
        <div className={s.LandingApplication__inputs}>
          <Input
            placeholder={'Имя'}
            hasBackground={!!backgroundColor}
            value=""
            className={s.LandingApplication__nameInput}
          />
          <Input
            placeholder={'E–mail'}
            value=""
            hasBackground={!!backgroundColor}
            className={s.LandingApplication__emailInput}
          />
          <Button size={isDesktop ? ButtonSize.Large : ButtonSize.Medium}>Оставить заявку</Button>
        </div>
      </LandingBox>
    </section>
  );
}
