import { FC, FormEvent } from 'react';
import { FieldProps } from 'formik';
import { default as BaseSelect, ReactSelectProps } from 'react-select';
import clsx from 'clsx';

import Arrow from '../../../../../assets/icons/arrow.svg';
import { Icon } from '../../Icons/Icon';
import { FieldSize } from '../basicTypes';
import s from './Select.module.scss';

type Props = {
  fullWidth?: boolean;
  size?: FieldSize;
  isCopy?: boolean;
};
export const Select: FC<Props & ReactSelectProps & FieldProps> = ({
  onChange,
  name,
  value,
  options,
  className,
  fullWidth,
  size = FieldSize.Small,
  isCopy
}) => {
  const styles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 32,
      height: size,
      border: 0,
      borderRadius: 8,
      boxShadow: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      paddingRight: 35,
      width: 10,
      height: size,
      background: `no-repeat url(${Arrow})`,
      backgroundPosition: 'center'
    }),
    menu: (provided) => ({
      ...provided,
      width: 'calc(100% + 38px)',
      marginLeft: -10
    })
  };

  return (
    <div className={clsx(s.Select, { fullWidth }, className)}>
      <BaseSelect
        onChange={onChange}
        name={name}
        defaultValue={value || options[0]}
        value={value}
        options={options}
        styles={styles}
        className={clsx(s.Select__select)}
        isSearchable
      />
      {isCopy && (
        <Icon onClick={() => navigator.clipboard.writeText(value.label)} type="copy" className={s.Select__icon} />
      )}
    </div>
  );
};
