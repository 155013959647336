import { TestTask3Programms } from './TestTask3Programms';
import s2 from './TestTask3.module.scss';
import s from './TestTask3MyPrograms.module.scss';


const items = [
  {
    id: '1',
    date: '06.00.00',
    title: 'Античность (направление 45.03.01 Филология)'
  },
  {
    id: '2',
    date: '06.00.00',
    title: 'Античность (направление 46.03.01 История)'
  }
];
export const TestTask3MyPrograms = () => {
  return (
    <div className={s.TestTask3MyPrograms}>
      <div className={s2.TestTask3__h2}>Мои программы</div>
      <TestTask3Programms items={items} />
    </div>
  );
};
