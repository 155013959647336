import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function BrainIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8392 5.87129C17.3499 6.26718 16.6504 6.26718 16.1611 5.87129C15.0917 5.01787 13.6604 4.76998 12.3663 5.21406C11.0721 5.65815 10.0946 6.73264 9.77448 8.06287C9.66128 8.58044 9.25605 8.98411 8.73805 9.0953C7.21523 9.40438 5.94599 10.4509 5.35228 11.8868C4.75858 13.3228 4.91822 14.9601 5.77815 16.2543C6.08801 16.708 6.08801 17.3052 5.77815 17.7589C4.91822 19.0531 4.75858 20.6904 5.35228 22.1264C5.94599 23.5623 7.21523 24.6088 8.73805 24.9179C9.25605 25.0291 9.66128 25.4328 9.77448 25.9503C10.0963 27.2794 11.0746 28.3521 12.3685 28.7948C13.6623 29.2374 15.0926 28.9887 16.1611 28.1352C16.6504 27.7393 17.3499 27.7393 17.8392 28.1352C18.9095 28.9886 20.3418 29.2357 21.6362 28.7902C22.9306 28.3447 23.9075 27.2684 24.2258 25.937C24.339 25.4194 24.7443 25.0157 25.2623 24.9046C26.7853 24.5953 28.0546 23.5485 28.648 22.1121C29.2415 20.6758 29.0814 19.0383 28.2208 17.7442C27.9122 17.2912 27.9122 16.6954 28.2208 16.2423C29.0814 14.9482 29.2415 13.3107 28.648 11.8744C28.0546 10.4381 26.7853 9.39122 25.2623 9.08196C24.7443 8.97077 24.339 8.56711 24.2258 8.04953C23.904 6.72045 22.9257 5.64775 21.6319 5.2051C20.338 4.76246 18.9077 5.01119 17.8392 5.86462V5.87129Z"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 6.16614V27.8325"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0017 22.3355C21.0017 20.8621 22.1961 19.6677 23.6695 19.6677"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9983 11.6644C12.9983 13.1378 11.8039 14.3322 10.3306 14.3322"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9983 22.3355C12.9983 20.8621 11.8039 19.6677 10.3306 19.6677"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0017 11.6644C21.0017 13.1378 22.1961 14.3322 23.6695 14.3322"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
