import clsx from 'clsx';
import React from 'react';
import { Card } from '../../../../../common/components/ui/Card/Card';
import { EllipseIcon } from '../../../../../common/components/ui/Icons';
import { Icon } from '../../../../../common/components/ui/Icons/Icon';

import s from './EducationalProgramList.module.scss';

type Program = {
  id: string;
  logo: string;
  title: string;
  city: string;
  educationalInstitution: string;
  duration: number;
  formOfEducation: string;
  budgetPlaces: number;
  paidPlaces: number;
  placesForForeigners: number;
};
type Props = {
  items: Program[];
  setChosenProgress: (number: number) => void;
};
export const EducationalProgramList: React.FC<Props> = ({ items, setChosenProgress }) => {
  const changeChosenProgress = () => {
    setChosenProgress(3);
  };

  const showItems = items.map((item) => (
    <Card key={item.id} className={s.EducationalProgramList__item}>
      <div className={(s.EducationalProgramList__column, s.EducationalProgramList__column_first)}>
        <Icon type={item.logo} className={s.EducationalProgramList__logo} rounded />
        <div onClick={changeChosenProgress} className={s.EducationalProgramList__title}>
          {item.title}
        </div>
        <div className={clsx(s.EducationalProgramList__content, s.EducationalProgramList__cityWrapper)}>
          <div className={s.EducationalProgramList__city}>
            {item.city} <EllipseIcon className={s.EducationalProgramList__ellipse} />
          </div>
          <span>{item.educationalInstitution}</span>
        </div>
      </div>
      <div className={s.EducationalProgramList__column}>
        <div className={s.EducationalProgramList__title}>{item.duration} лет</div>
        <div className={s.EducationalProgramList__content}>
          <span>{item.formOfEducation}</span>
        </div>
      </div>
      <div className={s.EducationalProgramList__column}>
        <div className={s.EducationalProgramList__title}>{item.budgetPlaces} бюджетных</div>
        <div className={s.EducationalProgramList__content}>
          <div className={s.EducationalProgramList__placesItem}>{item.paidPlaces} платных</div>
          <div className={s.EducationalProgramList__placesItem}>
            {item.placesForForeigners} платных мест для иностранцев
          </div>
        </div>
      </div>
      <div className={s.EducationalProgramList__column}>
        <div>Программа вступительных испытаний</div>
        <div className={s.EducationalProgramList__pdf}>
          <Icon type="eye" className={s.EducationalProgramList__iconEye} color="#605bff" />
          Открыть PDF
        </div>
      </div>
    </Card>
  ));

  return <div className={s.EducationalProgramList__wrapper}>{showItems}</div>;
};
