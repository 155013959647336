import React, { useState } from 'react';

import { Course, courses } from 'routes/lmsHome/_temporaryCoursesData';

import Avatar1 from 'assets/avatar-1.png';
import Avatar2 from 'assets/avatar-2.png';
import Avatar3 from 'assets/avatar-3.png';

import { LandingBox } from 'common/components/ui/Box/Box';
import { Title } from 'common/components/ui/Title/Title';
import { Filter2 } from 'common/components/ui/Filter2/Filter2';
import { LMSHomeCourseCard } from './components/LMSHomeCourseCard/LMSHomeCourseCard';
import { LMSHomeHeader } from './components/LMSHomeHeader/LMSHomeHeader';
import s from './LMSHomePage.module.scss';

const FILTER_ITEMS = [
  { slug: 'current', text: 'Текущие' },
  { slug: 'future', text: 'Будущие' },
  { slug: 'past', text: 'Прошедшие' }
];

export const LMSHomePage = () => {
  const [chosenFilterItem, setChosenFilterItem] = useState(FILTER_ITEMS[0].slug);

  const showItems = courses.map((item) => <LMSHomeCourseCard {...item} />);

  return (
    <LandingBox className={s.LMSHomePage__wrapper}>
      <LMSHomeHeader
        username="Аглая"
        averageRating={7.3}
        coursesCompleted={21}
        allCourses={80}
        courseProgress={40}
        visit={8}
      />
      <div className={s.LMSHomePage__content}>
        <Title title="Мои курсы" className={s.LMSHomePage__title}>
          <Filter2
            items={FILTER_ITEMS}
            setChosenItem={setChosenFilterItem}
            chosenItem={chosenFilterItem}
            classNameItem={s.LMSHomePage__filter_item}
          />
        </Title>
        <div className={s.LMSHomePage__courses}>{showItems}</div>
      </div>
    </LandingBox>
  );
};
