import { User } from 'routes/lmsAds/_temporaryUsersData';
import Avatar from 'routes/lmsAds/assets/avatar.png';

export type Ad = {
  id: string;
  title: string;
  description: string;
  date: number;
  user: User;
};

export const ads: Array<Ad> = [
  {
    id: '1',
    title: 'Перенос дедлайна по лекции истории',
    description:
      'Ребята, я заболела, поэтому давайте перенесем занятие. Все домашние работы видела, ответ дам в личных сообщениях каждому. Не болейте',
    date: 4,
    user: {
      id: '1',
      avatar: Avatar,
      username: 'Дмитрий Андреев',
      role: 'Лектор'
    }
  },
  {
    id: '2',
    title: 'Ищу коллегу для работы над проектом “Великие воины России”',
    description:
      'Для поиска источников материалов трачу много времени сидя в библиотеке. Буду рад напарнику и такому жаждующему знаний человеку как я, чтобы совместно написать доклад для выступления на конференции “Молодые ученые страны”',
    date: 4,
    user: {
      id: '1',
      avatar: Avatar,
      username: 'Василий Карамзин',
      role: 'Студент группы L-312'
    }
  },
  {
    id: '3',
    title: 'Перенос дедлайна по лекции истории',
    description:
      'Ребята, я заболела, поэтому давайте перенесем занятие. Все домашние работы видела, ответ дам в личных сообщениях каждому. Не болейте',
    date: 4,
    user: {
      id: '1',
      avatar: Avatar,
      username: 'Дмитрий Андреев',
      role: 'Лектор'
    }
  },
  {
    id: '4',
    title: 'Перенос дедлайна по лекции истории',
    description:
      'Ребята, я заболела, поэтому давайте перенесем занятие. Все домашние работы видела, ответ дам в личных сообщениях каждому. Не болейте',
    date: 4,
    user: {
      id: '1',
      avatar: Avatar,
      username: 'Дмитрий Андреев',
      role: 'Лектор'
    }
  }
];
