import DialogAvatar1 from 'assets/dialog/dialogAvatar-1.png';
import DialogAvatar2 from 'assets/dialog/dialogAvatar-2.png';
import DialogAvatar3 from 'assets/dialog/dialogAvatar-3.png';
import DialogAvatar4 from 'assets/dialog/dialogAvatar-4.png';
import DialogAvatar5 from 'assets/dialog/dialogAvatar-5.png';

import UserAvatar1 from 'assets/avatar-1.png';
import UserAvatar2 from 'assets/avatar-2.png';
import UserAvatar3 from 'assets/avatar-3.png';

import UserAvatar from 'assets/dialog/userAvatar.png';
import UserMyAvatar from 'assets/student.png';

export type Dialog = {
  id: string;
  avatar: string;
  title: string;
  participantsAvatars: Array<string>;
  allParticipants: number;
};
export type Message = {
  id: string;
  from_id: string;
  chat_id: string;
  avatar: string;
  text?: string;
};

export const dialogs: Array<Dialog> = [
  {
    id: '1',
    avatar: DialogAvatar1,
    title: 'Группа Д-234',
    participantsAvatars: [UserAvatar1, UserAvatar2, UserAvatar3],
    allParticipants: 20
  },
  {
    id: '2',
    avatar: DialogAvatar2,
    title: 'Илья, Мария, Даша',
    participantsAvatars: [UserAvatar1, UserAvatar2, UserAvatar3],
    allParticipants: 20
  },
  {
    id: '3',
    avatar: DialogAvatar3,
    title: 'Чат высший математики и кибернетики',
    participantsAvatars: [UserAvatar1, UserAvatar2, UserAvatar3],
    allParticipants: 20
  },
  {
    id: '4',
    avatar: DialogAvatar4,
    title: 'Группы Р-234',
    participantsAvatars: [UserAvatar1, UserAvatar2, UserAvatar3],
    allParticipants: 20
  },
  {
    id: '5',
    avatar: DialogAvatar5,
    title: 'Группы Ы-2122',
    participantsAvatars: [UserAvatar1, UserAvatar2, UserAvatar3],
    allParticipants: 20
  },
  {
    id: '6',
    avatar: DialogAvatar5,
    title: 'Группы Ы-2122',
    participantsAvatars: [UserAvatar1, UserAvatar2, UserAvatar3],
    allParticipants: 20
  }
];
export const messages: Array<Message> = [
  {
    id: '1',
    from_id: '5',
    chat_id: '2',
    avatar: UserAvatar,
    text: 'Итого, где будем праздновать др Маши? Предлагаю пойти в кафе на набережной, там вид красивый)'
  },
  {
    id: '2',
    from_id: '6',
    chat_id: '2',
    avatar: UserMyAvatar,
    text: 'Совсем забыл о Маше. Спасибо, что напомнила'
  }
];
