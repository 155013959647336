import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function ArrowIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        d="M9.26536 0.205112C8.96443 -0.0795129 8.48974 -0.0662918 8.20511 0.234642C7.92049 0.535577 7.93371 1.01027 8.23464 1.29489L13.4737 6.25H0.75C0.335786 6.25 0 6.58579 0 7C0 7.41421 0.335786 7.75 0.75 7.75H13.476L8.23464 12.7073C7.93371 12.9919 7.92049 13.4666 8.20511 13.7675C8.48974 14.0684 8.96443 14.0817 9.26536 13.797L15.6826 7.72759C15.8489 7.57031 15.9489 7.3702 15.9826 7.16141C15.994 7.10942 16 7.05541 16 7C16 6.94241 15.9935 6.88633 15.9812 6.83246C15.9462 6.62667 15.8467 6.42976 15.6826 6.27455L9.26536 0.205112Z"
        fill={color || '#605BFF'}
      />
    </svg>
  );
}
