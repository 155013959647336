type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function UserDataIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        d="M14.75 10.5H18.5"
        stroke={color || '#605BFF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 13.5H18.5"
        stroke={color || '#605BFF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13477 13.5C10.3774 13.5 11.3848 12.4926 11.3848 11.25C11.3848 10.0074 10.3774 9 9.13477 9C7.89212 9 6.88477 10.0074 6.88477 11.25C6.88477 12.4926 7.89212 13.5 9.13477 13.5Z"
        stroke={color || '#605BFF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.22949 15.75C6.39595 15.106 6.77164 14.5355 7.29753 14.1283C7.82342 13.721 8.46972 13.5 9.13487 13.5C9.80001 13.5 10.4463 13.7209 10.9722 14.1282C11.4982 14.5354 11.8739 15.1058 12.0404 15.7498"
        stroke={color || '#605BFF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.75 4.5H4.25C3.83579 4.5 3.5 4.83579 3.5 5.25V18.75C3.5 19.1642 3.83579 19.5 4.25 19.5H20.75C21.1642 19.5 21.5 19.1642 21.5 18.75V5.25C21.5 4.83579 21.1642 4.5 20.75 4.5Z"
        stroke={color || '#605BFF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
