import React from 'react';
import { useHistory } from 'react-router';
import { AuthCard } from '../../common/components/ui/AuthCard/AuthCard';
import { Box } from '../../common/components/ui/Box/Box';
import { Icon } from '../../common/components/ui/Icons/Icon';

import s from './Register.module.scss';

export default function Register() {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  const handleSubmit = (email: string, password: string) => {
    history.push('/sub-route');
  };

  return (
    <div className={s.Register__wrapper}>
      <div onClick={goBack} className={s.GoBack}>
        <div className={s.GoBack__icon__wrapper}>
          <Icon type="arrow" color="#605bff" className={s.GoBack__icon} />
        </div>
        <span className={s.GoBack__text}>Назад</span>
      </div>

      <Box>
        <AuthCard handleSubmit={handleSubmit} className={s.Register__card_wrapper} />
      </Box>

      <div className={s.Register__background}>
        <div className={s.Register__backgroundLeft} />
        <div className={s.Register__backgroundRight} />
      </div>
    </div>
  );
}
