import color from 'color';
import clsx from 'clsx';
import React from 'react';

import CoatOfArmsMoscowIcon from './assets/CoatsOfArms/CoatOfArmsMoscowIcon.png';
import CoatOfArmsPiterIcon from './assets/CoatsOfArms/CoatOfArmsPiterIcon.png';

import { EyeIcon } from './EyeIcon';
import { DiagramBarsIcon } from './DiagramBarsIcon';
import { CloseIcon } from './CloseIcon';
import { BookIcon2 } from './BookIcon2';
import { EllipseIcon } from './Shapes/EllipseIcon';
import { CloseIcon2 } from './CloseIcon2';
import { EyeIcon3 } from './EyeIcon3';
import { BrainIcon } from './BrainIcon';
import { CalendarIcon2 } from './CalendarIcon2';
import {
  AirplaneIcon,
  ArrowIcon,
  ArrowIcon2,
  ArrowIcon3,
  AwardIcon,
  BellIcon,
  BookIcon,
  BurgerMenuIcon,
  CalendarIcon,
  CheckIcon,
  CheckIcon2,
  CheckInRounIcon,
  ClipIcon,
  ClipIcon2,
  ClockIcon,
  CloudIcon,
  ConfederateIcon,
  ConnectIcon,
  CopyIcon,
  DiagramPieIcon,
  DialogIcon,
  DialogIcon2,
  DownloadIcon,
  EyeCloseIcon,
  EyeIcon2,
  FacebookIcon,
  FileIcon,
  FileInShapeIcon,
  FilterIcon,
  FolderIcon,
  FolderInShapeIcon,
  FontsInShapeIcon,
  HistoryIcon,
  HomeIcon,
  InfoInShapeIcon,
  ListIcon,
  MenuCircle,
  MenuIcon,
  MessageIcon,
  MonitorIcon,
  PencilIcon,
  PeopleInShapeIcon,
  PhoneIcon,
  PinIcon,
  PlusIcon,
  ScrollIcon,
  SearchIcon,
  SettingsIcon,
  SmileIcon,
  StarIcon,
  SuitcaseIcon,
  TwoArrowsIcon,
  TwoLinesIcon,
  UserDataIcon,
  UserInShapeIcon,
  VKIcon,
  WarningIcon,
  WriteIcon
} from './index';
import s from './Icon.module.scss';

type Props = {
  type: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
  style?: React.CSSProperties;
  fillColor?: string;
  backgroundColor?: string;
  width?: number;
  height?: number;
};

export function Icon({
  type,
  onClick,
  className,
  rounded,
  color: iconColor,
  style,
  fillColor,
  width,
  height,
  backgroundColor
}: Props) {
  const iconProps = { color: fillColor || iconColor, width, height };

  const showIcon = () => {
    switch (type) {
      case 'airplane':
        return <AirplaneIcon {...iconProps} />;
      case 'arrow':
        return <ArrowIcon {...iconProps} />;
      case 'arrow-2':
        return <ArrowIcon2 {...iconProps} />;
      case 'arrow-3':
        return <ArrowIcon3 {...iconProps} />;
      case 'award':
        return <AwardIcon {...iconProps} />;
      case 'bell':
        return <BellIcon {...iconProps} />;
      case 'burger-menu':
        return <BurgerMenuIcon {...iconProps} />;
      case 'book':
        return <BookIcon {...iconProps} />;
      case 'book-2':
        return <BookIcon2 {...iconProps} />;
      case 'brain':
        return <BrainIcon {...iconProps} />;
      case 'check':
        return <CheckIcon {...iconProps} />;
      case 'check-2':
        return <CheckIcon2 {...iconProps} />;
      case 'calendar':
        return <CalendarIcon {...iconProps} />;
      case 'calendar-2':
        return <CalendarIcon2 {...iconProps} />;
      case 'clip':
        return <ClipIcon {...iconProps} />;
      case 'check-in-roun':
        return <CheckInRounIcon {...iconProps} />;
      case 'clip-2':
        return <ClipIcon2 {...iconProps} />;
      case 'clock':
        return <ClockIcon {...iconProps} />;
      case 'close-2':
        return <CloseIcon2 {...iconProps} />;
      case 'close':
        return <CloseIcon {...iconProps} />;
      case 'cloud':
        return <CloudIcon {...iconProps} />;
      case 'confederate':
        return <ConfederateIcon {...iconProps} />;
      case 'copy':
        return <CopyIcon {...iconProps} />;
      case 'coat-of-arms-moscow':
        return <img src={CoatOfArmsMoscowIcon} />;
      case 'coat-of-arms-piter':
        return <img src={CoatOfArmsPiterIcon} />;
      case 'connect':
        return <ConnectIcon {...iconProps} />;
      case 'diagram-bars':
        return <DiagramBarsIcon {...iconProps} />;
      case 'diagram-pie':
        return <DiagramPieIcon {...iconProps} />;
      case 'download':
        return <DownloadIcon {...iconProps} />;
      case 'dialog':
        return <DialogIcon {...iconProps} />;
      case 'dialog-2':
        return <DialogIcon2 {...iconProps} />;
      case 'eye-close':
        return <EyeCloseIcon {...iconProps} />;
      case 'eye':
        return <EyeIcon {...iconProps} />;
      case 'eye-2':
        return <EyeIcon2 {...iconProps} />;
      case 'eye-3':
        return <EyeIcon3 {...iconProps} />;
      case 'ellipse':
        return <EllipseIcon {...iconProps} />;
      case 'facebook':
        return <FacebookIcon {...iconProps} />;
      case 'file':
        return <FileIcon {...iconProps} />;
      case 'file-in-shape':
        return <FileInShapeIcon {...iconProps} />;
      case 'filter':
        return <FilterIcon {...iconProps} />;
      case 'folder':
        return <FolderIcon {...iconProps} />;
      case 'folder-in-shape':
        return <FolderInShapeIcon {...iconProps} />;
      case 'history':
        return <HistoryIcon {...iconProps} />;
      case 'home':
        return <HomeIcon {...iconProps} />;
      case 'info-in-shape':
        return <InfoInShapeIcon {...iconProps} />;
      case 'list':
        return <ListIcon {...iconProps} />;
      case 'menu':
        return <MenuIcon {...iconProps} />;
      case 'monitor':
        return <MonitorIcon {...iconProps} />;
      case 'message':
        return <MessageIcon {...iconProps} />;
      case 'menu-circle':
        return <MenuCircle {...iconProps} />;
      case 'pencil':
        return <PencilIcon {...iconProps} />;
      case 'people-in-shape':
        return <PeopleInShapeIcon {...iconProps} />;
      case 'settings':
        return <SettingsIcon {...iconProps} />;
      case 'star':
        return <StarIcon {...iconProps} />;
      case 'two-arrows':
        return <TwoArrowsIcon {...iconProps} />;
      case 'user-data':
        return <UserDataIcon {...iconProps} />;
      case 'two-lines':
        return <TwoLinesIcon {...iconProps} />;
      case 'user-in-shape':
        return <UserInShapeIcon {...iconProps} />;
      case 'vk':
        return <VKIcon {...iconProps} />;
      case 'warning':
        return <WarningIcon {...iconProps} />;
      case 'write':
        return <WriteIcon {...iconProps} />;
      case 'fonts-in-shape':
        return <FontsInShapeIcon {...iconProps} />;
      case 'scroll':
        return <ScrollIcon {...iconProps} />;
      case 'suitcase':
        return <SuitcaseIcon {...iconProps} />;
      case 'phone':
        return <PhoneIcon {...iconProps} />;
      case 'pin':
        return <PinIcon {...iconProps} />;
      case 'plus':
        return <PlusIcon {...iconProps} />;
      case 'search':
        return <SearchIcon {...iconProps} />;
      case 'smile':
        return <SmileIcon {...iconProps} />;
      default:
        return;
    }
  };

  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded,
          [s.Icon_filled]: fillColor
        },
        className
      )}
      style={{ ...style, backgroundColor: fillColor ? color(fillColor).alpha(0.1).toString() : backgroundColor }}>
      {showIcon()}
    </div>
  );
}
