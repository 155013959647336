import React, { useState } from 'react';

import { ads } from 'routes/lmsAds/_temporaryAdsData';

import { LandingBox } from 'common/components/ui/Box/Box';
import { Breadcrumbs } from 'common/components/ui/Breadcrumbs/Breadcrumbs';
import { Title } from 'common/components/ui/Title/Title';
import { useToggle } from '@proscom/ui-react';
import { User } from 'routes/lmsAds/_temporaryUsersData';
import { LMSAdsItem } from './components/LMSAdsItem/LMSAdsItem';
import { LMSAdsModal } from './components/LMSAdsModal/LMSAdsModal';
import s from './LMSAdsPage.module.scss';

const breadcrumbsData = [
  { href: '/', title: 'Главная' },
  { href: '/lms-ads', title: 'Объявления' }
];

export const LMSAdsPage = () => {
  const [chosenUser, setChosenUser] = useState<User | null>(null);
  const showModal = useToggle();

  const onWrite = (user: User) => {
    setChosenUser(user);
    showModal.toggle();
  };

  const showAds = ads.map((ad) => <LMSAdsItem {...ad} key={ad.id} onWrite={() => onWrite(ad.user)} />);

  return (
    <LandingBox className={s.LMSAdsPage__wrapper}>
      <Breadcrumbs items={breadcrumbsData} />
      <Title title="Объявления" className={s.LMSAdsPage__title} />
      {chosenUser && <LMSAdsModal visible={showModal.value} onClose={showModal.toggle} user={chosenUser} />}
      <div className={s.LMSAdsPage__items}>{showAds}</div>
    </LandingBox>
  );
};
