import React from 'react';
import clsx from 'clsx';

import { AppColor } from 'store/app';
import { EventItem, EventLessonType, EventType } from 'routes/calendar/_temporaryEventsData';

import { Tag } from 'common/components/ui/Tags/Tags';
import { Icon } from 'common/components/ui/Icons/Icon';
import s from './ScheduleEvent.module.scss';

type Own = {
  active?: boolean;
  isFlex?: boolean;
  style?: React.CSSProperties;
  isMonth?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
};
export type ScheduleEventProps = Own & EventItem;
export const ScheduleEvent = React.forwardRef<HTMLDivElement, ScheduleEventProps>(
  ({ type, lessonType, cabinet, title, style, onClick, active, isFlex, className, isMonth }, ref) => {
    const COLOR_TAG_MAP = {
      [EventLessonType.Languages]: AppColor.Blue,
      [EventLessonType.PoliticalScience]: AppColor.Red,
      [EventLessonType.ArtHistory]: AppColor.Green,
      [EventLessonType.Economic]: AppColor.Pink,
      [EventType.Event]: AppColor.Purple,
      [EventLessonType.Right]: AppColor.Yellow,
      [EventLessonType.Philosophy]: AppColor.Blue
    };

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={clsx(
          s.ScheduleEvent__wrapper,
          {
            [s.CalendarEvent_active]: active,
            [s.CalendarEvent_flex]: isFlex
          },
          className
        )}
        style={style}>
        <div className={clsx(s.ScheduleEvent__status)} style={{ backgroundColor: COLOR_TAG_MAP[lessonType || type] }} />
        <div className={s.ScheduleEvent__content}>
          {!isMonth && (
            <div className={s.ScheduleEvent__header}>
              <Tag title={type} color={COLOR_TAG_MAP[lessonType || type]} className={s.ScheduleEvent__tag} />
              {!isFlex && <span className={s.ScheduleEvent__cabinet}>{cabinet}</span>}
            </div>
          )}
          <div className={s.ScheduleEvent__title}>
            {!lessonType && type === EventType.Event && <Icon type="warning" className={s.ScheduleEvent__titleIcon} />}
            {title}
          </div>
        </div>
      </div>
    );
  }
);
