import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icons/Icon';
import s from './Button.module.scss';

export enum ButtonSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}
export enum ButtonColor {
  Primary = '#605BFF',
  Success = '#24D957',
  Error = '#F43F5E'
}
export enum ButtonType {
  Default = 'default',
  Filter = 'filter',
  Outlined = 'outlined',
  Transparent = 'transparent'
}

export interface IButtonProps {
  children?: React.ReactNode;
  onClick?: any;
  secondary?: boolean;
  size?: ButtonSize;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  btnType?: ButtonType;
  iconStartType?: string;
  iconEndType?: string;
  rounded?: boolean;
  iconStyle?: string;
  active?: boolean;
  iconColor?: string;
  color?: ButtonColor;
  style?: React.CSSProperties;
  helpText?: string;
  classNameContainer?: string;
}

export interface IButtonLinkProps {
  children: string;
  to: string;
  secondary?: boolean;
  small?: boolean;
  size?: ButtonSize;
  className?: string;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  rounded?: boolean;
}
type ButtonBaseProps = {
  btnType?: ButtonType;
  secondary?: boolean;
  size?: ButtonSize;
  rounded?: boolean;
  active?: boolean;
  className?: string;
};
const classes = ({ btnType, secondary, size, rounded, active, className }: ButtonBaseProps) => {
  return clsx(
    s.Button,
    s[`Button_${btnType}`],
    {
      [s.Button_secondary]: secondary,
      [s.Button_sm]: size === ButtonSize.Small,
      [s.Button_md]: size === ButtonSize.Medium,
      [s.Button_lg]: size === ButtonSize.Large,
      [s.Button_rounded]: rounded,
      [s.Button_filter_active]: btnType === ButtonType.Filter && active
    },
    className
  );
};

export function Button({
  iconStartType,
  iconEndType,
  iconColor,
  color,
  children,
  onClick,
  secondary,
  size = ButtonSize.Medium,
  className,
  type,
  btnType = ButtonType.Default,
  rounded,
  style,
  active,
  iconStyle,
  classNameContainer,
  helpText
}: IButtonProps) {
  const [hover, setHover] = useState(false);
  const baseIconProps = { color: iconColor, className: iconStyle };

  return (
    <button
      onClick={onClick}
      type={type}
      className={classes({
        btnType,
        secondary,
        size,
        rounded,
        active,
        className
      })}
      style={{ ...style, background: color }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      {hover && helpText && <div className={s.Button__textHelp}>{helpText}</div>}
      {iconStartType && <Icon type={iconStartType} {...baseIconProps} />}
      {children && (
        <span style={{ marginLeft: iconStartType ? '8px' : 0, marginRight: iconEndType ? '8px' : 0 }}>{children}</span>
      )}
      {iconEndType && <Icon type={iconEndType} {...baseIconProps} />}
    </button>
  );
}

export function ButtonLink({
  iconStart,
  children,
  iconEnd,
  to,
  secondary,
  small,
  btnType,
  rounded,
  active,
  size,
  className
}: IButtonLinkProps & ButtonBaseProps) {
  return (
    <Link to={to} className={classes({ secondary, size, btnType, rounded, active, className })}>
      {iconStart}
      {children}
      {iconEnd}
    </Link>
  );
}
