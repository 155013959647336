import React, { useState } from 'react';

import { ReactComponent as Logo } from 'assets/logoManager.svg';


import { Footer } from '../../../common/components/ui/Footer/Footer';
import { MenuLeft } from '../../../common/components/ui/MenuLeft/MenuLeft';
import { TopBar } from '../../../common/components/ui/TopBar/TopBar';
import { SearchInput } from '../../../common/components/ui/Forms/SearchInput/SearchInput';
import { Icon } from '../../../common/components/ui/Icons/Icon';
import { MainLayoutPageTypeEnum } from '../MainLayout';
import s from './ManagerLayout.module.scss';

export function withManagerLayout<Props>(Comp: React.ComponentType<Props>) {
  const topBarSloLeft = () => <div className={s.ManagerLayout__topBar__left}>Demo2/ (1C: Предприятие)</div>;
  const activeItem = 'Менеджер';

  return function (props: Props) {
    return (
      <div className={s.ManagerLayout}>
        <TopBar
          logoLarge={<Logo />}
          logoSmall={<Logo />}
          slotLeft={topBarSloLeft()}
          slotRight={<ManagerTopBarSlotRight />}
          className={s.ManagerLayout__topBar}
          classNameContent={s.ManagerLayout__topBar_content}
        />
        <MenuLeft pageType={MainLayoutPageTypeEnum.Manager} activeItem={activeItem} />
        <div className={s.ManagerLayout__content}>
          <Comp {...props} />
        </div>
        <Footer />
      </div>
    );
  };
}

const ManagerTopBarSlotRight = () => {
  const [searchVal, setSearchVal] = useState('');

  const handleChangeSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <div className={s.ManagerLayout__topBar__right}>
      <SearchInput onChange={handleChangeSearchVal} value={searchVal} className={s.ManagerLayout__search} />
      <div className={s.ManagerLayout__topBar__icons}>
        <Icon type="bell" className={s.ManagerLayout__topBar__icon} />
        <div className={s.ManagerLayout__topBar__line} />
        <Icon type="history" className={s.ManagerLayout__topBar__icon} />
        <div className={s.ManagerLayout__topBar__line} />
        <Icon type="star" className={s.ManagerLayout__topBar__icon} />
      </div>
      <div className={s.ManagerLayout__userDropdown_wrapper}>
        <div className={s.ManagerLayout__userDropdown}>
          <span className={s.ManagerLayout__userDropdown_username}>Пушкин Александр Сергеевич</span>
          <Icon type="burger-menu" />
        </div>
        <div className={s.ManagerLayout__topBar__userDropdown__content}></div>
      </div>
    </div>
  );
};
