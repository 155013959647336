import clsx from 'clsx';
import { usePreventBodyScroll } from 'common/hooks/usePreventBodyScroll';
import { Icon } from '../Icons/Icon';

import s from './PlayerModal.module.scss';

export type PlayerModalProps = {
  url: string | null;
  onClose: () => void;
};
export const PlayerModal: React.FC<PlayerModalProps> = ({ url, onClose }) => {
  usePreventBodyScroll(url !== null);

  return (
    <div className={clsx(s.PlayerModal__wrapper, { [s.PlayerModal__wrapper_active]: url })}>
      <div className={s.PlayerModal__frame_wrapper}>
        <iframe
          src={`https://www.youtube.com/embed/${url}/QH2-TGUlwu4`}
          width="560"
          height="315"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={s.PlayerModal__frame}
        />
        <Icon onClick={onClose} type="close" className={s.PlayerModal__close} height={24} />
      </div>
      <div className={s.PlayerModal__background} />
    </div>
  );
};
