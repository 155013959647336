import clsx from 'clsx';
import React from 'react';
import { Icon } from '../Icons/Icon';

import s from './Notification.module.scss';

export type NotificationProps = {
  children: React.ReactNode;
  visible: boolean;
  onToggle: () => void;
  className?: string;
};
export const Notification: React.FC<NotificationProps> = ({ children, visible, onToggle, className }) => {
  console.log('kekeee', visible);

  return (
    <div className={clsx(s.Notification, { [s.Notification_show]: visible }, className)}>
      <div className={s.Notification__header}>
        <Icon onClick={onToggle} type="close" color="#9FA9C1" className={s.Notification__close} />
      </div>
      {children}
    </div>
  );
};
