export enum CourseThemeTypeEnum {
  Hard = 'Сложные'
}

export type CourseTheme = {
  id: string;
  title: string;
  items?: Array<CourseItem>;
};
export type CourseItem = {
  id: string;
  title: string;
  date: number;
  type: CourseThemeTypeEnum;
  notes: number;
  items?: Array<{
    id: string;
    video: string;
    title: string;
    description: string;
  }>;
};
