import { Enrollment4Banner } from './Enrollment4Banner';
import { Enrollment4Documents, Enrollment4DocumentsData } from './Enrollment4Documents';
import s from './Enrollment4ContractAndOtherDocumentsForTraining.module.scss';

export const Enrollment4ContractAndOtherDocumentsForTraining = () => {
  return (
    <div>
      <Enrollment4Banner
        text="Оплата первого взноса за обучение подтверждена! Ожидайте приказа на зачисление 22.08.2021"
        className={s.Enrollment4ContractAndOtherDocumentsForTraining__banner}
      />
      <div className={s.Enrollment4ContractAndOtherDocumentsForTraining__title}>
        Ознакомьтесь со всеми необходимыми документами
      </div>
      <Enrollment4Documents items={Enrollment4DocumentsData} />
    </div>
  );
};
