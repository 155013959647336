import { AppColor } from 'store/app';

import { Notification } from 'common/components/ui/Notification/Notification';
import { Icon } from 'common/components/ui/Icons/Icon';
import { Button, ButtonSize, ButtonType } from 'common/components/ui/Button/Button';
import { useHistory } from 'react-router';
import s from './EventNotification.module.scss';

type Props = {
  date?: string;
  lessonType: string;
  title?: string;
  lecturer: string;
  cabinet: number;
  visible: boolean;
  toggle: () => void;
  onCancel: () => void;
};
export const EventNotification2: React.FC<Props> = ({
  date,
  lessonType,
  title,
  lecturer,
  cabinet,
  visible,
  toggle,
  onCancel
}) => {
  const history = useHistory();

  const onConfirm = () => {
    history.push('/lesson/1');
  };

  return (
    <Notification visible={visible} onToggle={toggle} className={s.EventNotification__wrapper}>
      <div className={s.EventNotification__date}>{date}</div>
      <div className={s.EventNotification__contentWrapper}>
        <Icon type="calendar" fillColor={AppColor.Red} className={s.EventNotification__icon} />
        <div>
          <div className={s.EventNotification__title}>Вы на занятии «Физика. Повторение пройденного» ?</div>
          <div className={s.EventNotification__info}>
            <div className={s.EventNotification__listWrapper}>
              Лектор: <span className={s.EventNotification__list}>{lecturer}</span>
            </div>
            <div className={s.EventNotification__listWrapper}>
              Кабинет: <span className={s.EventNotification__list}>{cabinet}</span>
            </div>
          </div>
          <div className={s.EventNotification__footer}>
            <Button
              onClick={onConfirm}
              btnType={ButtonType.Outlined}
              className={s.EventNotification__footer_button}
              size={ButtonSize.Small}>
              Да
            </Button>
            <Button
              onClick={onCancel}
              btnType={ButtonType.Outlined}
              className={s.EventNotification__footer_button}
              size={ButtonSize.Small}>
              Нет
            </Button>
          </div>
        </div>
      </div>
    </Notification>
  );
};
