import React from 'react';
import clsx from 'clsx';

import s from './Card.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  shadow?: boolean;
  border?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (event: { clientX: number; clientY: number }) => void;
  ref?: React.MutableRefObject<null>;
};
export const Card: React.FC<Props> = ({ children, className, shadow, border, onClick, onMouseDown, ref }) => {
  return (
    <div
      ref={ref}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={clsx(
        s.Card,
        {
          [s.Card__shadow]: shadow,
          [s.Card__border]: border
        },
        className
      )}>
      {children}
    </div>
  );
};
