import moment from 'moment';

import { Ad } from 'routes/lmsAds/_temporaryAdsData';

import { Button, ButtonType } from 'common/components/ui/Button/Button';
import { LMSAdsUser } from '../LMSAdsUser/LMSAdsUser';
import s from './LMSAdsItem.module.scss';

export type LMSAdsItemProps = {
  onWrite: () => void;
};
export const LMSAdsItem: React.FC<LMSAdsItemProps & Ad> = ({ onWrite, title, description, date, user }) => {
  return (
    <div className={s.LMSAdsItem__wrapper}>
      <div>
        <div className={s.LMSAdsItem__title}>{title}</div>
        <div className={s.LMSAdsItem__description}>{description}</div>
        <div className={s.LMSAdsItem__date}>{moment(date).format('DD.MM.YYYY')}</div>
      </div>
      <div />
      <LMSAdsUser {...user} />
      <div />
      <Button onClick={onWrite} btnType={ButtonType.Outlined} className={s.LMSAdsItem__button}>
        Написать
      </Button>
    </div>
  );
};
