import clsx from 'clsx';
import React from 'react';
import { Card } from '../../../common/components/ui/Card/Card';
import { Icon } from '../../../common/components/ui/Icons/Icon';

import s from './LevelOfPreparation.module.scss';

const items = [
  { icon: 'book-2', title: 'Школа' },
  { icon: 'award', title: 'Лицей' },
  { icon: 'scroll', title: 'Бакалавриат' },
  { icon: 'confederate', title: 'Магистратура' },
  { icon: 'suitcase', title: 'Дополнительное и бизнес-образование' },
  { icon: 'monitor', title: 'Онлайн-курсы' }
];
type Props = {
  setChosenProgress: (number: number) => void;
};
export const LevelOfPreparation: React.FC<Props> = ({ setChosenProgress }) => {
  const changeChosenProgress = () => {
    setChosenProgress(1);
  };

  const showItems = items.map((item) => (
    <Card onClick={changeChosenProgress} className={s.LevelOfPreparation__card} key={item.title}>
      <div className={s.LevelOfPreparation__icon_wrapper}>
        <Icon type={item.icon} rounded color="#5C617C" className={s.LevelOfPreparation__icon} />
      </div>

      <span className={s.LevelOfPreparation__text}>{item.title}</span>
    </Card>
  ));

  return <div className={s.LevelOfPreparation__wrapper}>{showItems}</div>;
};
