import clsx from 'clsx';
import React from 'react';
import s from './LandingPoints.module.scss';

export interface ILandingPointsProps {
  points: [string, string[]][];
  picture: string;
  className?: string;
}

export function LandingPoints({ points, picture, className }: ILandingPointsProps) {
  return (
    <article className={clsx(s.LandingPoints, className)}>
      <img className={s.LandingPoints__picture} src={picture} alt={''} />
      <div className={s.LandingPoints__sections}>
        {points.map(([title, points]) => (
          <section key={title} className={s.LandingPoints__section}>
            <h3 className={s.LandingPoints__title}>{title}</h3>
            {points.map((point) => (
              <p key={point} className={s.LandingPoints__point}>
                <i className={'ci-check'} />
                {point}
              </p>
            ))}
          </section>
        ))}
      </div>
    </article>
  );
}
