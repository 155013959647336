import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';
import { services } from '../services/_temporaryServicesData';
import { LandingBox } from '../../common/components/ui/Box/Box';
import { LandingHero } from '../../common/components/ui/LandingHero/LandingHero';
import { LandingApplication } from '../../common/components/ui/LandingApplication/LandingApplication';
import { LandingPoints } from '../../common/components/ui/LandingPoints/LandingPoints';
import picture from './picture.png';
import pointsPicture from './points.png';
import s from './ServicePage.module.scss';

export interface IServicePageProps {
  match: { params: { service: string } };
}

export default function ServicePage({ match }: IServicePageProps) {
  const service = services.find((service) => service.id === match.params.service);
  if (!service) return <Redirect to={'/'} />;
  return (
    <React.Fragment>
      <Helmet title={service.title} />

      <LandingHero
        className={s.ServicePage__hero}
        title={service.title}
        text={service.description}
        picture={service.heroImage || picture}
        // scroll to the application form
        action={() => document.getElementById('application')?.scrollIntoView({ behavior: 'smooth' })}
        pictureHeight={490}
      />

      {service.points && (
        <LandingBox>
          <LandingPoints
            className={s.ServicePage__points}
            points={service.points}
            picture={service.pointsImage || pointsPicture}
          />
        </LandingBox>
      )}

      <LandingApplication background={'#F5F5F7'} />
    </React.Fragment>
  );
}
