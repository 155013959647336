type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function FileIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        d="M14.0001 2H6.00006C5.46963 2 4.96092 2.21071 4.58585 2.58579C4.21077 2.96086 4.00006 3.46957 4.00006 4V20C4.00006 20.5304 4.21077 21.0391 4.58585 21.4142C4.96092 21.7893 5.46963 22 6.00006 22H18.0001C18.5305 22 19.0392 21.7893 19.4143 21.4142C19.7893 21.0391 20.0001 20.5304 20.0001 20V8L14.0001 2Z"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9999 2V8H19.9999"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 13H7.99994"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 17H7.99994"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99994 9H8.99994H7.99994"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
