import React, { useState } from 'react';
import clsx from 'clsx';

import { Button, ButtonType } from '../Button/Button';
import { Icon } from '../Icons/Icon';
import s from './Filter.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  isFilterButton?: boolean;
};
export const Filter: React.FC<Props> = ({ children, className, isFilterButton = true }) => {
  const [isFilter, setIsFilter] = useState(false);

  const onToggleFilter = () => {
    setIsFilter(!isFilter);
  };
  const onClearForm = () => {
    onToggleFilter();
  };
  const onSubmit = () => {
    onToggleFilter();
  };

  return (
    <div className={clsx(s.Filter__wrapper, className)}>
      {isFilterButton && (
        <Button
          onClick={onToggleFilter}
          className={clsx('d-desktop-hide', s.Fitlter__button, { [s.Fitlter__button_active]: isFilter })}
          btnType={ButtonType.Filter}
          iconStartType="filter"
          iconColor={isFilter ? '#605bff' : '#222845'}>
          Фильтр
        </Button>
      )}
      <div
        className={clsx(s.Filter, {
          [s.Filter_active]: !isFilterButton || isFilter,
          [s.Filter_shadow]: isFilterButton
        })}>
        {children}
        {isFilterButton && (
          <div className={clsx(s.Fitlter__buttonActions, 'd-desktop-hide')}>
            <Button
              onClick={onClearForm}
              className={clsx(s.Fitlter__buttonAction, s.Fitlter__buttonAction_disabled)}
              btnType={ButtonType.Transparent}>
              Очистить
            </Button>
            <Button
              onClick={onSubmit}
              className={clsx(s.Fitlter__buttonAction, s.Fitlter__buttonAction_active)}
              btnType={ButtonType.Transparent}>
              Применить
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
