import React from 'react';
import { Link } from 'react-router-dom';
import s from './Breadcrumbs.module.scss';

type Props = {
  items: Array<{ href: string; title: string }>;
};
export const Breadcrumbs: React.FC<Props> = ({ items }) => {
  const showItems = items.map((item, index) => (
    <Link to={item.href} className={s.Breadcrumbs__item} key={item.title}>
      {item.title}
      <span className={s.Breadcrumbs__slash}>{index !== items.length - 1 && '/'}</span>
    </Link>
  ));

  return <div className={s.Breadcrumbs__list}>{showItems}</div>;
};
