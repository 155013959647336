import clsx from 'clsx';
import s from './Enrollment4Banner.module.scss';

type Props = {
  text: string;
  className?: string;
};
export const Enrollment4Banner: React.FC<Props> = ({ text, className }) => {
  return <div className={clsx(s.Enrollment4Banner, className)}>{text}</div>;
};
