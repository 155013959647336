import { useState } from 'react';

import { FilingAnApplication1 } from './FilingAnApplication1';
import { FilingAnApplication2 } from './FilingAnApplication2';

type Props = {
  setChosenProgress: (number: number) => void;
};
export const FilingAnApplication: React.FC<Props> = ({ setChosenProgress }) => {
  const [chosenStep, setChosenStep] = useState('second');

  return chosenStep === 'first' ? (
    <FilingAnApplication1 setChosenStep={setChosenStep} />
  ) : chosenStep === 'second' ? (
    <FilingAnApplication2 setChosenProgress={setChosenProgress} />
  ) : (
    <></>
  );
};
