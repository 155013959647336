import clsx from 'clsx';

import { AppColor } from 'store/app';


import { Notification } from 'common/components/ui/Notification/Notification';
import { Icon } from 'common/components/ui/Icons/Icon';
import { Button, ButtonSize, ButtonType } from 'common/components/ui/Button/Button';
import s2 from './EventNotification3.module.scss';
import s from './EventNotification.module.scss';

type Props = {
  date?: string;
  lecturer: string;
  cabinet: number;
  visible: boolean;
  toggle: () => void;
  isInLesson: boolean;
};
export const EventNotification3: React.FC<Props> = ({ date, lecturer, cabinet, visible, toggle, isInLesson }) => {
  return (
    <Notification visible={visible} onToggle={toggle} className={s.EventNotification__wrapper}>
      <div className={s.EventNotification__date}>{date}</div>
      <div className={s.EventNotification__contentWrapper}>
        <Icon type="calendar" fillColor={AppColor.Red} className={s.EventNotification__icon} />
        <div>
          <div
            className={clsx(s.EventNotification__title, s.EventNotification__title)}
            style={{ color: isInLesson ? AppColor.Green : AppColor.Red }}>
            {isInLesson ? 'Вы есть на занятии' : 'Вас нет на занятии'}
          </div>
          <div className={s.EventNotification__desc}>
            {isInLesson
              ? 'Вы отмечены присутствующим на занятие «Физика. Повторение пройденного»'
              : 'Вы отмечены отсутствующим на занятие «Физика. Повторение пройденного»'}
          </div>
          <div className={s.EventNotification__info}>
            <div className={s.EventNotification__listWrapper}>
              Лектор: <span className={s.EventNotification__list}>{lecturer}</span>
            </div>
            <div className={s.EventNotification__listWrapper}>
              Кабинет: <span className={s.EventNotification__list}>{cabinet}</span>
            </div>
          </div>
          <div className={s.EventNotification__footer}>
            <Button
              btnType={ButtonType.Outlined}
              className={clsx(s.EventNotification__footer_button, s2.EventNotification__footer_button)}
              size={ButtonSize.Small}>
              Написать преподавателю
            </Button>
          </div>
        </div>
      </div>
    </Notification>
  );
};
