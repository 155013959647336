import { dialogs, messages } from 'routes/lmsMessages/_temporaryDialogData';

import { LMSChat } from './components/LMSChat';
import { LMSDialogs } from './components/LMSDialogs';
import s from './LMSMessagesPage.module.scss';

export const LMSMessagesPage = () => {
  return (
    <div className={s.LMSMessagesPage__wrapper}>
      <LMSDialogs items={dialogs} />
      <LMSChat messages={messages} myId={'6'} />
    </div>
  );
};
