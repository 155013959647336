import clsx from 'clsx';

import { Card } from '../../../../../common/components/ui/Card/Card';
import { Field, FieldTitle } from '../../../../../common/components/ui/Forms/Field/Field';
import { Icon } from '../../../../../common/components/ui/Icons/Icon';
import { Tag } from '../../../../../common/components/ui/Tags/Tags';
import s from './TestTask2Dropdown.module.scss';

type Props = {
  title: string;
  items: Array<{ title: string; number: number }>;
  onToggle: () => void;
  active?: boolean;
  status: string;
  allResults: number;
  className?: string;
};
export const TestTask2Dropdown: React.FC<Props> = ({
  title,
  items,
  onToggle,
  active,
  status,
  allResults,
  className
}) => {
  const showItems = items.map((item) => (
    <Field>
      <FieldTitle title={item.title} className={s.TestTask2Dropdown__result_title} />
      <div className={s.TestTask2Dropdown__result_num}>{item.number}</div>
    </Field>
  ));

  return (
    <div className={clsx(s.TestTask2Dropdown__dropdown, className)}>
      <div className={s.TestTask2Dropdown__dropdown_header} onClick={onToggle}>
        <span className={s.TestTask2Dropdown__dropdown_title}>{title}</span>
        <div className={s.TestTask2Dropdown__dropdown_actions}>
          <Tag title={status} color="#53C120" className={s.TestTask2Dropdown__dropdown_tag} />
          <Icon
            type="arrow-2"
            className={clsx(s.TestTask2Dropdown__dropdown_arrow, {
              [s.TestTask2Dropdown__dropdown_arrow_active]: active
            })}
          />
        </div>
      </div>
      <Card
        className={clsx(s.TestTask2Dropdown__dropdown_content, {
          [s.TestTask2Dropdown__dropdown_content_show]: active
        })}>
        <div className={s.TestTask2Dropdown__results_col}>{showItems}</div>
        <div>
          <Field>
            <FieldTitle title="Общий балл" className={s.TestTask2Dropdown__result_title} />
            <div className={s.TestTask2Dropdown__result_num}>{allResults}</div>
          </Field>
        </div>
      </Card>
    </div>
  );
};
