import clsx from 'clsx';
import { useState } from 'react';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Button, ButtonType } from '../../../../../common/components/ui/Button/Button';

import { Checkbox } from '../../../../../common/components/ui/Forms/Checkbox/Checkbox';
import { Icon } from '../../../../../common/components/ui/Icons/Icon';
import { Select } from '../../../../../common/components/ui/Forms/Select/Select';
import s from './Filter.module.scss';

const programsData = [
  { value: 'all', label: 'Все' },
  { value: 'С бюджетными местами', label: 'С бюджетными местами' }
];
const languagesData = [
  { value: 'ru', label: 'ru' },
  { value: 'en', label: 'en' }
];
const EGEData = [
  { value: 'Русский', label: 'Русский' },
  { value: 'Математика', label: 'Математика' }
];
export const Filter = () => {
  const [isFilter, setIsFilter] = useState(false);
  const [formsOfEducation, setFormsOfEducation] = useState([
    { label: 'очная', value: 'full-time', checked: false },
    { label: 'очно-заочная', value: 'part-time', checked: false },
    { label: 'заочная', value: 'correspondence', checked: false }
  ]);
  const [chosenPrograms, setChosenPrograms] = useState({
    value: 'С бюджетными местами',
    label: 'С бюджетными местами'
  });
  const [chosenLanguage, setChosenLanguage] = useState({ value: 'ru', label: 'ru' });
  const [chosenEGE, setChosenEGE] = useState({ value: 'Русский', label: 'Русский' });
  const [isPrice, setIsPrice] = useState(false);

  const onToggleFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleChangeFormsOfEducation = (e) => {
    setFormsOfEducation(
      formsOfEducation.map((item) => (item.value === e.target.value ? { ...item, checked: e.target.checked } : item))
    );
  };
  const handleChangeProgram = (e) => {
    setChosenPrograms(e);
  };
  const handleChangeLanguage = (e) => {
    setChosenLanguage(e);
  };
  const handleChangeEGE = (e) => {
    setChosenEGE(e);
  };
  const onTooglePrice = () => {
    setIsPrice(!isPrice);
  };

  const onClearForm = () => {
    onToggleFilter();
  };
  const onSubmit = () => {
    onToggleFilter();
  };

  const showFormsOfEducation = formsOfEducation.map((item) => (
    <Checkbox
      onChange={handleChangeFormsOfEducation}
      name="form-of-education"
      {...item}
      key={item.label}
      className={s.Filter__checkboxItem}
    />
  ));

  const basicSelectProps = { className: s.Filter__select, size: FieldSize.Small };

  return (
    <div className={s.Filter__wrapper}>
      <Button
        onClick={onToggleFilter}
        className={clsx('d-desktop-hide', s.Fitlter__button, { [s.Fitlter__button_active]: isFilter })}
        btnType={ButtonType.Filter}
        iconStartType="filter"
        iconColor={isFilter ? '#605bff' : '#222845'}>
        Фильтр
      </Button>
      <div className={clsx(s.Filter, { [s.Filter_active]: isFilter })}>
        <div className={s.Filter__item}>
          <div className={s.Filter__title}>Форма обучения</div>
          <div className={s.Filter__checkboxes}>{showFormsOfEducation}</div>
        </div>
        <div className={s.Filter__item}>
          <div className={s.Filter__title}>Показать программы</div>
          <Select
            onChange={handleChangeProgram}
            name="program"
            value={chosenPrograms}
            options={programsData}
            {...basicSelectProps}
          />
        </div>
        <div className={s.Filter__item}>
          <div className={s.Filter__title}>Язык обучения</div>
          <Select
            onChange={handleChangeLanguage}
            name="language"
            value={chosenLanguage}
            options={languagesData}
            {...basicSelectProps}
          />
        </div>
        <div className={s.Filter__item}>
          <div className={s.Filter__title}>ЕГЭ</div>
          <Select onChange={handleChangeEGE} name="ege" value={chosenEGE} options={EGEData} {...basicSelectProps} />
        </div>
        <div className={s.Filter__item}>
          <div className={s.Filter__title}>Стоимость обучения</div>
          <Checkbox onChange={onTooglePrice} name="form-of-education" checked={isPrice} label="Показать стоимость" />
        </div>
        <div className={clsx(s.Fitlter__buttonActions, 'd-desktop-hide')}>
          <Button
            onClick={onClearForm}
            className={clsx(s.Fitlter__buttonAction, s.Fitlter__buttonAction_disabled)}
            btnType={ButtonType.Transparent}>
            Очистить
          </Button>
          <Button
            onClick={onSubmit}
            className={clsx(s.Fitlter__buttonAction, s.Fitlter__buttonAction_active)}
            btnType={ButtonType.Transparent}>
            Применить
          </Button>
        </div>
      </div>
    </div>
  );
};
