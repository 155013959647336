import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function CloseIcon2({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.40994 4.99994L8.70994 2.70994C8.89824 2.52164 9.00403 2.26624 9.00403 1.99994C9.00403 1.73364 8.89824 1.47825 8.70994 1.28994C8.52164 1.10164 8.26624 0.99585 7.99994 0.99585C7.73364 0.99585 7.47824 1.10164 7.28994 1.28994L4.99994 3.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L3.58994 4.99994L1.28994 7.28994C1.19621 7.3829 1.12182 7.49351 1.07105 7.61536C1.02028 7.73722 0.994141 7.86793 0.994141 7.99994C0.994141 8.13195 1.02028 8.26266 1.07105 8.38452C1.12182 8.50638 1.19621 8.61698 1.28994 8.70994C1.3829 8.80367 1.4935 8.87806 1.61536 8.92883C1.73722 8.9796 1.86793 9.00574 1.99994 9.00574C2.13195 9.00574 2.26266 8.9796 2.38452 8.92883C2.50638 8.87806 2.61698 8.80367 2.70994 8.70994L4.99994 6.40994L7.28994 8.70994C7.3829 8.80367 7.4935 8.87806 7.61536 8.92883C7.73722 8.9796 7.86793 9.00574 7.99994 9.00574C8.13195 9.00574 8.26266 8.9796 8.38452 8.92883C8.50637 8.87806 8.61698 8.80367 8.70994 8.70994C8.80367 8.61698 8.87806 8.50638 8.92883 8.38452C8.9796 8.26266 9.00574 8.13195 9.00574 7.99994C9.00574 7.86793 8.9796 7.73722 8.92883 7.61536C8.87806 7.49351 8.80367 7.3829 8.70994 7.28994L6.40994 4.99994Z"
          fill={color || 'white'}
        />
      </svg>
    </div>
  );
}
