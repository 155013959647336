import React, { useState } from 'react';

import { course } from 'routes/lmsHome/_temporaryCoursesData';

import { LandingBox } from 'common/components/ui/Box/Box';
import { LMSLessonHeader } from './components/LMSLessonHeader/LMSLessonHeader';
import { LMSLessonItem } from './components/LMSLessonItem/LMSLessonItem';
import s from './LMSLessonPage.module.scss';

export const LMSLessonPage = () => {
  const [chosenTheme, setChosenTheme] = useState<string | null>(course.themes[0].id);

  const onChoseTheme = (themeId: string) => {
    if (themeId !== chosenTheme) {
      setChosenTheme(themeId);
    } else {
      setChosenTheme(null);
    }
  };

  const showThemes = course.themes.map((theme) => (
    <LMSLessonItem
      key={theme.id}
      {...theme}
      active={chosenTheme === theme.id}
      onActive={() => onChoseTheme(theme.id)}
    />
  ));

  return (
    <LandingBox className={s.LMSLessonPage__wrapper}>
      <LMSLessonHeader
        title={course.title}
        description={course.description}
        address={course.address}
        date={course.date}
        author={course.author}
        allParticipants={course.allParticipants}
        participantsAvatars={course.participantsAvatars}
      />
      <div className={s.LMSLessonPage__themes}>{showThemes}</div>
    </LandingBox>
  );
};
