import { useFormik } from 'formik';

import { Button } from 'common/components/ui/Button/Button';
import { Enrollment1Info } from './Enrollment1Info';
import { Enrollment1Info2 } from './Enrollment1Info2';
import s from './Enrollment1.module.scss';

const conditions_of_admissions = [{ value: 'to_the_main_competition_place', label: 'На основное конкурсного места' }];
type Props = {
  setChosenItem: (number: number) => void;
};
export const Enrollment1: React.FC<Props> = ({ setChosenItem }) => {
  const initialValues = { budget: false, commerce: false, conditions_of_admission: { value: 'all', label: 'Все' } };
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      setChosenItem(1);
    }
  });

  return (
    <div className={s.Enrollment1}>
      <div className={s.Enrollment1__title}>Согласие на зачисление</div>
      <Enrollment1Info
        budget={formik.values.budget}
        commerce={formik.values.commerce}
        setFieldValue={formik.setFieldValue}
      />
      <Enrollment1Info2
        conditions_of_admission={formik.values.conditions_of_admission}
        conditions_of_admissions={conditions_of_admissions}
        setFieldValue={formik.setFieldValue}
      />
      <div className={s.Enrollment1__button_wrapper}>
        <Button onClick={formik.handleSubmit}>Отправить согласие</Button>
      </div>
    </div>
  );
};
