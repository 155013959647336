import { useFormik } from 'formik';

import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Field, FieldTitle, FormsGroup } from 'common/components/ui/Forms/Field/Field';
import { Input } from 'common/components/ui/Forms/Input/Input';
import s from './ApplicationsOfApplicantsConsent.module.scss';

export const ApplicationsOfApplicantsConsent = () => {
  const basicInputProps = { size: FieldSize.Small as FieldSize };
  const basicFieldProps = { flex: true, className: s.ApplicationsOfApplicantsConsent__field };

  const initialValues = {
    citizenship: 'РФ',
    place_of_birth: 'город Волжский, Волгоградская область',
    type_of_document: 'Паспорт гражданина РФ',
    passport_series: '30 17',
    passport_number: '576895',
    date_of_passport_receipt: '16.09.2017',
    passport_issued_by: 'Отделом УФМС России по Камчатскому краю в гор. Петропавловске-Камч...',
    department_code: '410-001',
    status_for_data_processing: 'Одобрено'
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {}
  });

  return (
    <div>
      <Field {...basicFieldProps}>
        <FieldTitle title="Гражданство" flex />
        <Input {...basicInputProps} value={formik.values.citizenship} isCopy />
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Место рождения" flex />
        <Input {...basicInputProps} value={formik.values.place_of_birth} />
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Вид документа" flex />
        <Input {...basicInputProps} value={formik.values.type_of_document} isCopy />
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Серия и номер паспорта" flex />
        <FormsGroup items={2}>
          <Input {...basicInputProps} value={formik.values.passport_series} />
          <Input {...basicInputProps} value={formik.values.passport_number} />
        </FormsGroup>
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Дата получения паспорта" flex />
        <FormsGroup items={2}>
          <Input {...basicInputProps} value={formik.values.date_of_passport_receipt} isCopy />
        </FormsGroup>
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Кем выдан паспорт" flex />
        <Input {...basicInputProps} value={formik.values.passport_issued_by} />
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Код подразделения" flex />
        <FormsGroup items={2}>
          <Input {...basicInputProps} value={formik.values.department_code} />
        </FormsGroup>
      </Field>
      <Field {...basicFieldProps}>
        <FieldTitle title="Статус группа согласие на обработку данных" flex />
        <FormsGroup items={2}>
          <Input {...basicInputProps} value={formik.values.status_for_data_processing} isCopy />
        </FormsGroup>
      </Field>
    </div>
  );
};
