import clsx from 'clsx';
import { Button, ButtonType } from 'common/components/ui/Button/Button';

import { Card } from 'common/components/ui/Card/Card';
import { Tag } from 'common/components/ui/Tags/Tags';

import s from './EnrollmentDropdown.module.scss';

export enum EnrollmentDropdownStatus {
  Completed = 'Выполнено',
  NeedSign = 'Требуется подпись',
  NoCompleted = 'Не выполнено'
}
export type EnrollmentDropdownButtonAction = {
  text: string;
  btnType?: ButtonType;
  onClick?: () => void;
};

type Props = {
  title: string;
  setVal: (visible: boolean) => void;
  active: boolean;
  status: EnrollmentDropdownStatus;
  content: React.ReactNode;
  className?: string;
  buttonActions?: Array<EnrollmentDropdownButtonAction>;
};
export const EnrollmentDropdown: React.FC<Props> = ({
  title,
  setVal,
  active,
  status,
  content,
  className,
  buttonActions
}) => {
  const onToggle = () => {
    setVal(!active);
  };

  const STATUS_CLASSES_MAP = {
    Выполнено: s.EnrollmentDropdown__status_completed,
    'Требуется подпись': s.EnrollmentDropdown__needSign,
    'Не выполнено': s.EnrollmentDropdown__status_disable
  };

  const showButtonActions = buttonActions?.map((buttonAction) => (
    <Button onClick={buttonAction.onClick} btnType={buttonAction.btnType} className={s.EnrollmentDropdown__button}>
      {buttonAction.text}
    </Button>
  ));

  return (
    <div className={clsx(s.EnrollmentDropdown, className)}>
      <Card className={s.EnrollmentDropdown__header} onClick={onToggle}>
        <div className={s.EnrollmentDropdown__col_first}>
          <div className={clsx(s.EnrollmentDropdown__status, STATUS_CLASSES_MAP[status])} />
          <div className={clsx(s.EnrollmentDropdown__title)}>{title}</div>
        </div>
        {status !== EnrollmentDropdownStatus.NoCompleted && (
          <Tag
            title={status}
            color={
              status === EnrollmentDropdownStatus.Completed
                ? '#53C120'
                : status === EnrollmentDropdownStatus.NeedSign
                ? '#EC6C10'
                : ''
            }
          />
        )}
      </Card>
      <Card className={clsx(s.EnrollmentDropdown__content, { [s.EnrollmentDropdown__content_show]: active })}>
        {content}
      </Card>
      <div className={clsx(s.EnrollmentDropdown__footer, { [s.EnrollmentDropdown__footer_show]: active })}>
        {showButtonActions}
      </div>
    </div>
  );
};
