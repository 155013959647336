import { Card } from 'common/components/ui/Card/Card';
import { Link } from 'react-router-dom';
import { Button, ButtonType } from 'common/components/ui/Button/Button';
import { Documents } from 'common/components/ui/Documents/Documents';
import { Enrollment4ContendForCompletedImg } from './Enrollment4ContendForCompletedImg';
import s from './Enrollment4ContendForCompleted.module.scss';

const documents = [
  { text: 'Договор.pdf', to: '/' },
  { text: 'Приложение.pdf', to: '/' },
  { text: 'Комплект договоров (версий для печати со штампами)', to: '/' },
  { text: 'Комплект договоров (электронная версия с файлом электронной подписи)', to: '/' },
  { text: 'Комплект приложений (версий для печати со штампами)', to: '/' },
  { text: 'Комплект приложений (электронная версия с файлом электронной подписи)', to: '/' }
];

export const Enrollment4ContendForCompleted = () => {
  return (
    <div className={s.Enrollment4ContendForCompleted__wrapper}>
      <Card className={s.Enrollment4ContendForCompleted__card_first}>
        <Enrollment4ContendForCompletedImg />
        <div className={s.Enrollment4ContendForCompleted__card_first_title}>Вы зачислены</div>
        <div className={s.Enrollment4ContendForCompleted__desc}>
          Вы зачислены по программе “Математика”. Ознакомьтесь с приказом и другими документами ниже.
        </div>
        <Link to="/" className={s.Enrollment4ContendForCompleted__link}>
          Приказ о зачислении
        </Link>
      </Card>
      <Card className={s.Enrollment4ContendForCompleted__card_second}>
        <div className={s.Enrollment4ContendForCompleted__card_second_title}>
          Ознакомьтесь со всеми необходимыми документами
        </div>
        <Documents className={s.Enrollment4ContendForCompleted__card__documentsList} items={documents} />
      </Card>
      <div className={s.Enrollment4ContendForCompleted__button_wrapper}>
        <Button btnType={ButtonType.Outlined}>Отказаться</Button>
      </div>
    </div>
  );
};
