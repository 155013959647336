import Avatar1 from 'assets/avatar-1.png';
import Avatar2 from 'assets/avatar-2.png';
import Avatar3 from 'assets/avatar-3.png';

import CourseImg1 from 'assets/lmsLesson/cardImage-1.png';
import CourseImg2 from 'assets/lmsLesson/cardImage-2.png';
import CourseImg3 from 'assets/lmsLesson/cardImage-3.png';
import CourseImg4 from 'assets/lmsLesson/cardImage-4.png';
import CourseImg5 from 'assets/lmsLesson/cardImage-5.png';
import CourseImg6 from 'assets/lmsLesson/cardImage-6.png';

import { CourseTheme, CourseThemeTypeEnum } from '../lmsLesson/_temporaryCourseThemesData';

export type Course = {
  id: string;
  preview: string;
  rating: number;
  title: string;
  description: string;
  author: string;
  allParticipants: number;
  participantsAvatars: Array<string>;
  progress: number;
};

export type CourseFull = {
  title: string;
  description: string;
  address: string;
  date: number;
  author: string;
  allParticipants: number;
  participantsAvatars: Array<string>;
  themes: Array<CourseTheme>;
};

export const course: CourseFull = {
  title: 'Основы квантовой физики и квантовой информатики',
  description:
    'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
  address: 'Шаболовская ул. Д 23, ауд.512',
  date: 1632639634,
  author: 'Ощепков Ю. А',
  allParticipants: 20,
  participantsAvatars: [Avatar1, Avatar2, Avatar3],
  themes: [
    {
      id: '1',
      title: 'Основы квантовой физики',
      items: [
        { id: '1532', title: 'Введение', date: 1633244434, type: CourseThemeTypeEnum.Hard, notes: 24 },
        {
          id: '2532',
          title: 'Свойства локализованного фотона',
          date: 1633244434,
          type: CourseThemeTypeEnum.Hard,
          notes: 24
        },
        {
          id: '3573',
          title: 'Квантовые состояния и гильбертово пространство',
          date: 1633244434,
          type: CourseThemeTypeEnum.Hard,
          notes: 24,
          items: [
            {
              id: '15233',
              video: 'V1sRabJhGWs',
              title: 'Элементарные частицы материи (Лекция 1)',
              description:
                'Элементарные частицы — неделимые части материального мира. Все частицы объединены в два класса: составляющие материи и переносчики взаимодействий. Как устроена материя и чем различаются сильное и слабое взаимодействие смотрите в этой лекции'
            },
            {
              id: '215512',
              video: 'LqYoAXDq3pk',
              title: 'Элементарные частицы материи (Лекция 1)',
              description:
                'Элементарные частицы — неделимые части материального мира. Все частицы объединены в два класса: составляющие материи и переносчики взаимодействий. Как устроена материя и чем различаются сильное и слабое взаимодействие смотрите в этой лекции'
            }
          ]
        },
        {
          id: '4352',
          title: 'Операторы, описывающие процессы',
          date: 1633244434,
          type: CourseThemeTypeEnum.Hard,
          notes: 24
        },
        {
          id: '5235',
          title: 'Соотношение неопределенностей',
          date: 1633244434,
          type: CourseThemeTypeEnum.Hard,
          notes: 24
        }
      ]
    },
    { id: '2', title: 'Квантовая информатика', items: [] },
    { id: '3', title: 'Кинематика', items: [] }
  ]
};

export const courses: Array<Course> = [
  {
    id: '1',
    preview: CourseImg1,
    rating: 5.7,
    title: 'Свойства локализованного фотона',
    description:
      'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3],
    progress: 72
  },
  {
    id: '2',
    preview: CourseImg2,
    rating: 4.6,
    title: 'Квантовые состояния и гильбертово пространство',
    description:
      'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3],
    progress: 72
  },
  {
    id: '3',
    preview: CourseImg3,
    rating: 7.4,
    title: 'Свойства локализованного фотона',
    description:
      'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3],
    progress: 72
  },
  {
    id: '4',
    preview: CourseImg4,
    rating: 1.5,
    title: 'Свойства локализованного фотона',
    description:
      'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3],
    progress: 72
  },
  {
    id: '5',
    preview: CourseImg5,
    rating: 9.1,
    title: 'Свойства локализованного фотона',
    description:
      'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3],
    progress: 72
  },
  {
    id: '6',
    preview: CourseImg6,
    rating: 9.9,
    title: 'Свойства локализованного фотона',
    description:
      'Для достижение максимальных аналитических способностей, которые столь полезны в жизни, пройдите наш курс и все будет хорошо.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3],
    progress: 72
  }
];
