import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../Icons/Icon';
import s from './LinkShowAll.module.scss';

type Props = {
  href: string;
  className?: string;
};
export const LinkShowAll: React.FC<Props> = ({ href, className }) => {
  return (
    <Link to={href} className={clsx(s.Link, className)}>
      Смотреть все <Icon type="arrow" className={s.LinkIcon} />
    </Link>
  );
};
