import { useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import clsx from 'clsx';

import { SearchInput } from 'common/components/ui/Forms/SearchInput/SearchInput';
import { Icon } from 'common/components/ui/Icons/Icon';
import { ParticipantsTable } from 'common/components/ui/ParticipantsTable/ParticipantsTable';
import { usePreventBodyScroll } from 'common/hooks/usePreventBodyScroll';
import s from './Participants.module.scss';

type Props = {
  visible: boolean;
  toggle: () => void;
};
export const Participants: React.FC<Props> = ({ visible, toggle }) => {
  usePreventBodyScroll(visible);
  const tableRef = useRef(null);
  const { onMouseDown } = useDraggableScroll(tableRef);
  const [searchVal, setSearchVal] = useState('');

  const handleChangeSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <div className={clsx(s.Participants__wrapper, { [s.Participants__wrapper_show]: visible })}>
      <div className={s.Participants__cardWrapper}>
        <div className={s.Participants__card_header}>
          <Icon onClick={toggle} type="close" className={s.Participants__iconClose} />
        </div>
        <div className={s.Participants__card} ref={tableRef} onMouseDown={onMouseDown}>
          <div className={s.Participants__header}>
            <div className={s.Participants__title}>Участники</div>
            <SearchInput onChange={handleChangeSearchVal} value={searchVal} classNameWrapper={s.Participants__search} />
          </div>
          <ParticipantsTable pageType="calendar" className={s.Participants__table} />
        </div>
      </div>
      <div className={s.Participants__background} />
    </div>
  );
};
