import clsx from 'clsx';
import React from 'react';
import s from './DashboardCardContent.module.scss';

export interface IDashboardCardContentProps {
  tag?: string;
  children: React.ReactNode;
  slotBottomRight?: React.ReactNode;
  className?: string;
}

export function DashboardCardContent({ tag, children, slotBottomRight, className }: IDashboardCardContentProps) {
  return (
    <div className={clsx(s.DashboardCardContent, className)}>
      {children}
      <div className={s.DashboardCardContent__footer}>
        {tag && <div className={s.DashboardCardContent__tag}>{tag}</div>}
        {slotBottomRight}
      </div>
    </div>
  );
}
