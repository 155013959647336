import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function InfoInShapeIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6781 14.9983H16.2121V20.6673"
          stroke={color || '#BBCAFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6661 20.6668H17.7465"
          stroke={color || '#BBCAFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1334 10.9924C16.1334 11.1766 15.9841 11.3259 15.7999 11.3259C15.6157 11.3259 15.4664 11.1766 15.4664 10.9924C15.4664 10.8083 15.6157 10.659 15.7999 10.659"
          stroke={color || '#BBCAFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7999 10.659C15.9841 10.659 16.1334 10.8083 16.1334 10.9924"
          stroke={color || '#BBCAFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="3.995"
          y="3.99316"
          width="24.01"
          height="24.01"
          rx="5"
          stroke={color || '#BBCAFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
