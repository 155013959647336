import React from 'react';
import { Icon } from 'common/components/ui/Icons/Icon';

import s from './AdmissionsOfficerCardItem.module.scss';

export type AdmissionsOfficerCardItem = {
  title: string;
  setVal: (visible: boolean) => void;
  active: boolean;
  children: React.ReactNode;
};
type Props = {
  items: Array<AdmissionsOfficerCardItem>;
};
export const AdmissionsOfficerCardItems: React.FC<Props> = ({ items }) => {
  const showItems = items.map(({ title, active, setVal, children }) => (
    <div className={s.AdmissionsOfficerAgreemet__item}>
      <AdmissionsOfficerDropdown text={title} setVal={setVal} active={active} />
      {active && <div className={s.AdmissionsOfficerAgreemet__content}>{children}</div>}
    </div>
  ));

  return <div className={s.AdmissionsOfficerAgreemet}>{showItems}</div>;
};

type AdmissionsOfficerDropdownProps = {
  text: string;
  setVal: (visible: boolean) => void;
  active: boolean;
};
const AdmissionsOfficerDropdown: React.FC<AdmissionsOfficerDropdownProps> = ({ text, setVal, active }) => {
  const onToggle = () => {
    setVal(!active);
    console.log(active);
  };

  return (
    <div onClick={onToggle} className={s.AdmissionsOfficerDropdown}>
      {text}
      <Icon
        type="arrow-2"
        style={{ transform: active ? `rotate(-180deg)` : 'rotate(0)' }}
        className={s.AdmissionsOfficerDropdown__icon}
      />
    </div>
  );
};
