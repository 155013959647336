import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function FacebookIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.34182 16.0001V9.2458H8.99518L9.39282 6.22367H6.341V4.29417C6.341 3.41944 6.58973 2.82289 7.86855 2.82289L9.5 2.82212V0.119122C9.21773 0.0828041 8.249 0.00012207 7.12236 0.00012207C4.77091 0.00012207 3.15991 1.4088 3.15991 3.99512V6.22367H0.5V9.24503H3.15991V16.0001H6.34182Z"
          fill={color || '#9FA9C1'}
        />
      </svg>
    </div>
  );
}
