import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function CloudIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.169 13.6884C10.523 13.9125 9.25049 15.309 9.25049 17.0164C9.25049 18.8799 10.7618 20.3912 12.6252 20.3912H20.0483C21.5396 20.3912 22.7481 19.1827 22.7481 17.6914C22.7481 16.2001 21.5396 14.9916 20.0483 14.9916C20.0483 12.7546 18.2356 10.9419 15.9986 10.9419C14.2206 10.9419 12.7119 12.0917 12.169 13.6884Z"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="16"
          cy="16.0001"
          r="12.005"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
