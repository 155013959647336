import { useState } from 'react';
import { Filter2 } from '../../../../../common/components/ui/Filter2/Filter2';
import { TestTask3Programms } from './TestTask3Programms';
import s2 from './TestTask3.module.scss';
import s from './TestTask3OtherPrograms.module.scss';


const items = [
  {
    id: '1',
    date: '06.00.00',
    title: 'Античность (направление 45.03.01 Филология)'
  },
  {
    id: '2',
    date: '06.00.00',
    title: 'Античность (направление 46.03.01 История)'
  },
  {
    id: '3',
    date: '06.00.00',
    title: 'Арабистика: язык, словесность, культура'
  },
  {
    id: '4',
    date: '06.00.00',
    title: 'Бизнес-информатика'
  },
  {
    id: '5',
    date: '06.00.00',
    title: 'Востоковедение'
  },
  {
    id: '6',
    date: '06.00.00',
    title: 'Государственное и муниципальное управление'
  },
  {
    id: '7',
    date: '06.00.00',
    title: 'Дизайн'
  },
  {
    id: '8',
    date: '06.00.00',
    title: 'Журналистика'
  },
  {
    id: '9',
    date: '06.00.00',
    title: 'Иностранные языки и межкультурная коммуникация'
  },
  {
    id: '10',
    date: '06.00.00',
    title: 'Инфокоммуникационные технологии и системы связи'
  },
  {
    id: '11',
    date: '06.00.00',
    title: 'Иностранные языки и межкультурная коммуникация'
  },
  {
    id: '12',
    date: '06.00.00',
    title: 'Инфокоммуникационные технологии и системы связи'
  }
];
const filterItems = [
  { slug: 'moscow', text: 'Москва' },
  { slug: 'nizhny_novgorod', text: 'Нижний Новгород' },
  { slug: 'perm', text: 'Пермь' },
  { slug: 'st.petersburg', text: 'Санкт-Петербург' }
];
export const TestTask3OtherPrograms = () => {
  const [chosenFilterItem, setChosenFilterItem] = useState('moscow');

  return (
    <div className={s.TestTask3OtherPrograms}>
      <div className={s2.TestTask3__h2}>Другие программы</div>
      <Filter2
        items={filterItems}
        setChosenItem={setChosenFilterItem}
        chosenItem={chosenFilterItem}
        className={s.TestTask3OtherPrograms_filter2}
      />
      <TestTask3Programms items={items} />
    </div>
  );
};
