import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function FontsInShapeIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.67999 15.4267H11.9333"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.81333 8.17334L5 17.0133"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6134 17.0133L8.81335 8.17334"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6667 25H6.33333C3.388 25 1 22.612 1 19.6667V6.33333C1 3.388 3.388 1 6.33333 1H19.6667C22.612 1 25 3.388 25 6.33333V19.6667C25 22.612 22.612 25 19.6667 25Z"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6667 11.6667V17.0001"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8813 12.4387C20.9285 13.4858 20.9285 15.1835 19.8813 16.2306C18.8342 17.2778 17.1365 17.2778 16.0894 16.2306C15.0422 15.1835 15.0422 13.4858 16.0894 12.4387C17.1365 11.3915 18.8342 11.3915 19.8813 12.4387"
          stroke={color || '#605BFF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
