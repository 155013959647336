import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Option } from 'react-select';

import { Button, ButtonType, ButtonSize, ButtonColor } from 'common/components/ui/Button/Button';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Input } from 'common/components/ui/Forms/Input/Input';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { directions_of_reception, sources } from '../_temporaryApplicationsOfApplicantsData';
import s from './ApplicationsOfApplicantsForm.module.scss';

type Props = {
  setIsApplicationCard: (isApplicationCard: boolean) => void;
};
export const ApplicationsOfApplicantsForm: React.FC<Props> = ({ setIsApplicationCard }) => {
  const initialValues = {
    registration_number: '0890021',
    date: '',
    source: { value: 'all', label: 'Все' },
    status: 'Новый',
    enrollee: '',
    admission_to_the_university: '',
    direction_of_reception: { value: 'all', label: 'Все' }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      setIsApplicationCard(false);
    }
  });
  const basicInputProps = { size: FieldSize.Small as FieldSize, onChange: formik.handleChange };

  const onCleanForm = () => {
    setIsApplicationCard(false);
  };

  return (
    <div className={s.ApplicationsOfApplicantsForm}>
      <div className={s.ApplicationsOfApplicantsForm__itemsRow}>
        <Field>
          <FieldTitle title="Регистрационный номер" />
          <Input {...basicInputProps} name="registration_number" value={formik.values.registration_number} />
        </Field>
        <Field>
          <FieldTitle title="Дата" />
          <Input {...basicInputProps} name="date" value={formik.values.date} isCopy />
        </Field>
        <Field>
          <FieldTitle title="Источник" />
          <Select
            {...basicInputProps}
            onChange={(option: Option) => formik.setFieldValue('source', option)}
            name="source"
            value={formik.values.source}
            options={sources}
            isCopy
          />
        </Field>
      </div>
      <div className={s.ApplicationsOfApplicantsForm__itemsCol}>
        <Field flex className={s.ApplicationsOfApplicantsForm__itemCol}>
          <FieldTitle flex title="Статус заявления" />
          <Input {...basicInputProps} name="status" value={formik.values.status} isCopy />
        </Field>
        <Field flex className={s.ApplicationsOfApplicantsForm__itemCol}>
          <FieldTitle flex title="Абитуриент" />
          <Input {...basicInputProps} name="enrollee" value={formik.values.enrollee} isCopy />
        </Field>
        <Field flex className={s.ApplicationsOfApplicantsForm__itemCol}>
          <FieldTitle flex title="Набор в ВУЗ" />
          <Input
            {...basicInputProps}
            name="admission_to_the_university"
            value={formik.values.admission_to_the_university}
            isCopy
          />
        </Field>
        <Field flex className={s.ApplicationsOfApplicantsForm__itemCol}>
          <FieldTitle flex title="Направление приема" />
          <Select
            {...basicInputProps}
            onChange={(option: Option) => formik.setFieldValue('direction_of_reception', option)}
            name="direction_of_reception"
            value={formik.values.direction_of_reception}
            options={directions_of_reception}
            isCopy
          />
        </Field>
      </div>
      <div className={s.ApplicationsOfApplicantsForm__actions}>
        <Button
          btnType={ButtonType.Outlined}
          size={ButtonSize.Small}
          className={s.ApplicationsOfApplicantsForm__action}>
          Печать
        </Button>
        <div>
          <Button
            size={ButtonSize.Small}
            color={ButtonColor.Success}
            iconStartType="check-2"
            className={s.ApplicationsOfApplicantsForm__action}>
            Принять
          </Button>
          <Button
            onClick={onCleanForm}
            size={ButtonSize.Small}
            color={ButtonColor.Error}
            iconStartType="close"
            className={s.ApplicationsOfApplicantsForm__action}>
            Отклонить
          </Button>
        </div>
      </div>
    </div>
  );
};
