import { Helmet } from 'react-helmet';

import { LandingBox } from 'common/components/ui/Box/Box';
import { Breadcrumbs } from 'common/components/ui/Breadcrumbs/Breadcrumbs';
import { Title } from 'common/components/ui/Title/Title';
import { Filter2 } from 'common/components/ui/Filter2/Filter2';
import { useState } from 'react';
import { SearchInput } from 'common/components/ui/Forms/SearchInput/SearchInput';
import { Tag } from 'common/components/ui/Tags/Tags';
import { AppColor } from 'store/app';
import { Button, ButtonType } from 'common/components/ui/Button/Button';
import { Checkbox } from 'common/components/ui/Forms/Checkbox/Checkbox';
import clsx from 'clsx';
import { ParticipantsTable } from 'common/components/ui/ParticipantsTable/ParticipantsTable';
import ParticipantsImg from './assets/participants.png';
import s from './LessonPage.module.scss';

const breadcrumbsData = [
  { href: '/', title: 'Главная' },
  { href: '/the-quantum-physics', title: 'Квантовая физика' },
  { href: '/intro', title: 'Вводное занятие. Что общего между квантовой физикой и криптографией?' }
];
const buttonsActions = [
  { slug: 'lesson-program', text: 'Программа занятия' },
  { slug: 'attendance-of-students', text: 'Присутствие студентов' }
];
const tableTitles = ['ФИО студента', 'Факультет', 'Посещаемость', 'Общий балл', ' '];
const tableItems = [
  {
    id: '1',
    groupNum: 342,
    items: [
      {
        id: '1',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      },
      {
        id: '2',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      }
    ]
  },
  {
    id: '2',
    groupNum: 342,
    items: [
      {
        id: '1',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      },
      {
        id: '2',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      }
    ]
  }
];

export const LessonPage = () => {
  const [chosenAction, setChosenAction] = useState(buttonsActions[0].slug);
  const [searchVal, setSearchVal] = useState('');

  const handleChangeSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  const showTitles = tableTitles.map((title) => (
    <div className={clsx(s.LessonPage__table__item, s.LessonPage__table_title)}>{title}</div>
  ));

  /* eslint-disable react-hooks/rules-of-hooks */
  const tableChildren = tableItems.map((item) => {
    const showSubItems = item.items.map((subItem) => {
      const [isAttended, setIsAttended] = useState(subItem.student.isAttend);

      const handleChangeAttend = () => {
        setIsAttended(!isAttended);
      };

      return (
        <div className={s.LessonPage__table__row} key={subItem.id}>
          {Object.entries(subItem).map(
            ([key, value], index) =>
              key !== 'id' && (
                <div key={index} className={s.LessonPage__table__item}>
                  {key === 'attendance' || key === 'overallScore' ? (
                    <Tag
                      color={value < 5 ? AppColor.Red : value >= 5 && value < 9 ? AppColor.Yellow : AppColor.Green}
                      title={`${value}/10`}
                      className={s.LessonPage__table__tag}
                    />
                  ) : key === 'student' ? (
                    <Checkbox
                      onChange={handleChangeAttend}
                      label={subItem.student.name}
                      checked={isAttended}
                      name="student"
                    />
                  ) : (
                    value
                  )}
                </div>
              )
          )}
          <div className={s.LessonPage__table__item}>
            <Button btnType={ButtonType.Outlined}>Написать</Button>
          </div>
        </div>
      );
      /* eslint-enable react-hooks/rules-of-hooks */
    });

    return (
      <>
        <div key={item.id} className={clsx(s.LessonPage__table__row, s.LessonPage__rowTitle)}>
          <div className={clsx(s.LessonPage__table__item, s.LessonPage__groupTitle)}>Группа: {item.groupNum}</div>
        </div>
        {showSubItems}
      </>
    );
  });

  return (
    <LandingBox className={s.LessonPage__wrapper}>
      <Helmet title="Урок" />
      <div className={s.LessonPage__header}>
        <Breadcrumbs items={breadcrumbsData} />
        <Title
          title={'Вводное занятие. Что общего между квантовой физикой и криптографией?'}
          className={s.LessonPage__title}
        />
        <div>
          <div className={s.LessonPage__tagInfo}>20 сентября 2021</div>
          <div className={s.LessonPage__tagInfo}>10:00</div>
          <div className={s.LessonPage__tagInfo}>Шаболовская ул. Д 23, ауд.512</div>
          <div className={s.LessonPage__tagInfo}>
            Участник: <img src={ParticipantsImg} className={s.LessonPage__participants} />
            +20
          </div>
        </div>
      </div>
      <div className={s.LessonPage__content}>
        <div className={s.LessonPage__content_header}>
          <Filter2
            items={buttonsActions}
            chosenItem={chosenAction}
            setChosenItem={setChosenAction}
            classNameItem={s.LessonPage__filter_item}
          />
          <SearchInput onChange={handleChangeSearchVal} value={searchVal} classNameWrapper={s.LessonPage__search} />
        </div>
        <ParticipantsTable pageType="lesson" classNameTable={s.LessonPage__table} />
        {/* <div>
          <div className={s.LessonPage__table__row}>{showTitles}</div>
          <div className={s.LessonPage__table}>{tableChildren}</div>
        </div> */}
      </div>
    </LandingBox>
  );
};
