import { useFormik } from 'formik';
import { Option } from 'react-select';


import { Button } from '../../../../../common/components/ui/Button/Button';
import { FilterItem } from '../../../../../common/components/ui/Filter/FilterItem';
import { FilterTitle } from '../../../../../common/components/ui/Filter/FilterTitle';
import { FieldSize } from '../../../../../common/components/ui/Forms/basicTypes';
import { Select } from '../../../../../common/components/ui/Forms/Select/Select';
import { Filter } from '../../../../../common/components/ui/Filter/Filter';
import s from './TestTaskForm.module.scss';

export const items = [
  { value: 'all', label: 'Все' },
  { value: 'english', label: 'Английский язык' }
];
export const forms_of_conducting = [
  { value: 'all', label: 'Все' },
  { value: 'online', label: 'Онлайн' }
];
const time = [{ value: '11:00', label: '11:00 (московское время)' }];
const date = [{ value: '06.08.2021', label: '06.08.2021' }];

type Props = {
  setItem: (number: number) => void;
};
export const TestTaskForm: React.FC<Props> = ({ setItem }) => {
  const initialValues = {
    item: '',
    form_of_conducting: '',
    time: '',
    date: ''
  };
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      setItem(1);
    }
  });
  const basicInputProps = { size: FieldSize.Small as FieldSize, onChange: formik.handleChange };

  return (
    <>
      <Filter>
        <div className={s.TestTaskForm}>
          <FilterItem className={s.TestTaskForm__field}>
            <FilterTitle title="Предмет" />
            <Select
              {...basicInputProps}
              onChange={(option: Option) => formik.setFieldValue('item', option)}
              name="item"
              value={formik.values.item}
              options={items}
              className={s.Filter__select}
            />
          </FilterItem>
          <FilterItem className={s.TestTaskForm__field}>
            <FilterTitle title="Форма проведения" />
            <Select
              {...basicInputProps}
              onChange={(option: Option) => formik.setFieldValue('form_of_conducting', option)}
              name="form_of_conducting"
              value={formik.values.form_of_conducting}
              options={forms_of_conducting}
              className={s.Filter__select}
            />
          </FilterItem>
          <FilterItem className={s.TestTaskForm__field}>
            <FilterTitle title="Время" />
            <Select
              {...basicInputProps}
              onChange={(option: Option) => formik.setFieldValue('time', option)}
              name="form_of_conducting"
              value={formik.values.time}
              options={time}
              className={s.Filter__select}
            />
          </FilterItem>
          <FilterItem className={s.TestTaskForm__field}>
            <FilterTitle title="Дата" />
            <Select
              {...basicInputProps}
              onChange={(option: Option) => formik.setFieldValue('date', option)}
              name="form_of_conducting"
              value={formik.values.date}
              options={date}
              className={s.Filter__select}
            />
          </FilterItem>
          <div />
        </div>
      </Filter>
      <div className={s.TestTaskForm__button_wrapper}>
        <Button onClick={formik.handleSubmit} className={s.TestTaskForm__button}>
          Записаться на вступительные испытания
        </Button>
      </div>
    </>
  );
};
