import clsx from 'clsx';

import { Card } from '../Card/Card';
import s from './CardWithShortContent.module.scss';

type Props = {
  id: string;
  smallText: string;
  title: string;
  isShowMore?: boolean;
  onClick: () => void;
  active: boolean;
};
export const CardWithShortContent: React.FC<Props> = ({ id, title, smallText, isShowMore, onClick, active }) => (
  <Card
    onClick={onClick}
    className={clsx(
      s.CardWithShortContent__card,
      isShowMore ? s.CardWithShortContent__card_lg : s.CardWithShortContent__card_sm,
      { [s.CardWithShortContent__card_active]: active }
    )}>
    <span className={clsx(s.CardWithShortContent__card_item, s.CardWithShortContent__smallText)}>{smallText}</span>
    <span className={clsx(s.CardWithShortContent__card_item, s.CardWithShortContent__title)}>{title}</span>
    <span
      className={clsx(s.CardWithShortContent__card_item, s.CardWithShortContent__showMore, {
        [s.CardWithShortContent__showMore_show]: isShowMore
      })}>
      Открыть список
    </span>
  </Card>
);
