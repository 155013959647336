import { Dialog as DialogType } from 'routes/lmsMessages/_temporaryDialogData';

import { Title } from 'common/components/ui/Title/Title';
import { Button, ButtonSize, ButtonType } from 'common/components/ui/Button/Button';
import { AvatarsSmall } from 'common/components/ui/AvatarsSmall/AvatarsSmall';
import clsx from 'clsx';
import s from './LMSDialogs.module.scss';

type Props = {
  items: Array<DialogType>;
};
export const LMSDialogs: React.FC<Props> = ({ items }) => {
  const showDialogs = items.map((item) => <LMSDialog {...item} />);

  return (
    <div className={s.LMSDialogs__wrapper}>
      <Title title="Сообщения" tag="h1" className={s.LMSDialogs__header}>
        <Button btnType={ButtonType.Outlined} size={ButtonSize.Small}>
          Создать чат
        </Button>
      </Title>
      <div className={s.LMSDialogs__dialogs}>{showDialogs}</div>
    </div>
  );
};

const LMSDialog: React.FC<DialogType> = ({ avatar, title, participantsAvatars, allParticipants }) => {
  return (
    <div className={s.LMSDialog__wrapper}>
      <div className={s.LMSDialog_col}>
        <div className={s.LMSDialog__avatar_wrapper}>
          <img src={avatar} alt={title} className={s.LMSDialog__avatar} />
        </div>
        <span className={s.LMSDialog__title}>{title}</span>
      </div>
      <div className={clsx(s.LMSDialog_col, s.LMSDialog__participants)}>
        <AvatarsSmall avatars={participantsAvatars} />+{allParticipants}
      </div>
    </div>
  );
};
