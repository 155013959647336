import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { LandingBox } from '../../common/components/ui/Box/Box';
import { LandingHero } from '../../common/components/ui/LandingHero/LandingHero';
import { LandingPoints } from '../../common/components/ui/LandingPoints/LandingPoints';
import { LandingHeading } from '../../common/components/ui/LandingHeading/LandingHeading';
import { LandingApplication } from '../../common/components/ui/LandingApplication/LandingApplication';
import { ServiceCard } from '../../common/components/ui/ServiceCard/ServiceCard';
import { ServiceGrid } from '../../common/components/ui/ServiceGrid/ServiceGrid';
import { FolderInShapeIcon } from '../../common/components/ui/Icons/FolderInShapeIcon';
import { FolderIcon } from '../../common/components/ui/Icons/FolderIcon';
import { InfoInShapeIcon } from '../../common/components/ui/Icons/InfoInShapeIcon';
import { ListIcon } from '../../common/components/ui/Icons/ListIcon';
import { UserInShapeIcon } from '../../common/components/ui/Icons/UserInShapeIcon';
import { services } from '../services/_temporaryServicesData';
import { AdvantageCard, IAdvantageCardProps } from './AdvantageCard';
import { ReactComponent as PersonGraduateIcon } from './advantages/person-graduate.svg';
import { ReactComponent as RulerPenIcon } from './advantages/ruler-pen.svg';
import { ReactComponent as SchoolIcon } from './advantages/school.svg';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import picture from './picture.png';
import pointsPicture from './points.png';
import s from './IndexPage.module.scss';

const title = 'E-Platform – платформа цифрового университета';
const text =
  'Платформа помогает университетам в реализации лучших практик цифрового образования, упрощает создание онлайн-сервисов для студентов, абитуриентов и сотрудников. Тем самым повышает конкурентоспособность российских вузов на международном рынке образования';

const points: [string, string[]][] = [
  [
    'E–Platform (Цифровой университет)',
    [
      'Реализация программ дополнительного образования',
      'Перевод в онлайн программ основного образования',
      'Создание единого информационного пространства для университета',
      'Предоставление сервисов для сотрудников, студентов, абитуриентов и родителей',
      'Сбор разнообразных данных и проведение качественной аналитики'
    ]
  ],
  [
    'Важные особенности',
    [
      'Высочайший уровень информационной безопасности',
      'Применение передовых практик цифрового образования',
      'Лучшие решения от НИУ ВШЭ, Яндекса и их партнеров',
      'Развитие цифровых компетенций у всех участников'
    ]
  ]
];

const advantages: IAdvantageCardProps[] = [
  {
    icon: PersonGraduateIcon,
    background: '#fff4e0',
    title: 'Студенты',
    advantages: [
      'Упрощенная система поступления',
      'Академическая поддержка студентов',
      'Платформа о студенческой жизни'
    ]
  },
  {
    icon: RulerPenIcon,
    background: '#f3f6ff',
    title: 'Преподаватели',
    advantages: ['Поддержка профессиональных услуг', 'Услуга «Мой портал»', 'Аналитическая поддержка ']
  },
  {
    icon: SchoolIcon,
    background: '#e9fcf5',
    title: 'Университеты',
    advantages: ['Укрепление имиджа', 'Привлечение студентов и преподавателей', 'Привлечение финансирования']
  }
];

export default function IndexPage() {
  return <Redirect to="/register" />;
  // return (
  //   <React.Fragment>
  //     <LandingHero
  //       className={s.IndexPage__hero}
  //       contentClassName={s.IndexPage__heroContent}
  //       textClassName={s.IndexPage__heroText}
  //       // scroll to the application form
  //       action={() => document.getElementById('application')?.scrollIntoView({ behavior: 'smooth' })}
  //       title={title}
  //       text={text}
  //       picture={picture}
  //     />
  //     <LandingBox>
  //       <div className={s.IndexPage__serviceBar}>
  //         <LandingHeading className={s.IndexPage__serviceHeading}>Сервисы</LandingHeading>
  //         <Link to={'/services'} className={s.IndexPage__serviceLink}>
  //           <span>Смотреть все</span>
  //           <ArrowIcon />
  //         </Link>
  //       </div>
  //       <ServiceGrid>
  //         {services.slice(0, 6).map((service) => (
  //           <ServiceCard key={service.title} {...service} />
  //         ))}
  //       </ServiceGrid>
  //       <LandingPoints className={s.IndexPage__points} points={points} picture={pointsPicture} />
  //     </LandingBox>
  //     <section className={s.IndexPage__advantages}>
  //       <LandingBox>
  //         <LandingHeading className={s.IndexPage__advantageHeading}>Преимущества сервисов</LandingHeading>
  //         <div className={s.IndexPage__advantageGrid}>
  //           {advantages.map((advantage) => (
  //             <AdvantageCard key={advantage.title} {...advantage} />
  //           ))}
  //         </div>
  //       </LandingBox>
  //     </section>
  //     <LandingApplication />
  //   </React.Fragment>
  // );
}
