import clsx from 'clsx';
import React from 'react';
import s from './Box.module.scss';

export interface IBoxProps {
  children: React.ReactNode;
  className?: string;
}

export function Box({ children, className }: IBoxProps) {
  return <div className={clsx(s.Box, className)}>{children}</div>;
}

export function LandingBox({ children, className }: IBoxProps) {
  return <Box className={clsx(s.LandingBox, className)}>{children}</Box>;
}
