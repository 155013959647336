import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { CourseItem } from 'routes/lmsLesson/_temporaryCourseThemesData';

import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

import { Icon } from 'common/components/ui/Icons/Icon';
import { Button, ButtonType } from 'common/components/ui/Button/Button';
import s from './LMSLessonThemeItem.module.scss';

export type LMSLessonThemeItemProps = {
  chosenSubitems: string | null;
  onShowSubitems: (id: string) => void;
  onSetVideoUrl: (url: string) => void;
};
export const LMSLessonThemeItem: React.FC<LMSLessonThemeItemProps & CourseItem> = ({
  id,
  title,
  items,
  type,
  notes,
  date,

  chosenSubitems,
  onShowSubitems,
  onSetVideoUrl
}) => {
  const showSubitems = items?.map((i) => (
    <div onClick={() => onSetVideoUrl(i.video)} key={i.id} className={s.LMSLessonThemeItem__subitem}>
      <div
        style={{ backgroundImage: `url(https://img.youtube.com/vi/${i.video}/0.jpg)` }}
        className={s.LMSLessonThemeItem__subitem_preview}>
        <div className={s.LMSLessonThemeItem__subitem_play_wrapper}>
          <PlayIcon className={s.LMSLessonThemeItem__subitem_play} />
        </div>
      </div>
      <div className={s.LMSLessonThemeItem__subitem_content}>
        <div className={s.LMSLessonThemeItem__subitem_title}>{i.title}</div>
        <p className={s.LMSLessonThemeItem__subitem_description}>{i.description}</p>
      </div>
    </div>
  ));

  return (
    <div className={s.LMSLessonThemeItem__item_wrapper}>
      <div className={s.LMSLessonThemeItem__item}>
        <span className={s.LMSLessonThemeItem__item_title}>
          {title}
          {items && (
            <Icon
              onClick={() => onShowSubitems(id)}
              type="arrow-2"
              className={clsx(s.LMSLessonThemeItem__subitems_button, {
                [s.LMSLessonThemeItem__subitems_button_active]: chosenSubitems
              })}
              rounded
              fillColor="#5C617C"
            />
          )}
        </span>
        <span />
        <span>{moment(date).format('DD.MM.YYYY')}</span>
        <span />
        <span>{type}</span>
        <span />
        <span>{notes}</span>
        <span />
        <div>
          <Button
            btnType={ButtonType.Outlined}
            iconStartType="download"
            className={clsx(s.LMSLessonThemeItem__button, s.LMSLessonThemeItem__button_download)}
            helpText="Скачать материалы занятия"
          />
        </div>
        <span />
        <Button btnType={ButtonType.Outlined} className={s.LMSLessonThemeItem__button}>
          Записаться на консультацию
        </Button>
      </div>
      <div
        className={clsx(s.LMSLessonThemeItem__subItems, {
          [s.LMSLessonThemeItem__subItems_active]: chosenSubitems === id
        })}>
        {showSubitems}
      </div>
    </div>
  );
};
