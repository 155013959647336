import { useFormik } from 'formik';
import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Input } from 'common/components/ui/Forms/Input/Input';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Enrollment4Banner } from './Enrollment4Banner';
import s from './Enrollment4PaymentOfTheFirstTuitionFee.module.scss';

export const Enrollment4PaymentOfTheFirstTuitionFee = () => {
  const initialValues = {
    number_of_contract: '',
    payers_initials: '',
    purpose_of_payment: '',
    sum: ''
  };
  const formik = useFormik({ initialValues, onSubmit: () => {} });

  const inputs = [
    {
      name: 'number_of_contract',
      value: formik.values.number_of_contract,
      placeholder: 'Номер договора'
    },
    {
      name: 'payers_initials',
      value: formik.values.payers_initials,
      placeholder: 'ФИО плательщика'
    },
    {
      name: 'purpose_of_payment',
      value: formik.values.purpose_of_payment,
      placeholder: 'Назначение платежа'
    },
    {
      name: 'sum',
      value: formik.values.sum,
      placeholder: 'Сумма'
    }
  ];
  const showInputs = inputs.map(({ name, value, placeholder }) => (
    <Field key={placeholder}>
      <FieldTitle title={placeholder} />
      <Input name={name} value={value} size={FieldSize.Small} />
    </Field>
  ));

  return (
    <div className={s.Enrollment4PaymentOfTheFirstTuitionFee}>
      <div className={s.Enrollment4PaymentOfTheFirstTuitionFee__title}>Данные о платеже</div>
      <Enrollment4Banner
        text="Обращаем внимание! Без оплаты первого взноса за обучение договор не будет считаться заключенным!"
        className={s.Enrollment4PaymentOfTheFirstTuitionFee__banner}
      />
      <div className={s.Enrollment4PaymentOfTheFirstTuitionFee__form}>{showInputs}</div>
    </div>
  );
};
