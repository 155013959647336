import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';

import { AvatarsSmall } from 'common/components/ui/AvatarsSmall/AvatarsSmall';
import { Course } from 'routes/lmsHome/_temporaryCoursesData';
import s from './LMSHomeCourseCard.module.scss';

export const LMSHomeCourseCard: React.FC<Course> = ({
  id,
  preview,
  rating,
  title,
  description,
  author,
  allParticipants,
  participantsAvatars,
  progress
}) => {
  return (
    <div className={s.LMSHomeCourseCard__wrapper}>
      <div className={s.LMSHomeCourseCard__content}>
        <div className={s.LMSHomeCourseCard__preview} style={{ backgroundImage: `url(${preview})` }}>
          <span className={s.LMSHomeCourseCard__rating}>{rating}</span>
        </div>
        <Link to={`/lms-lesson/${id}`} className={s.LMSHomeCourseCard__title}>
          {title}
        </Link>
        <div className={s.LMSHomeCourseCard__description}>{description}</div>
      </div>
      <div className={s.LMSHomeCourseCard__foooter}>
        <div className={s.LMSHomeCourseCard__footer_col}>
          <span className={s.LMSHomeCourseCard__footerData}>{author}</span>
          <div className={s.LMSHomeCourseCard__footerData}>
            Участники: <AvatarsSmall avatars={participantsAvatars} />
            <span className={s.LMSHomeCourseCard__footerData_counter}>+{allParticipants}</span>
          </div>
        </div>
        <div className={s.LMSHomeCourseCard__footer_col}>
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            className={s.LMSHomeCourseCard__progress}
            styles={{
              root: { width: 33 },
              path: {
                stroke: `rgba(51, 197, 92, ${progress / 100})`,
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transformOrigin: 'center center'
              },
              trail: {
                stroke: '#B3BACB',
                strokeLinecap: 'butt',
                transformOrigin: 'center center'
              },
              text: {
                fontWeight: 'bold',
                fontSize: 28,
                color: '#222845',
                textAlign: 'center',
                fill: '#222845'
              }
            }}
            strokeWidth={5}
          />
        </div>
      </div>
    </div>
  );
};
