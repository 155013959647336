import React from 'react';

import { LinkShowAll } from '../../../common/components/ui/LinkShowAll/LinkShowAll';
import { Title } from '../../../common/components/ui/Title/Title';
import { ServiceGrid } from '../../../common/components/ui/ServiceGrid/ServiceGrid';
import NewsImg1 from './assets/news-1.png';
import NewsImg2 from './assets/news-2.png';
import NewsImg3 from './assets/news-3.png';
import s from './News.module.scss';

const newsData = [
  {
    id: '1',
    title: 'Ярослав Кузьминов награжден Орденом «За заслуги перед Отечеством» II степени',
    date: '02.07.2021',
    img: NewsImg1
  },
  {
    id: '2',
    title: 'Ярослав Кузьминов переходит на пост научного руководителя НИУ ВШЭ',
    date: '02.07.2021',
    img: NewsImg2
  },
  {
    id: '2',
    title: '«Домыслы о влиянии вакцин на репродуктивную функцию далеко не оригинальная "пугалка" антивакцинаторов»‎',
    date: '30.06.2021',
    img: NewsImg3
  }
];

export const News = () => {
  const showNews = newsData.map((item) => (
    <div key={item.id} className={s.SubRoutePage__newsItem}>
      <div className={s.SubRoutePage__newsImageWrapper}>
        <img src={item.img} className={s.SubRoutePage__newsImage} />
      </div>
      <div className={s.SubRoutePage__newsTitle}>{item.title}</div>
      <div className={s.SubRoutePage__newsDate}>{item.date}</div>
    </div>
  ));

  return (
    <>
      <Title title="Новости" tag="h2">
        <LinkShowAll href="/schedule" className="d-desktop-only" />
      </Title>
      <ServiceGrid className={s.SubRoutePage__newsList}>{showNews}</ServiceGrid>
      <div className={s.SubRoutePage__news__linkWrapper}>
        <LinkShowAll href="/schedule" className="d-desktop-hide" />
      </div>
    </>
  );
};
