import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function VKIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9531 5.39351C14.9531 5.39351 17.3242 1.90143 17.5606 0.754045C17.6392 0.344507 17.4657 0.115199 17.0575 0.115199H14.9997C14.5285 0.115199 14.3558 0.327583 14.2143 0.639814C14.2143 0.639814 13.1015 3.11312 11.7491 4.67258C11.3138 5.17604 11.0929 5.32835 10.8532 5.32835C10.6601 5.32835 10.5709 5.15912 10.5709 4.70474V0.721045C10.5709 0.164276 10.5079 0.00012207 10.0677 0.00012207H6.77045C6.51845 0.00012207 6.36136 0.152429 6.36136 0.360583C6.36136 0.885199 7.13045 1.00366 7.13045 2.42605V5.36051C7.13045 5.95112 7.09936 6.18043 6.83264 6.18043C6.12573 6.18043 4.44518 3.63943 3.50264 0.754045C3.31445 0.164276 3.10991 0.00012207 2.57564 0.00012207H0.518727C0.220091 0.00012207 0 0.213353 0 0.524737C0 1.09843 0.643909 3.75451 3.17291 7.31089C4.869 9.70551 7.10018 11.0001 9.10964 11.0001C10.3353 11.0001 10.6339 10.7869 10.6339 10.2462V8.41004C10.6339 7.95058 10.8057 7.75428 11.0577 7.75428C11.34 7.75428 11.8391 7.84905 13.005 9.04889C14.3877 10.4273 14.4818 11.0001 15.2354 11.0001H17.5443C17.7799 11.0001 18 10.885 18 10.4755C18 9.93397 17.3242 8.96766 16.2875 7.81943C15.8637 7.22966 15.1732 6.58997 14.9531 6.29551C14.6389 5.95112 14.733 5.75397 14.9531 5.39351Z"
          fill={color || '#9FA9C1'}
        />
      </svg>
    </div>
  );
}
