import { useState } from 'react';


import { CardWithShortContentList } from 'common/components/ui/CardWithShortContent/CardWithShortContentList';
import { Filter2 } from 'common/components/ui/Filter2/Filter2';
import { Button, ButtonType } from 'common/components/ui/Button/Button';
import Img from './assets/img.png';
import s from './Enrollment3.module.scss';

const filterItems = [
  { slug: 'moscow', text: 'Москва' },
  { slug: 'nizhny_novgorod', text: 'Нижний Новгород' },
  { slug: 'perm', text: 'Пермь' },
  { slug: 'st.petersburg', text: 'Санкт-Петербург' }
];
const items = [
  {
    id: '1',
    smallText: 'Общежитие №1',
    title: 'Москва, ул. Б. Переяславская, д. 50, стр.1'
  },
  {
    id: '2',
    smallText: 'Общежитие №2',
    title: 'Москва, ул. Электродная, д. 1'
  },
  {
    id: '3',
    smallText: 'Общежитие №3',
    title: 'Москва, ул. Энергетическая, д. 10, корп. 2'
  },
  {
    id: '4',
    smallText: 'Общежитие №4',
    title: 'Москва, ул. Студенческая, д. 33/1'
  },
  {
    id: '5',
    smallText: 'Общежитие №5',
    title: 'Москва, ул. Кибальчича, д. 7'
  },
  {
    id: '6',
    smallText: 'Общежитие №6',
    title: 'Московская область, г. Одинцово, ул. Комсомольская, д. 1'
  },
  {
    id: '7',
    smallText: 'Общежитие №7',
    title: 'Москва, 1-й Саратовский проезд, д. 5, к. 2, д. 7, к. 3'
  },
  {
    id: '8',
    smallText: 'Общежитие №8',
    title: 'г. Одинцово, ул. Маковского, д. 2'
  }
];
type Props = {
  setChosenItem: (number: number) => void;
};
export const Enrollment3: React.FC<Props> = ({ setChosenItem }) => {
  const [chosenFilterItem, setChosenFilterItem] = useState('moscow');

  return (
    <div className={s.Enrollment3}>
      <div className={s.Enrollment3__title}>Общежития в Москве</div>
      <Filter2
        items={filterItems}
        setChosenItem={setChosenFilterItem}
        chosenItem={chosenFilterItem}
        className={s.Enrollment3_filter2}
      />
      <div className={s.Enrollment3__img_wrapper}>
        <img src={Img} className={s.Enrollment3__img} />
      </div>
      <CardWithShortContentList items={items} />
      <div className={s.Enrollment3__buttons_wrapper}>
        <Button btnType={ButtonType.Outlined} className={s.Enrollment3__button}>
          Не требуется общежитие
        </Button>
        <Button onClick={() => setChosenItem(3)} className={s.Enrollment3__button}>
          Далее
        </Button>
      </div>
    </div>
  );
};
