import clsx from 'clsx';
import React from 'react';

import s from './AvatarsSmall.module.scss';

export type AvatarsSmallProps = {
  avatars: Array<string>;
  className?: string;
};
export const AvatarsSmall: React.FC<AvatarsSmallProps> = ({ avatars, className }) => {
  const showParticipantsAvatars = avatars.map((item, index) => (
    <div className={s.AvatarsSmall__avatar_wrapper} key={index}>
      <img src={item} key={index} className={s.AvatarsSmall__avatar} />
    </div>
  ));

  return <div className={clsx(s.AvatarsSmall__avatars, className)}>{showParticipantsAvatars}</div>;
};
