import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { MainLayoutPageTypeEnum } from 'routes/_layouts/MainLayout';

import { useToggle } from '@proscom/ui-react';

import { Icon } from '../Icons/Icon';
import s from './MenuLeft.module.scss';

const items = [
  { icon: 'home', title: 'Главная', href: '/sub-route' },
  { icon: 'settings', title: 'Настройки', href: '/' },
  { icon: 'check', title: 'Настройки', href: '/settings' },
  { icon: 'file', title: 'Объявления', href: '/lms-ads' },
  { icon: 'book', title: 'Настройки', href: '/settings' },
  { icon: 'dialog', title: 'Сообщения', href: '/lms-messages' }
];
const managerItems = [
  { icon: 'home', title: 'Главная', href: '/sub-route' },
  { icon: 'user-data', title: 'Менеджер', href: '/' },
  { icon: 'confederate', title: 'Настройки', href: '/settings' },
  { icon: 'book', title: 'Настройки', href: '/settings' },
  { icon: 'two-arrows', title: 'Настройки', href: '/settings' },
  { icon: 'monitor', title: 'Настройки', href: '/settings' },
  { icon: 'diagram-bars', title: 'Настройки', href: '/settings' },
  { icon: 'file', title: 'Объявления', href: '/lms-ads' }
];
const lmsItems = [
  { icon: 'home', title: 'Главная', href: '/lms' },
  { icon: 'dialog', title: 'Сообщения', href: '/lms-messages' },
  { icon: 'book', title: 'Курсы', href: '/' },
  { icon: 'calendar-2', title: 'Календарь', href: '/calendar' },
  { icon: 'check-in-roun', title: 'Оценки', href: '/' },
  { icon: 'list', title: 'События', href: '/', notifications: 4 },
  { icon: 'file', title: 'Объявления', href: '/lms-ads' }
];
type Props = {
  activeItem: string;
  pageType?: MainLayoutPageTypeEnum;
};
export const MenuLeft: React.FC<Props> = ({ pageType = MainLayoutPageTypeEnum.Default, activeItem }) => {
  const location = useLocation();
  const [routePath, setRoutePath] = useState(location.pathname);
  const isOpen = useToggle();

  useEffect(() => {
    setRoutePath(location.pathname);
  }, [location]);

  const onToggleOpen = () => {
    isOpen.toggle();
  };

  const isActive = (name: string) => {
    return activeItem === name;
  };

  const showItems = (list: Array<{ icon: any; title: string; href: string; notifications?: number }>) => {
    return list.map((item, index) => (
      <Link
        to={item.href}
        className={clsx(s.MenuLeft__item, {
          [s.MenuLeft__item_open]: isOpen.value,
          [s.MenuLeft__item_active]: isActive(item.title)
        })}
        key={index}>
        <div className={clsx(s.MenuLeft__itemLine, { [s.MenuLeft__itemLine_active]: isActive(item.title) })} />
        <div className={s.MenuLeft__itemIcon_wrapper}>
          {!isOpen.value && item.notifications && <div className={s.MenuLeft__item_notice_small} />}
          <Icon
            type={item.icon}
            className={s.MenuLeft__itemIcon}
            color={isActive(item.title) ? '#605bff' : '#9FA9C1'}
            height={24}
          />
        </div>

        {isOpen.value && (
          <div className={s.MenuLeft__text_wrapper}>
            <div className={s.MenuLeft__text}>{item.title}</div>
            {isOpen.value && item.notifications && (
              <div className={s.MenuLeft__item_notice_large}>{item.notifications}</div>
            )}
          </div>
        )}
      </Link>
    ));
  };
  const showChoosenItems = () => {
    switch (pageType) {
      case MainLayoutPageTypeEnum.Default:
        return showItems(items);
      case MainLayoutPageTypeEnum.Manager:
        return showItems(managerItems);
      case MainLayoutPageTypeEnum.LMS:
        return showItems(lmsItems);
    }
  };

  return (
    <div className={clsx(s.MenuLeft, { [s.MenuLeft_active]: isOpen.value })}>
      <div className={s.MenuLeft__items}>
        <div
          onClick={onToggleOpen}
          className={clsx(s.MenuLeft__item, { [s.MenuLeft__item_open]: isOpen.value }, s.MenuLeft__arrow_wrapper)}>
          <span />
          <Icon
            type="arrow-2"
            color="#9FA9C1"
            className={clsx(s.MenuLeft__itemIcon, s.MenuLeft__arrow, { [s.MenuLeft__arrowActive]: isOpen.value })}
          />
          {isOpen.value && <span className={s.MenuLeft__text}>Свернуть</span>}
        </div>

        {showChoosenItems()}
      </div>
    </div>
  );
};
