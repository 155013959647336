import React from 'react';
import clsx from 'clsx';

import { User } from 'routes/lmsAds/_temporaryUsersData';

import s from './LMSAdsUser.module.scss';

export type LMSAdsUser = {
  classNameUsername?: string;
  classNameRole?: string;
  className?: string;
};
export const LMSAdsUser: React.FC<LMSAdsUser & User> = ({
  avatar,
  username,
  role,

  classNameUsername,
  classNameRole,
  className
}) => {
  return (
    <div className={clsx(s.LMSAdsUser__user, className)}>
      <div className={s.LMSAdsUser__avatar_wrapper}>
        <img src={avatar} className={s.LMSAdsUser__avatar} alt={username} />
      </div>
      <div>
        <div className={clsx(s.LMSAdsUser__username, classNameUsername)}>{username}</div>
        <div className={clsx(s.LMSAdsUser__role, classNameRole)}>{role}</div>
      </div>
    </div>
  );
};
