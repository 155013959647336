type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function CalendarIcon({ onClick, className, rounded, color }: Props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2V0H8V2H4V0H2V2H0V12H12V2H10ZM10 10H2V5H10V10Z" fill={color || '#F0522B'} />
    </svg>
  );
}
