import React from 'react';
import clsx from 'clsx';

import { EventItem } from 'routes/calendar/_temporaryEventsData';

import { AvatarsSmall } from 'common/components/ui/AvatarsSmall/AvatarsSmall';
import { Icon } from 'common/components/ui/Icons/Icon';
import s from './CalendarModal.module.scss';

type Own = {
  style?: React.CSSProperties;
  visible: boolean;
  toggle: () => void;
  toggleNotification: () => void;
  toggleParticipants: () => void;
};
export type CalendarModalProps = Own & EventItem;
export const CalendarModal = React.forwardRef<HTMLDivElement, CalendarModalProps>(
  (
    {
      style,
      toggle,
      visible,
      author,
      cabinet,
      title,
      participantsAvatars,
      allParticipants,
      toggleNotification,
      toggleParticipants
    },
    ref
  ) => {
    return (
      <div ref={ref} className={clsx(s.CalendarModal__wrapper, { [s.CalendarModal_show]: visible })} style={style}>
        <div className={s.CalendarModal__header}>
          <span className={s.CalendarModal__cabinet}>{cabinet}</span>
          <Icon onClick={toggle} type="close" color="#5C617C" className={s.CalendarModal__close} />
        </div>
        <div className={s.CalendarModal__title}>{title}</div>
        <div className={s.CalendarModal__actions}>
          <div className={s.CalendarModal__tag}>{author}</div>
          <div onClick={toggleParticipants} className={clsx(s.CalendarModal__tag, s.CalendarModal__participants)}>
            Участники: <AvatarsSmall avatars={participantsAvatars} /> +{allParticipants}
          </div>
        </div>
        <div onClick={toggleNotification} className={s.CalendarModal_link_wrapper}>
          <span className={s.CalendarModal_link}>Подключиться</span>
        </div>
      </div>
    );
  }
);
