import { Option } from 'react-select';

import { Card } from 'common/components/ui/Card/Card';
import { FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { CloseIcon2 } from 'common/components/ui/Icons';
import { FileIcon2 } from 'common/components/ui/Icons/FileIcon2';

import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import s from './Enrollment1Info2.module.scss';

type Props = {
  conditions_of_admission: { value: string; label: string };
  conditions_of_admissions: Array<{ value: string; label: string }>;
  setFieldValue: any;
};
export const Enrollment1Info2: React.FC<Props> = ({
  conditions_of_admission,
  conditions_of_admissions,
  setFieldValue
}) => {
  return (
    <Card className={s.Enrollment1Info2}>
      <div className={s.Enrollment1Info2__title}>
        Единый конкурс на образовательные программы “Экономика” и “Совместная программа по экономике НИУ ВШЭ и РЭШ”
        (Бюджет)
      </div>
      <div className={s.Enrollment1Info2__row}>
        <FieldTitle title="Условия поступления" />
        <Select
          onChange={(option: Option) => setFieldValue('conditions_of_admission', option)}
          name="conditions_of_admission"
          value={conditions_of_admission}
          options={conditions_of_admissions}
          className={s.Enrollment1Info2__select}
          size={FieldSize.Small}
        />
      </div>
      <div className={s.Enrollment1Info2__row}>
        <FieldTitle title="Заявление о согласии на зачисление" />
        <div className={s.Enrollment1Info2__statement}>
          <FileIcon2 />
          <span className={s.Enrollment1Info2__statement_text}>Заявление о согласии на зачислении.pdf</span>
          <CloseIcon2 color="white" className={s.Enrollment1Info2__icon_close} />
        </div>
      </div>
    </Card>
  );
};
