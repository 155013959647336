import clsx from 'clsx';

import { Card } from '../../../../../common/components/ui/Card/Card';
import { TestTaskForm } from './TestTaskForm';
import { TestTaskImg } from './TestTaskImg';
import s from './TestTask1.module.scss';
import s2 from '../TestTask.module.scss';

type Props = {
  setItem: (number: number) => void;
};
export const TestTask1: React.FC<Props> = ({ setItem }) => {
  return (
    <div className={s.TestTask1}>
      <Card className={s.TestTask1__card}>
        <TestTaskImg />
        <div className={clsx(s2.TestTask__title, s.TestTask1__card_title)}>Заявка принята</div>
        <p className={s.TestTask1__card_desc}>
          Ваше заявление рассмотрено и принято! Запишитесь на испытательные задания по выбранным направлениям
        </p>
      </Card>
      <div className={clsx(s2.TestTask__title, s.TestTask1__title)}>Запись на испытательные испытания</div>
      <TestTaskForm setItem={setItem} />
    </div>
  );
};
