export enum AppColor {
  Purple = '#605BFF',
  Green = '#53C120',
  Pink = '#EE61E0',
  Yellow = '#FFDA18',
  Red = '#CF314C',
  Blue = '#1ABCDF',
  BlueLight = '#BBCAFF',
  Dark = '#5C617C',
  Tertiary = '#B3BACB',
  White = '#fff'
}

export const chosenUniversityAdmission = localStorage.getItem('university-admission');
