import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDropdown } from '../../common/hooks/useDropdown';
import { TopBar } from '../../common/components/ui/TopBar/TopBar';
import { Footer } from '../../common/components/ui/Footer/Footer';
import student from '../../assets/student.png';
import logoIcon from '../../assets/icon.png';
import employee from '../../assets/employee.png';
import { Logo } from '../../common/components/ui/Icons/Logo';

import { ReactComponent as NotificationsIcon } from './notifications.svg';
import { ReactComponent as ChevronIcon } from './chevron.svg';
import s from './UserLayout.module.scss';

export function withUserLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    const [dropdown, setDropdown] = useDropdown('user-dropdown');
    const isStudent = useRouteMatch('/student');

    useEffect(() => {
      const className = document.body.className;
      document.body.className = clsx(className, s.Body);
      return () => {
        document.body.className = className;
      };
    }, []);

    return (
      <React.Fragment>
        <TopBar
          logoLarge={<Logo />}
          logoSmall={<img src={logoIcon} alt={'Цифровой университет'} />}
          slotRight={
            <React.Fragment>
              <NotificationsIcon className={s.TopBar__notifications} />
              <div className={s.TopBar__userDropdown}>
                <button
                  className={clsx(s.TopBar__userDropdownButton, { [s.TopBar__userDropdownButton_active]: dropdown })}
                  onClick={() => setDropdown(true)}>
                  <img className={s.TopBar__userPhoto} src={isStudent ? student : employee} alt={''} />
                  <ChevronIcon />
                </button>
                {dropdown && (
                  <div id={'user-dropdown'} className={s.TopBar__dropdownMenu}>
                    <div className={s.TopBar__dropdownMenuItem}>Настройки</div>
                    <div className={s.TopBar__dropdownMenuItem}>Профиль</div>
                    <Link className={s.TopBar__dropdownMenuItem} to={'/'}>
                      Выйти
                    </Link>
                  </div>
                )}
              </div>
            </React.Fragment>
          }
        />
        <Comp {...props} />
        <Footer />
      </React.Fragment>
    );
  };
}
