type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function MonitorIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path
        d="M5 18L20 18C20.8284 18 21.5 17.3284 21.5 16.5V6C21.5 5.17157 20.8284 4.5 20 4.5L5 4.5C4.17157 4.5 3.5 5.17157 3.5 6V16.5C3.5 17.3284 4.17157 18 5 18Z"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 21H9.5"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 14.25H21.5"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18V21"
        stroke={color || '#9FA9C1'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
