import { Option } from 'react-select';
import { useFormik } from 'formik';


import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Input } from 'common/components/ui/Forms/Input/Input';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Documents } from 'common/components/ui/Documents/Documents';
import { Button, ButtonColor, ButtonSize, ButtonType } from 'common/components/ui/Button/Button';
import s from './ApplicantAgreementsConditions.module.scss';

const program_type = [{ value: 'bachelor_of_RF', label: 'Бакалавриат РФ' }];
const programs = [{ value: 'program_1', label: 'М 46 04 01 П СГ П 2020 очная Цифровые методы в гуманитарных науках' }];
const directions = [{ value: 'direction_1', label: '46 04 01 История' }];
const faculties_of_education = [
  { value: 'social_and_humanitarian_faculty', label: 'Социально-гуманитарный факультет' }
];

const documents = [
  { text: 'Заявка на заключение договора.pdf', to: '/' },
  { text: 'Договор об образовании.pdf', to: '/' }
];

export const ApplicantAgreementsConditions = () => {
  const initialValues = {
    program_type: program_type[0],
    program: programs[0],
    directions: directions[0],
    form_of_study: '',
    duration: '2',
    place_of_study: 'Пермь',
    faculty_of_education: faculties_of_education[0]
  };
  const formik = useFormik({ initialValues, onSubmit: () => {} });

  const items = [
    {
      name: 'program_type',
      label: 'Вид программы',
      select: { options: program_type, value: formik.values.program_type }
    },
    {
      name: 'program',
      label: 'Программа',
      value: formik.values.program,
      select: { options: programs }
    },
    {
      name: 'directions',
      label: 'Направления',
      value: formik.values.directions,
      select: { options: directions }
    },
    {
      name: 'form_of_study',
      label: 'Форма обучения',
      input: { value: formik.values.form_of_study }
    },
    {
      name: 'duration',
      label: 'Продолжительность',
      input: { value: formik.values.duration }
    },
    {
      name: 'place_of_study',
      label: 'Место обучения',
      input: { value: formik.values.place_of_study }
    },
    {
      name: 'faculty_of_education',
      label: 'Факультет обучения',
      select: { options: faculties_of_education }
    }
  ];
  const basicInputProps = {
    isCopy: true,
    size: FieldSize.Small,
    className: s.ApplicantAgreementsConditions__item
  };
  const basicButtonProps = { size: ButtonSize.Small, className: s.ApplicantAgreementsConditions__action };

  const showItems = items.map(({ name, label, select, input }) => (
    <Field flex className={s.ApplicantAgreementsConditions__field}>
      <FieldTitle title={label} flex />
      {select ? (
        <Select
          onChange={(option: Option) => formik.setFieldValue(name, option)}
          name={name}
          value={select.value}
          options={select.options}
          {...basicInputProps}
        />
      ) : input ? (
        <Input onChange={formik.handleChange} name={name} value={input.value} {...basicInputProps} />
      ) : null}
    </Field>
  ));

  return (
    <div>
      <div className={s.ApplicantAgreementsConditions__items}>
        {showItems}
        <Field flex className={s.ApplicantAgreementsConditions__field}>
          <FieldTitle title="Заявка" flex />
          <span>Одобрена</span>
        </Field>
        <Field flex>
          <div />
          <Documents items={documents} />
        </Field>
      </div>
      <div className={s.ApplicantAgreementsConditions__actions}>
        <Button btnType={ButtonType.Outlined} {...basicButtonProps}>
          Печать
        </Button>
        <div className={s.ApplicantAgreementsConditions__actionsGroup}>
          <Button iconStartType="check-2" {...basicButtonProps} color={ButtonColor.Success}>
            Отправить
          </Button>
          <Button iconStartType="close" {...basicButtonProps} color={ButtonColor.Error}>
            Отклонить
          </Button>
        </div>
      </div>
    </div>
  );
};
