import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function WriteIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 16L9.99886 3.995L3.99503 15.9987"
          stroke={color || '#9D57D3'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.19553 13.5976H14.7969"
          stroke={color || '#9D57D3'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4975 27.5755L27.5742 19.4988C28.1478 18.9252 28.1478 17.9968 27.5742 17.4246L25.2465 15.0969C24.673 14.5234 23.7446 14.5234 23.1723 15.0969L15.0956 23.1736C14.8195 23.4497 14.6661 23.8219 14.6661 24.2101V28.005H18.4611C18.8492 28.005 19.2214 27.8516 19.4975 27.5755Z"
          stroke={color || '#9D57D3'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
