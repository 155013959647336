import { useState } from 'react';

import { TestTask1 } from './TestTask1/TestTask1';
import { TestTask2 } from './TestTask2/TestTask2';
import { TestTask3 } from './TestTask3/TestTask3';

type Props = {
  setChosenProgress: (number: number) => void;
};
export const TestTask: React.FC<Props> = ({ setChosenProgress }) => {
  const [item, setItem] = useState(0);

  return (
    <>
      {item === 0 && <TestTask1 setItem={setItem} />}
      {item === 1 && <TestTask2 setItem={setItem} setChosenProgress={setChosenProgress} />}
      {item === 2 && <TestTask3 setItem={setItem} setChosenProgress={setChosenProgress} />}
    </>
  );
};
