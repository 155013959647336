import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Helmet } from 'react-helmet';
import { EMDASH } from '@proscom/ui-utils';
import AppRoutes from './routes';

moment.locale('ru');

const siteTitle = 'E-Platform';

export function App() {
  return (
    <Fragment>
      <Helmet defaultTitle={siteTitle} titleTemplate={`%s ${EMDASH} ${siteTitle}`} />
      <AppRoutes />
    </Fragment>
  );
}
