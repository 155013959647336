import { useState } from 'react';

// common components
import { AdmissionsOfficer } from 'common/components/ui/AdmissionsOfficer/AdmissionsOfficer';
import { ButtonType } from 'common/components/ui/Button/Button';
import { TableItem, TableRow } from 'common/components/ui/Table/Table';

// components for this page
import { useHistory } from 'react-router';
import { AdmissionsOfficerFilter } from './AdmissionsOfficerFilrer';

// components for card
import { ApplicantAgreementsOverview } from './cardItems/ApplicantAgreementsOverview';
import { ApplicantAgreementsSolution } from './cardItems/ApplicantAgreementsSolution';
import { ApplicantAgreementsConditions } from './cardItems/ApplicantAgreementsConditions';
import { ApplicantAgreementsCostOfEducation } from './cardItems/ApplicantAgreementsCostOfEducation';
import { ApplicantAgreementsApprovalSheet } from './cardItems/ApplicantAgreementsApprovalSheet';
import { ApplicantAgreementsMailingList } from './cardItems/ApplicantAgreementsMailingList';
import { ApplicantAgreementsOriginals } from './cardItems/ApplicantAgreementsOriginals';
import { ApplicantAgreementsTies } from './cardItems/ApplicantAgreementsTies';
import { ApplicantAgreementsEP } from './cardItems/ApplicantAgreementsEP';
import { ApplicantAgreementsCategories } from './cardItems/ApplicantAgreementsCategories';
import { ApplicantAgreementsAccess } from './cardItems/ApplicantAgreementsAccess';
import s from './ApplicantAgreements.module.scss';

const buttonActions = [
  { title: 'Сформировать', btnType: ButtonType.Default },
  { title: 'Настройки', btnType: ButtonType.Outlined },
  { title: 'Найти', btnType: ButtonType.Outlined },
  { iconStartType: 'pencil', btnType: ButtonType.Outlined, width: 32, height: 32 },
  { title: 'Согласование', iconEndType: 'arrow-2', iconColor: '#605BFF', btnType: ButtonType.Outlined }
];
const cardActionsLeft = [
  { title: 'Записать', btnType: ButtonType.Outlined },
  { iconStartType: 'pencil', btnType: ButtonType.Outlined, width: 32, height: 32 },
  { title: 'Согласование', iconEndType: 'arrow-2', iconColor: '#605BFF', btnType: ButtonType.Outlined }
];
const cardActionsRight = [
  { title: 'Печать', btnType: ButtonType.Outlined },
  { title: 'Еще', iconEndType: 'arrow-2', iconColor: '#605BFF', btnType: ButtonType.Outlined }
];
const tableTitles = ['Документ', 'Внутренний номер'];
const tableItems = [
  {
    id: '1',
    document: 'Договор с абитуриентом Андронов Сергей Олегович ( 2.9-31 1.03.1 210521-3 от 25.05.2021)',
    extension_number: 'М 22/4/2021-24'
  },
  {
    id: '2',
    document: 'Договор с абитуриентом Андронов Сергей Олегович ( 2.9-31 1.03.1 210521-3 от 25.05.2021)',
    extension_number: 'М 22/4/2021-24'
  }
];

export const ApplicantAgreements = () => {
  const history = useHistory();

  const [isApplicationCard, setIsApplicationCard] = useState(false);

  const [isOverview, setIsOverview] = useState(false);
  const [isSolution, setIsSolution] = useState(false);
  const [isConditions, setIsConditions] = useState(false);
  const [isCostOfEducation, setIsCostOfEducation] = useState(false);
  const [isApprovalSheet, setIsApprovalSheet] = useState(false);
  const [isMailingList, setIsMailingList] = useState(false);
  const [isOriginals, setIsOriginals] = useState(false);
  const [isTies, setIsTies] = useState(false);
  const [isEP, setIsEP] = useState(false);
  const [isCategories, setIsCategories] = useState(false);
  const [isAccess, setIsAccess] = useState(false);

  const cardItems = [
    { title: 'Обзор', setVal: setIsOverview, active: isOverview, children: <ApplicantAgreementsOverview /> },
    { title: 'Решение', setVal: setIsSolution, active: isSolution, children: <ApplicantAgreementsSolution /> },
    { title: 'Условия', setVal: setIsConditions, active: isConditions, children: <ApplicantAgreementsConditions /> },
    {
      title: 'Стоимость обучения',
      setVal: setIsCostOfEducation,
      active: isCostOfEducation,
      children: <ApplicantAgreementsCostOfEducation />
    },
    {
      title: 'Лист согласования',
      setVal: setIsApprovalSheet,
      active: isApprovalSheet,
      children: <ApplicantAgreementsApprovalSheet />
    },
    {
      title: 'Лист рассылки',
      setVal: setIsMailingList,
      active: isMailingList,
      children: <ApplicantAgreementsMailingList />
    },
    {
      title: 'Подлинники',
      setVal: setIsOriginals,
      active: isOriginals,
      children: <ApplicantAgreementsOriginals />
    },
    {
      title: 'Связи (1)',
      setVal: setIsTies,
      active: isTies,
      children: <ApplicantAgreementsTies />
    },
    {
      title: 'ЭП (1)',
      setVal: setIsEP,
      active: isEP,
      children: <ApplicantAgreementsEP />
    },
    {
      title: 'Категории',
      setVal: setIsCategories,
      active: isCategories,
      children: <ApplicantAgreementsCategories />
    },
    {
      title: 'Доступ (5)',
      setVal: setIsAccess,
      active: isAccess,
      children: <ApplicantAgreementsAccess />
    }
  ];

  const tableChildren = tableItems.map((item) => {
    const showSubItems = Object.entries(item).map(
      ([key, value], index) =>
        key !== 'id' && (
          <TableItem key={index} className={s.ApplicantAgreements__table__item}>
            <div className={s.Applicantagreements__table__value}>{value}</div>
          </TableItem>
        )
    );

    return (
      <TableRow onClick={() => setIsApplicationCard(true)} key={item.id}>
        {showSubItems}
      </TableRow>
    );
  });

  const onNotification = () => {
    localStorage.setItem('university-admission', '5');
    localStorage.setItem('university-admission-last', '3');
    history.push('/university-admission');
    window.location.reload();
  };

  return (
    <AdmissionsOfficer
      title="Договоры абитуриентов"
      actionsLeft={buttonActions}
      cardActionsLeft={cardActionsLeft}
      cardActionsRight={cardActionsRight}
      cardTitle="Договор с абитуриентом Шабрин Григорий (21111 / 8.2.6.5 от 07.06.2021)"
      filterChildren={<AdmissionsOfficerFilter />}
      tableTitles={tableTitles}
      tableChildren={tableChildren}
      cardItems={cardItems}
      isCard={isApplicationCard}
      setCard={setIsApplicationCard}
      onNotification={onNotification}
    />
  );
};
