import clsx from 'clsx';
import React from 'react';
import s from './ServiceGrid.module.scss';

export interface IServiceGridProps {
  children: React.ReactNode[];
  className?: string;
}

export function ServiceGrid({ children, className }: IServiceGridProps) {
  return <div className={clsx(s.ServiceGrid, className)}>{children}</div>;
}
