import { useState } from 'react';

import { AppColor } from 'store/app';

import { Icon } from 'common/components/ui/Icons/Icon';
import { Filter2 } from 'common/components/ui/Filter2/Filter2';
import moment from 'moment';
import { CalendarType } from './Calendar';
import s from './CalendarHeader.module.scss';

export type CalendarHeaderProps = {
  days: Array<any>;
  today: moment.Moment;
  beforeHandler: () => void;
  todayHandler: () => void;
  nextHandler: () => void;
  onChangeCalendarType: (newDays: CalendarType) => void;
  isMonth: boolean;
};
export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  days,
  today,
  beforeHandler,
  nextHandler,
  onChangeCalendarType,
  isMonth
}) => {
  const [chosenFilterItem, setChosenFilterItem] = useState('week');
  const startDay = days[0];
  const endDay = days[days.length - 1];

  const filterItems = [
    { slug: 'week', text: 'Учебная неделя', onClick: () => onChangeCalendarType(CalendarType.Week) },
    { slug: 'Month', text: 'Месяц', onClick: () => onChangeCalendarType(CalendarType.Month) }
  ];

  const baseIconProps = {
    type: 'arrow-2',
    className: s.CalendarHeader__arrow,
    color: AppColor.Purple,
    fillColor: AppColor.Purple
  };

  return (
    <div className={s.CalendarHeader}>
      <div className={s.CalendarHeader_colFirst}>
        <div className={s.CalendarHeader__title}>
          {!isMonth ? (
            <>
              Четная неделя, {moment(startDay).format('D MMM')} - {moment(endDay).format('D MMM')}
            </>
          ) : (
            <>
              <span style={{ textTransform: 'capitalize' }}>{today.format('MMMM YYYY')}</span> {today.calendar()}{' '}
              {today.format('D MMMM')}
            </>
          )}
        </div>

        <div className={s.CalendarHeader__arrows}>
          <Icon onClick={beforeHandler} {...baseIconProps} />
          <Icon onClick={nextHandler} {...baseIconProps} />
        </div>
      </div>
      <Filter2
        items={filterItems}
        setChosenItem={setChosenFilterItem}
        chosenItem={chosenFilterItem}
        className={s.Enrollment3_filter2}
      />
    </div>
  );
};
