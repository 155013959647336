import clsx from 'clsx';
import React from 'react';
import { Button } from '../../Button/Button';
import { Input } from '../Input/Input';
import { Icon } from '../../Icons/Icon';
import s from './SearchInput.module.scss';

export interface ISearchInputProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  classNameWrapper?: string;
  isButton?: boolean;
}

export function SearchInput({ value, onChange, className, classNameWrapper, isButton }: ISearchInputProps) {
  return (
    <div className={clsx(s.SearchInput__wrapper, classNameWrapper)}>
      <div className={clsx(s.SearchInput, className)}>
        <Icon type="search" />
        <input className={s.SearchInput__input} value={value} onChange={onChange} placeholder={'Поиск по сервисам'} />
      </div>
      {isButton && <Button className={s.SearchInput__button}>Найти</Button>}
    </div>
  );
}
