import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function PeopleInShapeIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.1222 10.2096C19.2947 11.3821 19.2947 13.2815 18.1222 14.454C16.9497 15.6265 15.0503 15.6265 13.8778 14.454C12.7053 13.2815 12.7053 11.3821 13.8778 10.2096C15.0503 9.03708 16.9497 9.03708 18.1222 10.2096"
          stroke={color || '#FF9BE9'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7637 22.0572C21.4689 20.2044 19.8763 18.7852 17.9421 18.7852H14.0579C12.1224 18.7852 10.5311 20.2044 10.2363 22.0572"
          stroke={color || '#FF9BE9'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6694 28.005H9.33055C6.38399 28.005 3.995 25.616 3.995 22.6694V9.33055C3.995 6.38399 6.38399 3.995 9.33055 3.995H22.6694C25.616 3.995 28.005 6.38399 28.005 9.33055V22.6694C28.005 25.616 25.616 28.005 22.6694 28.005Z"
          stroke={color || '#FF9BE9'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
