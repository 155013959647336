import clsx from 'clsx';
import React from 'react';

import s from './Title.module.scss';

type Props = {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  tag?: 'h1' | 'h2';
};
export const Title: React.FC<Props> = ({ title, children, className, tag = 'h1' }) => {
  return (
    <div className={clsx(s.Title__wrapper, className)}>
      <div
        className={clsx(s.Title, {
          [s.Title_h1]: tag === 'h1',
          [s.Title_h2]: tag === 'h2'
        })}>
        {title}
      </div>
      {children}
    </div>
  );
};
