import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  height?: number;
  color?: string;
};

export function ConfederateIcon({ onClick, className, height, color }: Props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <g clipPath="url(#clip0)">
        <path
          d="M1.25 9L12.5 3L23.75 9L12.5 15L1.25 9Z"
          stroke={color || '#9FA9C1'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.125 22.5V12L12.5 9"
          stroke={color || '#9FA9C1'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.125 10.3999V15.5112C21.1253 15.673 21.073 15.8305 20.976 15.96C20.3444 16.8004 17.68 19.8749 12.5 19.8749C7.32004 19.8749 4.65558 16.8004 4.02402 15.96C3.92699 15.8305 3.87469 15.673 3.875 15.5112V10.3999"
          stroke={color || '#9FA9C1'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
