import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function HistoryIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5 7.5V12"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3971 14.25L12.5 12"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.23437 9.34839H3.48438V5.59839"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66635 17.8336C7.82014 18.9874 9.29015 19.7732 10.8905 20.0915C12.4908 20.4098 14.1496 20.2464 15.6571 19.622C17.1646 18.9976 18.4531 17.9402 19.3596 16.5835C20.2661 15.2268 20.75 13.6317 20.75 12C20.75 10.3683 20.2661 8.77325 19.3596 7.41655C18.4531 6.05984 17.1646 5.00242 15.6571 4.378C14.1496 3.75357 12.4908 3.5902 10.8905 3.90853C9.29015 4.22685 7.82014 5.01259 6.66635 6.16637L3.48438 9.34835"
          stroke={color || '#5C617C'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
