import clsx from 'clsx';
import React from 'react';
import s from './DashboardCard.module.scss';

export interface IDashboardCardProps {
  title: string;
  slotRight?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

export function DashboardCard({ title, slotRight, children, className }: IDashboardCardProps) {
  return (
    <article className={clsx(s.DashboardCard, className)}>
      <div className={s.DashboardCard__header}>
        <h3 className={s.DashboardCard__title}>{title}</h3>
        {slotRight}
      </div>
      {children}
    </article>
  );
}
