import clsx from 'clsx';
import color from 'color';
import React from 'react';
import { ITag } from '../../../../routes/services/_temporaryServicesData';
import s from './Tags.module.scss';

export interface ITagsProps {
  tags: ITag[];
  className?: string;
}

export function Tags({ tags, className }: ITagsProps) {
  return (
    <div className={clsx(s.Tags, className)}>
      {tags.map((tag) => (
        <Tag key={tag.title} color={tag.color} title={tag.title} />
      ))}
    </div>
  );
}

type TagProps = {
  children?: React.ReactNode;
  className?: string;
};
export const Tag: React.FC<TagProps & ITag> = ({ children, color: tagColor, backgroundColor, title, className }) => {
  return (
    <div
      className={clsx(s.Tags__tag, className)}
      style={{
        color: tagColor,
        backgroundColor: backgroundColor ? backgroundColor : color(tagColor).alpha(0.1).toString()
      }}>
      {children || title}
    </div>
  );
};
