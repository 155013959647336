import { useState } from 'react';
import clsx from 'clsx';

import { Card } from 'common/components/ui/Card/Card';
import { CardWithShortContent } from './CardWithShortContent';
import s from './CardWithShortContentList.module.scss';

type Props = {
  items: Array<{
    id: string;
    smallText: string;
    title: string;
  }>;
};
export const CardWithShortContentList: React.FC<Props> = ({ items }) => {
  const [chosenItem, setChosenItem] = useState<string | null>(null);

  const showItems = items.map((item) => (
    <CardWithShortContent
      {...item}
      key={item.id}
      onClick={() => setChosenItem(item.id)}
      active={chosenItem === item.id}
    />
  ));

  return <div className={s.CardWithShortContentList}>{showItems}</div>;
};
