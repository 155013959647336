import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
  width?: number;
  height?: number;
};

export function CloseIcon({ onClick, className, rounded, color, width, height }: Props) {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: `${height}px` }}>
      <path d="M15 5L5 15" stroke={color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 5L15 15" stroke={color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
