import clsx from 'clsx';
import React from 'react';

import { Message } from 'routes/lmsMessages/_temporaryDialogData';

import { Icon } from 'common/components/ui/Icons/Icon';
import { ReactComponent as WriteIcon } from '../assets/write.svg';

import s from './LMSMessage.module.scss';

export type LMSMessage = {
  isOnline: boolean;
  isWrite: boolean;
  isMyMessage: boolean;
  className?: string;
};
export const LMSMessage: React.FC<LMSMessage & Message> = ({
  isOnline,
  isWrite,
  avatar,
  text,
  isMyMessage,
  className
}) => {
  return (
    <div className={clsx(s.LMSMessage__wrapper, { [s.LMSMessage__wrapper_me]: isMyMessage }, className)}>
      <div className={s.LMSMessage__avatar_wrapper}>
        <div className={s.LMSMessage__avatar_container}>
          <div className={clsx(s.LMSMessage__online, { [s.LMSMessage__online_active]: isOnline })} />
          <img src={avatar} alt="avatar" className={s.LMSMessage__avatar} />
        </div>
      </div>
      {!isWrite ? (
        <>
          <div className={s.LMSMessage__message}>
            <p className={s.LMSMessage__message_text}>{text}</p>
          </div>
          <Icon type="menu-circle" className={s.LMSMessage__menu} color={'#B7BDCB'} />
        </>
      ) : (
        <div className={s.LMSMessage__write}>
          <WriteIcon className={s.LMSMessage__write_icon} />
          Пишет
        </div>
      )}
    </div>
  );
};
