import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function FileInShapeIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7527 11.861L18.6571 9.76541C18.3797 9.48663 18.0022 9.33057 17.61 9.33057H12.2945C11.4755 9.33057 10.8125 9.99351 10.8125 10.8125V21.1875C10.8125 22.0065 11.4755 22.6695 12.2945 22.6695H19.7056C20.5246 22.6695 21.1875 22.0065 21.1875 21.1875V12.9081C21.1875 12.5159 21.0315 12.1384 20.7527 11.861Z"
          stroke={color || '#F43F5E'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1875 13.6657H17.7194C17.2405 13.6657 16.8524 13.2775 16.8524 12.7987V9.33057"
          stroke={color || '#F43F5E'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6695 28.0051H9.33058C6.38402 28.0051 3.99503 25.6161 3.99503 22.6696V9.33067C3.99503 6.38411 6.38402 3.99512 9.33058 3.99512H22.6695C25.616 3.99512 28.005 6.38411 28.005 9.33067V22.6696C28.005 25.6161 25.616 28.0051 22.6695 28.0051Z"
          stroke={color || '#F43F5E'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
