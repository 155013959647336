import { Button, ButtonType } from 'common/components/ui/Button/Button';
import React from 'react';
import s from './AdmissionsOfficerActions.module.scss';

export type AdmissionsOfficerAction = {
  title?: string;
  iconStartType?: string;
  iconEndType?: string;
  iconColor?: string;
  btnType?: ButtonType;
  width?: number;
  height?: number;
};
type AdmissionsOfficerActionsProps = {
  actionsLeft: Array<AdmissionsOfficerAction>;
  actionsRight?: Array<AdmissionsOfficerAction>;
};
export const AdmissionsOfficerActions: React.FC<AdmissionsOfficerActionsProps> = ({ actionsLeft, actionsRight }) => {
  const showItems = (items: Array<AdmissionsOfficerAction>) => {
    return items.map(({ iconStartType, iconEndType, title, iconColor, btnType, width, height }) => (
      <Button
        iconStartType={iconStartType}
        iconEndType={iconEndType}
        iconColor={iconColor}
        btnType={btnType}
        className={s.AdmissionsOfficerActions__action}
        style={{ width: `${width}px`, height: `${height}px` }}>
        {title && title}
      </Button>
    ));
  };
  const showItemsLeft = showItems(actionsLeft);
  const showItemsRight = actionsRight && showItems(actionsRight);

  return (
    <div className={s.AdmissionsOfficerActions}>
      <div className={s.AdmissionsOfficerActions__left}>{showItemsLeft}</div>
      <div className={s.AdmissionsOfficerActions__right}>{showItemsRight}</div>
    </div>
  );
};
