import { useState } from 'react';

import React from 'react';
import { Filter } from '../Filter/Filter';
import { Table } from '../Table/Table';
import { AdmissionsOfficerAction, AdmissionsOfficerActions } from './components/AdmissionsOfficerActions';
import { AdmissionsOfficerHeader } from './components/AdmissionsOfficerHeader';
import { AdmissionsOfficerCard } from './components/AdmissionsOfficerCard/AdmissionsOfficerCard';
import { AdmissionsOfficerCardItem } from './components/AdmissionsOfficerCard/AdmissionsOfficerCardItem/AdmissionsOfficerCardItem';
import s from './AdmissionsOfficer.module.scss';

type Props = {
  title: string;
  actionsLeft: Array<AdmissionsOfficerAction>;
  actionsRight?: Array<AdmissionsOfficerAction>;
  cardActionsLeft: Array<AdmissionsOfficerAction>;
  cardActionsRight?: Array<AdmissionsOfficerAction>;
  cardTitle: string;
  filterChildren: React.ReactNode;
  tableTitles: Array<string>;
  tableChildren: React.ReactNode;
  cardItems: Array<AdmissionsOfficerCardItem>;
  cardTopChildren?: React.ReactNode;
  isCard?: boolean;
  setCard: (isCard: boolean) => void;
  onNotification: () => void;
};
export const AdmissionsOfficer: React.FC<Props> = ({
  title,
  actionsLeft,
  actionsRight,
  cardActionsLeft,
  cardActionsRight,
  cardTitle,
  filterChildren,
  tableTitles,
  tableChildren,
  cardItems,
  cardTopChildren,
  isCard,
  setCard,
  onNotification
}) => {
  console.log('filterChildren', filterChildren === true);

  return (
    <div className={s.AdmissionsOfficer__wrapper}>
      <AdmissionsOfficerHeader title={title} />
      <AdmissionsOfficerActions actionsLeft={actionsLeft} actionsRight={actionsRight} />
      <Filter className={s.AdmissionsOfficer__filter}>{filterChildren}</Filter>
      <Table titles={tableTitles}>{tableChildren}</Table>
      {isCard && (
        <AdmissionsOfficerCard
          visible={isCard}
          setIsApplicationCard={setCard}
          actionsLeft={cardActionsLeft}
          actionsRight={cardActionsRight}
          cardTitle={cardTitle}
          cardItems={cardItems}
          cardTopChildren={cardTopChildren}
          onNotification={onNotification}
        />
      )}
    </div>
  );
};
