import React from 'react';

import { AuthCard } from '../../../../common/components/ui/AuthCard/AuthCard';
import s from './FilingAnApplication1.module.scss';

type Props = {
  setChosenStep: (chosenStep: string) => void;
};
export const FilingAnApplication1: React.FC<Props> = ({ setChosenStep }) => {
  const handleSubmit = () => {
    setChosenStep('second');
  };

  return (
    <div className={s.FilingAnApplication__wrapper}>
      <AuthCard handleSubmit={handleSubmit} setChosenStep={setChosenStep} isLogin />
    </div>
  );
};
