import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Icon } from 'common/components/ui/Icons/Icon';
import s from './Enrollment4Documents.module.scss';

export const Enrollment4DocumentsData = [
  { text: 'Договор.pdf', to: '/' },
  { text: 'Приложение.pdf', to: '/' },
  { text: 'Комплект договоров (версий для печати со штампами)', to: '/' },
  { text: 'Комплект договоров (электронная версия с файлом электронной подписи)', to: '/' },
  { text: 'Комплект приложений (версий для печати со штампами)', to: '/' },
  { text: 'Комплект приложений (электронная версия с файлом электронной подписи)', to: '/' }
];

export type Enrollment4Document = {
  text: string;
  to: string;
};
type Props = {
  items: Array<Enrollment4Document>;
  className?: string;
};
export const Enrollment4Documents: React.FC<Props> = ({ items, className }) => {
  const showItems = items.map(({ text, to }) => (
    <Link to={to} key={text} className={s.Enrollment4Documents__document}>
      <Icon type="clip-2" className={s.Enrollment4Documents__icon} color="#605BFF" /> {text}
    </Link>
  ));

  return <div className={clsx(s.Enrollment4Documents, className)}>{showItems}</div>;
};
