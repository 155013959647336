import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { LandingBox } from '../../common/components/ui/Box/Box';
import { SearchInput } from '../../common/components/ui/Forms/SearchInput/SearchInput';
import { Actions } from './components/Actions';
import { MySchedule } from './components/MySchedule';
import { Services } from './components/Services';
import { News } from './components/News';
import s from './SubRoutePage.module.scss';

export default function SubRoutePage() {
  const [searchVal, setSearchVal] = useState('');

  const handleChangeSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <LandingBox className={s.SubRoutePage__wrapper}>
      <Helmet title={'Сервисы'} />
      <SearchInput onChange={handleChangeSearchVal} value={searchVal} className={s.SubRoutePage__search} isButton />
      <div className={s.SubRoutePage__item}>
        <Actions />
      </div>
      <div className={s.SubRoutePage__item}>
        <MySchedule />
      </div>
      <div className={s.SubRoutePage__item}>
        <Services />
      </div>
      <div className={s.SubRoutePage__item}>
        <News />
      </div>
    </LandingBox>
  );
}
