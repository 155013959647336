import { useState } from 'react';

import { ButtonLink, ButtonType } from 'common/components/ui/Button/Button';
import { Notification } from 'common/components/ui/Notification/Notification';
import { EnrollmentDropdown, EnrollmentDropdownStatus } from '../EnrollmentDropdown/EnrollmentDropdown';
import { Enrollment4ApplicationForTheConclusionOfAContract } from './Enrollment4ApplicationForTheConclusionOfAContract';
import { Enrollment4ContendForCompleted } from './Enrollment4ContendForCompleted';
import { Enrollment4ContractAndOtherDocumentsForTraining } from './Enrollment4ContractAndOtherDocumentsForTraining';
import { Enrollment4PaymentOfTheFirstTuitionFee } from './Enrollment4PaymentOfTheFirstTuitionFee';
import { Enrollment4SigningAContractByAnApplicant } from './Enrollment4SigningAContractByAnApplicant';
import s from './Enrollment4.module.scss';

type Props = {
  setChosenItem: (number: number) => void;
};
export const Enrollment4: React.FC<Props> = ({ setChosenItem }) => {
  const [isNotification, setIsNotification] = useState(true);

  // useState for dropdown-items
  const [isApplicationForTheConclusionOfAContract, setIsApplicationForTheConclusionOfAContract] = useState(false);
  const [applicationForTheConclusionOfAContractStatus, setApplicationForTheConclusionOfAContractStatus] = useState(
    EnrollmentDropdownStatus.Completed
  );

  const [isSigningAContractByAnApplicant, setIsSigningAContractByAnApplicant] = useState(false);
  const [signingAContractByAnApplicantStatus, setSigningAContractByAnApplicantStatus] = useState(
    EnrollmentDropdownStatus.NeedSign
  );

  const [isPaymentOfTheFirstTuitionFee, setIsPaymentOfTheFirstTuitionFee] = useState(false);
  const [paymentOfTheFirstTuitionFeeStatus, setPaymentOfTheFirstTuitionFeeStatus] = useState(
    EnrollmentDropdownStatus.NoCompleted
  );

  const [isContractAndOtherDocumentsForTraining, setIsContractAndOtherDocumentsForTraining] = useState(false);
  const [contractAndOtherDocumentsForTrainingStatus, setContractAndOtherDocumentsForTrainingStatus] = useState(
    EnrollmentDropdownStatus.NoCompleted
  );
  // End useState for dropdown-items

  const isCompleted = true;

  const items = [
    {
      title: 'Заявка на заключение договора',
      status: applicationForTheConclusionOfAContractStatus,
      setStatus(status: EnrollmentDropdownStatus.Completed | EnrollmentDropdownStatus.NeedSign) {
        setIsApplicationForTheConclusionOfAContract(false);
        setApplicationForTheConclusionOfAContractStatus(status);
      },
      contentType: 'application_for_the_conclusion_of_a_contract',
      setVal: setIsApplicationForTheConclusionOfAContract,
      active: isApplicationForTheConclusionOfAContract,
      buttons: [
        {
          text: 'Вернуть на доработку',
          onClick: () => {
            setIsApplicationForTheConclusionOfAContract(false);
            setApplicationForTheConclusionOfAContractStatus(EnrollmentDropdownStatus.NeedSign);
          }
        },
        {
          text: 'Подписать',
          onClick: () => {
            setIsApplicationForTheConclusionOfAContract(false);
            setApplicationForTheConclusionOfAContractStatus(EnrollmentDropdownStatus.Completed);
          }
        }
      ]
    },
    {
      title: 'Подписание договора абитуриентом',
      status: signingAContractByAnApplicantStatus,
      setStatus(status: EnrollmentDropdownStatus.Completed | EnrollmentDropdownStatus.NeedSign) {
        setIsSigningAContractByAnApplicant(false);
        setSigningAContractByAnApplicantStatus(status);
      },
      contentType: 'signing_a_contract_by_an_applicant',
      setVal: setIsSigningAContractByAnApplicant,
      active: isSigningAContractByAnApplicant,
      buttons: [
        {
          text: 'Отправить заявку',
          onClick: () => {
            setIsSigningAContractByAnApplicant(false);
            setSigningAContractByAnApplicantStatus(EnrollmentDropdownStatus.Completed);
          }
        }
      ]
    },
    {
      title: 'Оплата первого взноса за обучение',
      status: paymentOfTheFirstTuitionFeeStatus,
      setStatus(status: EnrollmentDropdownStatus.Completed | EnrollmentDropdownStatus.NeedSign) {
        setIsPaymentOfTheFirstTuitionFee(false);
        setPaymentOfTheFirstTuitionFeeStatus(status);
      },
      contentType: 'payment_of_the_first_tuition_fee',
      setVal: setIsPaymentOfTheFirstTuitionFee,
      active: isPaymentOfTheFirstTuitionFee,
      buttons: [
        {
          text: 'Оплатить',
          onClick: () => {
            setIsPaymentOfTheFirstTuitionFee(false);
            setPaymentOfTheFirstTuitionFeeStatus(EnrollmentDropdownStatus.Completed);
          }
        }
      ]
    },
    {
      title: 'Договор и другие документы на обучение',
      status: contractAndOtherDocumentsForTrainingStatus,
      setStatus(status: EnrollmentDropdownStatus.Completed | EnrollmentDropdownStatus.NeedSign) {
        setIsContractAndOtherDocumentsForTraining(false);
        setContractAndOtherDocumentsForTrainingStatus(status);
      },
      contentType: 'contract_and_other_documents_for_training',
      setVal: setIsContractAndOtherDocumentsForTraining,
      active: isContractAndOtherDocumentsForTraining,
      buttons: [
        {
          text: 'Отказаться',
          onClick: () => {
            setIsContractAndOtherDocumentsForTraining(false);
            setContractAndOtherDocumentsForTrainingStatus(EnrollmentDropdownStatus.NeedSign);
          },
          btnType: ButtonType.Outlined
        }
      ]
    }
  ];
  const CONTENT_TYPE_MAP = {
    application_for_the_conclusion_of_a_contract: <Enrollment4ApplicationForTheConclusionOfAContract />,
    signing_a_contract_by_an_applicant: <Enrollment4SigningAContractByAnApplicant />,
    payment_of_the_first_tuition_fee: <Enrollment4PaymentOfTheFirstTuitionFee />,
    contract_and_other_documents_for_training: <Enrollment4ContractAndOtherDocumentsForTraining />
  };

  const showItems = items.map((item) => {
    return (
      <EnrollmentDropdown
        title={item.title}
        status={item.status}
        content={CONTENT_TYPE_MAP[item.contentType]}
        setVal={item.setVal}
        active={item.active}
        key={item.title}
        className={s.Enrollment4__dropdown}
        buttonActions={item.buttons}
      />
    );
  });

  return (
    <div className={s.Enrollment4}>
      <div className={s.Enrollment4__title}>Заключение договора с абитуриентами</div>
      <div className={s.Enrollment4__items}>{showItems}</div>
      {isCompleted && <Enrollment4ContendForCompleted />}
      <div className={s.Enrollment4__notification_wrapper}>
        <Notification
          visible={isNotification}
          onToggle={() => setIsNotification(!isNotification)}
          className={s.Enrollment4__notification}>
          <p className={s.Enrollment4__notification_text}>Как поступит сотрудник приемной комиссии?</p>
          <ButtonLink
            to="/applicant-agreements"
            btnType={ButtonType.Outlined}
            className={s.Enrollment4__notification_button}>
            Смотреть
          </ButtonLink>
        </Notification>
      </div>
    </div>
  );
};
