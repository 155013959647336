export const sources = [
  { value: 'all', label: 'Все' },
  { value: 'EPGU', label: 'ЕПГУ' }
];
export const statuses = [
  { value: 'all', label: 'Все' },
  { value: 'approved', label: 'Одобрено' }
];
export const firstEducationalPrograms = [{ value: 'all', label: 'Все' }];
export const branchesNIUHSE = [
  { value: 'all', label: 'Все' },
  { value: 'moscow', label: 'Москва' }
];
export const directions_of_reception = [
  { value: 'all', label: 'Все' },
  { value: 'bachelor-of-RF', label: 'Бакалавриат РФ' }
];
