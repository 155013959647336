import clsx from 'clsx';
import React from 'react';
import s from './LandingHeading.module.scss';

export interface ILandingHeading {
  children: string;
  className?: string;
}

export function LandingHeading({ children, className }: ILandingHeading) {
  return <h2 className={clsx(s.LandingHeading, className)}>{children}</h2>;
}
