import { useFormik } from 'formik';
import { Option } from 'react-select';


import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import s from './AdmissionsOfficerFilter.module.scss';

export const AdmissionsOfficerFilter = () => {
  const initialValues = { registration_date: '', effective_date: '' };
  const formik = useFormik({ initialValues, onSubmit: () => {} });

  const selects = [
    { label: 'Дата регистрации', name: 'registration_date', value: formik.values.registration_date, options: '' },
    { label: 'Дата начала действия', name: 'effective_date', value: formik.values.registration_date, options: '' }
  ];
  const showSelects = selects.map(({ label, name, value, options }) => (
    <Field className={s.AdmissionsOfficerFilter_field}>
      <FieldTitle title={label} />
      <Select
        onChange={(option: Option) => formik.setFieldValue(name, option)}
        name={name}
        value={value}
        options={options}
        size={FieldSize.Small}
      />
    </Field>
  ));

  return <>{showSelects}</>;
};
