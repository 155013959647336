import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function PhoneIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24 6.66667V25.3333C24 26.8061 22.8061 28 21.3333 28H10.6667C9.19391 28 8 26.8061 8 25.3333V6.66667C8 5.19391 9.19391 4 10.6667 4H21.3333C22.8061 4 24 5.19391 24 6.66667"
          stroke={color || '#53C120'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 23.9999H18.6666"
          stroke={color || '#53C120'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
