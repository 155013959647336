import { useMemo, useState, useEffect } from 'react';

export function useMediaQueryResult(mediaQueryString: string) {
  const mediaQueryList = useMemo(() => {
    return window.matchMedia(mediaQueryString);
  }, [mediaQueryString]);

  const [queryResult, setQueryResult] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handleMediaQueryListChange = (e: MediaQueryListEvent) => setQueryResult(e.matches);

    mediaQueryList.addListener(handleMediaQueryListChange);
    return () => mediaQueryList.removeListener(handleMediaQueryListChange);
  }, [mediaQueryList]);

  return queryResult;
}
