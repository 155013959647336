import React from 'react';

import { Title } from '../../../common/components/ui/Title/Title';
import { LinkShowAll } from '../../../common/components/ui/LinkShowAll/LinkShowAll';
import { ServiceCard } from '../../../common/components/ui/ServiceCard/ServiceCard';
import { services as servicesData } from '../../services/_temporaryServicesData';
import { ServiceGrid } from '../../../common/components/ui/ServiceGrid/ServiceGrid';
import { useMediaQueryResult } from '../../../common/hooks/useMediaQueryResult';
import s from './Services.module.scss';

export const Services = () => {
  const isDesktop = useMediaQueryResult('(min-width: 768px)');

  return (
    <>
      <Title
        title={
          <>
            Сервисы <span className={s.SubRoutePage__counter}>{servicesData.length}</span>
          </>
        }
        tag="h2">
        <LinkShowAll href="/services" className="d-desktop-only" />
      </Title>

      <ServiceGrid>
        {servicesData.slice(0, isDesktop ? 6 : 3).map((service) => (
          <ServiceCard key={service.id} {...service} />
        ))}
      </ServiceGrid>
      <div className={s.SubRoutePage__services__linkWrapper}>
        <LinkShowAll href="/schedule" className="d-desktop-hide" />
      </div>
    </>
  );
};
