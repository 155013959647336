import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function ClockIcon({ onClick, className, rounded, color }: Props) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [s.Icon_rounded]: rounded
        },
        className
      )}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12.5474" r="8" stroke={color || '#B3BACB'} strokeWidth="2" />
        <path d="M12 7.54736V13.2616L15 15.5474" stroke={color || '#B3BACB'} strokeWidth="2" />
      </svg>
    </div>
  );
}
