import clsx from 'clsx';


import { Button } from 'common/components/ui/Button/Button';
import { Input } from 'common/components/ui/Forms/Input/Input';
import { Icon } from 'common/components/ui/Icons/Icon';
import React from 'react';
import s from './CreateMessageField.module.scss';

export type CreateMessageField = {
  value: string;
  onChange: (e: React.ChangeEvent) => void;
  onSend: () => void;
  className?: string;
};
export const CreateMessageField: React.FC<CreateMessageField> = ({ value, onChange, onSend, className }) => {
  return (
    <div className={clsx(s.CreateMessageField__wrapper, className)}>
      <Input
        value={value}
        name="message"
        onChange={onChange}
        placeholder="Написать сообщение"
        className={s.CreateMessageField__input}
      />
      <div className={s.CreateMessageField__footer_actions}>
        <Icon type="smile" className={s.CreateMessageField__footer_action} />
        <Icon type="pin" className={s.CreateMessageField__footer_action} />
        <Button onClick={onSend} iconStartType="airplane" className={s.CreateMessageField__button_send} />
      </div>
    </div>
  );
};
