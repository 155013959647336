import { useFormik } from 'formik';
import { Option } from 'react-select';

import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Link } from 'react-router-dom';
import { Icon } from 'common/components/ui/Icons/Icon';
import { EnrollmentDropdownStatus } from '../EnrollmentDropdown/EnrollmentDropdown';
import s from './Enrollment4ApplicationForTheConclusionOfAContract.module.scss';

const method_of_concluding_a_contract = [{ value: 'electronic', label: 'Электронный' }];
const agreement_with_the_applicant = [{ value: 'enrollee', label: 'Абитуриент' }];
const additional_points = [{ value: 'olympiad', label: 'Олимпиада' }];

export const Enrollment4ApplicationForTheConclusionOfAContract = () => {
  const initialValues = {
    method_of_concluding_a_contract: { value: 'electronic', label: 'Электронный' },
    agreement_with_the_applicant: { value: 'enrollee', label: 'Абитуриент' },
    additional_points: { value: 'olympiad', label: 'Олимпиада' }
  };
  const formik = useFormik({ initialValues, onSubmit: () => {} });

  const selects = [
    {
      name: 'method_of_concluding_a_contract',
      value: formik.values.method_of_concluding_a_contract,
      options: method_of_concluding_a_contract,
      label: 'Способ заключения договора'
    },
    {
      name: 'agreement_with_the_applicant',
      value: formik.values.agreement_with_the_applicant,
      options: agreement_with_the_applicant,
      label: 'Договор заключается с абитуриентом'
    },
    {
      name: 'additional_points',
      value: formik.values.additional_points,
      options: additional_points,
      label: 'Дополнительные баллы',
      document: { title: 'Сертификат.pdf', to: '' }
    }
  ];
  const infoList = [
    { title: 'Программа, на которую вы рекомендованы', value: 'Математика' },
    { title: 'Факультет', value: 'Факультет математики' },
    { title: 'Срок обучения', value: '4 года' },
    { title: 'Квалификация', value: 'Учитель математики' },
    { title: 'Форма обучения', value: 'Очная' }
  ];

  const showSelects = selects.map(({ name, value, options, label, document }) => (
    <Field className={s.Enrollment4ApplicationForTheConclusionOfAContract__field} key={name}>
      <FieldTitle title={label} />
      <Select
        onChange={(option: Option) => formik.setFieldValue(name, option)}
        name={name}
        value={value}
        options={options}
        size={FieldSize.Small}
      />
      {document && (
        <div className={s.Enrollment4ApplicationForTheConclusionOfAContract__document}>
          <Link to={document.to} className={s.Enrollment4ApplicationForTheConclusionOfAContract__document_link}>
            <Icon type="clip-2" className={s.Enrollment4ApplicationForTheConclusionOfAContract__document_iconClip} />{' '}
            {document.title}
          </Link>
          <Icon
            type="close"
            className={s.Enrollment4ApplicationForTheConclusionOfAContract__document_iconClose}
            color="#6B7A99"
          />
        </div>
      )}
    </Field>
  ));
  const showInfoList = infoList.map(({ title, value }) => (
    <Field className={s.Enrollment4ApplicationForTheConclusionOfAContract__info}>
      <div className={s.Enrollment4ApplicationForTheConclusionOfAContract__info_title}>{title}</div>
      <div className={s.Enrollment4ApplicationForTheConclusionOfAContract__info_value}>{value}</div>
    </Field>
  ));

  return (
    <div>
      <div className={s.Enrollment4ApplicationForTheConclusionOfAContract__infoList}>{showInfoList}</div>
      <div className={s.Enrollment4ApplicationForTheConclusionOfAContract__selects}>{showSelects}</div>
    </div>
  );
};
