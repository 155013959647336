import clsx from 'clsx';

import s from './Icon.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  rounded?: boolean;
  color?: string;
};

export function MenuIcon({ onClick, className, rounded, color }: Props) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.292 10.4167C10.6372 10.4167 10.917 10.1369 10.917 9.79175C10.917 9.44657 10.6372 9.16675 10.292 9.16675C9.94681 9.16675 9.66699 9.44657 9.66699 9.79175C9.66699 10.1369 9.94681 10.4167 10.292 10.4167Z"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.292 4.58325C10.6372 4.58325 10.917 4.30343 10.917 3.95825C10.917 3.61307 10.6372 3.33325 10.292 3.33325C9.94681 3.33325 9.66699 3.61307 9.66699 3.95825C9.66699 4.30343 9.94681 4.58325 10.292 4.58325Z"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.292 16.25C10.6372 16.25 10.917 15.9702 10.917 15.625C10.917 15.2798 10.6372 15 10.292 15C9.94681 15 9.66699 15.2798 9.66699 15.625C9.66699 15.9702 9.94681 16.25 10.292 16.25Z"
        stroke={color || '#5C617C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
