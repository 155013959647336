import { useState } from 'react';
import clsx from 'clsx';

import { Tag } from 'common/components/ui/Tags/Tags';
import { AppColor } from 'store/app';
import { Button, ButtonType } from 'common/components/ui/Button/Button';
import { Checkbox } from 'common/components/ui/Forms/Checkbox/Checkbox';
import s from './ParticipantsTable.module.scss';

const tableTitles = ['ФИО студента', 'Факультет', 'Посещаемость', 'Общий балл', ' '];
const tableItems = [
  {
    id: '1',
    groupNum: 342,
    items: [
      {
        id: '1',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 8
      },
      {
        id: '2',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 4,
        overallScore: 4
      },
      {
        id: '3',
        student: { name: 'Орлова Алина Геннадиевна', isAttend: false },
        faculty: 'МФПИТ',
        attendance: 3,
        overallScore: 4
      },
      {
        id: '4',
        student: { name: 'Романова Ирина Викторовна', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 5,
        overallScore: 7
      },
      {
        id: '5',
        student: { name: 'Иванов Марк Денисович', isAttend: false },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 8
      },
      {
        id: '6',
        student: { name: 'Ахматова Анна Андреевна', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 9,
        overallScore: 10
      }
    ]
  },
  {
    id: '2',
    groupNum: 342,
    items: [
      {
        id: '1',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      },
      {
        id: '2',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 4,
        overallScore: 9
      },
      {
        id: '3',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      },
      {
        id: '4',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      },
      {
        id: '5',
        student: { name: 'Иванов Иван Иванович', isAttend: true },
        faculty: 'МФПИТ',
        attendance: 6,
        overallScore: 9
      }
    ]
  }
];

type Props = {
  pageType: 'calendar' | 'lesson';
  className?: string;
  classNameTable?: string;
};
export const ParticipantsTable: React.FC<Props> = ({ pageType, className, classNameTable }) => {
  const showTitles = tableTitles.map((title) => (
    <div className={clsx(s.ParticipantsTable__table__item, s.ParticipantsTable__table_title)} key={title}>
      {title}
    </div>
  ));

  /* eslint-disable react-hooks/rules-of-hooks */
  const tableChildren = tableItems.map((item) => {
    const showSubItems = item.items.map((subItem) => {
      const [isAttended, setIsAttended] = useState(subItem.student.isAttend);

      const handleChangeAttend = () => {
        setIsAttended(!isAttended);
      };

      return (
        <div className={s.ParticipantsTable__table__row} key={subItem.id}>
          {Object.entries(subItem).map(
            ([key, value], index) =>
              key !== 'id' && (
                <div key={key} className={s.ParticipantsTable__table__item}>
                  {key === 'attendance' || key === 'overallScore' ? (
                    <Tag
                      color={value < 5 ? AppColor.Red : value >= 5 && value < 9 ? AppColor.Yellow : AppColor.Green}
                      title={`${value}/10`}
                      className={s.ParticipantsTable__table__tag}
                    />
                  ) : key === 'student' && pageType === 'lesson' ? (
                    <Checkbox
                      key={key}
                      onChange={handleChangeAttend}
                      label={subItem.student.name}
                      checked={isAttended}
                      name="student"
                      className={s.ParticipantsTable__name}
                    />
                  ) : key === 'student' ? (
                    <span key={key} className={s.ParticipantsTable__name}>
                      {subItem.student.name}
                    </span>
                  ) : (
                    value
                  )}
                </div>
              )
          )}
          <div className={s.ParticipantsTable__table__item} key={subItem.id}>
            <Button btnType={ButtonType.Outlined}>Написать</Button>
          </div>
        </div>
      );
      /* eslint-enable react-hooks/rules-of-hooks */
    });

    return (
      <>
        <div key={item.id} className={clsx(s.ParticipantsTable__table__row, s.ParticipantsTable__rowTitle)}>
          <div className={clsx(s.ParticipantsTable__table__item, s.ParticipantsTable__groupTitle)}>
            Группа: {item.groupNum}
          </div>
        </div>
        {showSubItems}
      </>
    );
  });

  return (
    <div className={clsx(s.ParticipantsTable__wrapper, className)}>
      <div className={s.ParticipantsTable__table__row}>{showTitles}</div>
      <div className={clsx(s.ParticipantsTable__table, classNameTable)}>{tableChildren}</div>
    </div>
  );
};
