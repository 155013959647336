import Avatar1 from 'assets/avatar-1.png';
import Avatar2 from 'assets/avatar-2.png';
import Avatar3 from 'assets/avatar-3.png';

export enum EventLessonType {
  Economic = 'экономика',
  Languages = 'языки',
  Phisic = 'фищика',
  Biology = 'биология',
  PoliticalScience = 'политология',
  ArtHistory = 'история искусств',
  Philosophy = 'философия',
  Right = 'право'
}
export enum EventType {
  Seminar = 'Семинар',
  Lecture = 'Лекция',
  Event = 'Мероприятие'
}

export type EventItem = {
  id: string;
  dateFrom: number;
  dateTo: number;
  title: string;
  lessonType?: EventLessonType;
  type: EventType;
  cabinet: string;
  author?: string;
  allParticipants: number;
  participantsAvatars: Array<string>;
};

export const eventsData: Array<EventItem> = [
  {
    id: '1',
    dateFrom: 1631170857,
    dateTo: 1631174457,
    title: 'Европейские языки и культура Cкандинавские языки и культура',
    lessonType: EventLessonType.Languages,
    type: EventType.Seminar,
    cabinet: '456 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '2',
    dateFrom: 1625555375,
    dateTo: 1625558975,
    title: 'Европейские языки и культура Cкандинавские языки и культура',
    lessonType: EventLessonType.Languages,
    type: EventType.Seminar,
    cabinet: 'Online',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '3',
    dateFrom: 1631192457,
    dateTo: 1631206857,
    title: 'Политология',
    lessonType: EventLessonType.PoliticalScience,
    type: EventType.Lecture,
    cabinet: 'Online',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '5',
    dateFrom: 1630911608,
    dateTo: 1630915208,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '6',
    dateFrom: 1631257208,
    dateTo: 1631260808,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '7',
    dateFrom: 1630990808,
    dateTo: 1630994408,
    title: 'Философия',
    lessonType: EventLessonType.Philosophy,
    type: EventType.Seminar,
    cabinet: '456 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '8',
    dateFrom: 1630999808,
    dateTo: 1631003408,
    title: 'Право',
    lessonType: EventLessonType.Right,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '10',
    dateFrom: 1631007608,
    dateTo: 1631012408,
    title: 'История искусств',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '11',
    dateFrom: 1631014208,
    dateTo: 1631019608,
    title: 'Философия',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '456 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '12',
    dateFrom: 1630832408,
    dateTo: 1630839608,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '13',
    dateFrom: 1631091608,
    dateTo: 1631095208,
    title: 'История искусств',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '14',
    dateFrom: 1631707208,
    dateTo: 1631718008,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '15',
    dateFrom: 1631775608,
    dateTo: 1631786408,
    title: 'История искусств',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '16',
    dateFrom: 1631422808,
    dateTo: 1631430008,
    title: 'Генеральная уборка..',
    type: EventType.Event,
    cabinet: 'Акт зал',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '17',
    dateFrom: 1631505631,
    dateTo: 1631509231,
    title: 'История искусств',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '18',
    dateFrom: 1631512831,
    dateTo: 1631516431,
    title: 'Генеральная уборка..',
    type: EventType.Event,
    cabinet: 'Акт зал',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '19',
    dateFrom: 1631602831,
    dateTo: 1631610031,
    title: 'Философия',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '456 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '20',
    dateFrom: 1631613631,
    dateTo: 1631619031,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '21',
    dateFrom: 1631889031,
    dateTo: 1631896231,
    title: 'Право',
    lessonType: EventLessonType.Right,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '22',
    dateFrom: 1631854831,
    dateTo: 1631862031,
    title: 'Политология',
    lessonType: EventLessonType.PoliticalScience,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '23',
    dateFrom: 1631530831,
    dateTo: 1631534431,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '24',
    dateFrom: 1631428225,
    dateTo: 1631435425,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '25',
    dateFrom: 1631889031,
    dateTo: 1631896231,
    title: 'Право',
    lessonType: EventLessonType.Right,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '26',
    dateFrom: 1631424625,
    dateTo: 1631428225,
    title: 'Политология',
    lessonType: EventLessonType.PoliticalScience,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '27',
    dateFrom: 1631532625,
    dateTo: 1631536225,
    title: 'Философия',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '456 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '28',
    dateFrom: 1631539825,
    dateTo: 1631543425,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '29',
    dateFrom: 1631629825,
    dateTo: 1631633425,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '30',
    dateFrom: 1631701825,
    dateTo: 1631705425,
    title: 'Право',
    lessonType: EventLessonType.Right,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '31',
    dateFrom: 1631709025,
    dateTo: 1631712625,
    title: 'История искусств',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '342 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '32',
    dateFrom: 1631716225,
    dateTo: 1631719825,
    title: 'Европейские языки и культура Cкандинавские языки и культура',
    lessonType: EventLessonType.Languages,
    type: EventType.Seminar,
    cabinet: 'Online',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '33',
    dateFrom: 1631802625,
    dateTo: 1631806225,
    title: 'Политология',
    lessonType: EventLessonType.PoliticalScience,
    type: EventType.Lecture,
    cabinet: 'Online',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '34',
    dateFrom: 1631806225,
    dateTo: 1631809825,
    title: 'Политология',
    lessonType: EventLessonType.PoliticalScience,
    type: EventType.Lecture,
    cabinet: 'Online',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '35',
    dateFrom: 1631768425,
    dateTo: 1631772025,
    title: 'Европейские языки и культура Cкандинавские языки и культура',
    lessonType: EventLessonType.Languages,
    type: EventType.Seminar,
    cabinet: 'Online',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '36',
    dateFrom: 1631790025,
    dateTo: 1631793625,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '37',
    dateFrom: 1631620825,
    dateTo: 1631624425,
    title: 'Экономика',
    lessonType: EventLessonType.Economic,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '38',
    dateFrom: 1631628025,
    dateTo: 1631631625,
    title: 'История искусств',
    lessonType: EventLessonType.ArtHistory,
    type: EventType.Seminar,
    cabinet: '512 ауд.',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  },
  {
    id: '39',
    dateFrom: 1631635225,
    dateTo: 1631638825,
    title: 'Генеральная уборка..',
    type: EventType.Event,
    cabinet: 'Акт зал',
    author: 'Ощепков Ю.А.',
    allParticipants: 20,
    participantsAvatars: [Avatar1, Avatar2, Avatar3]
  }
];
