import { useState } from 'react';
import { useFormik } from 'formik';
import { Option } from 'react-select';

import { Field, FieldTitle } from 'common/components/ui/Forms/Field/Field';
import { Select } from 'common/components/ui/Forms/Select/Select';
import { FieldSize } from 'common/components/ui/Forms/basicTypes';
import { Checkbox } from 'common/components/ui/Forms/Checkbox/Checkbox';
import { EnrollmentDropdownStatus } from '../EnrollmentDropdown/EnrollmentDropdown';
import { Enrollment4Documents } from './Enrollment4Documents';
import s from './Enrollment4SigningAContractByAnApplicant.module.scss';

const documents = [
  { text: 'Договор об образовании.pdf', to: '/' },
  { text: 'Приложение к договору об образовании.pdf', to: '/' }
];
const contractWith = [{ value: 'abiturient', label: 'Абитуриент' }];

export const Enrollment4SigningAContractByAnApplicant = () => {
  const initialValues = { contract_with: '', agree: false };
  const formik = useFormik({ initialValues, onSubmit: () => {} });

  return (
    <div>
      <div className={s.Enrollment4SigningAContractByAnApplicant__title}>Подпишите сформированные документы</div>
      <Enrollment4Documents items={documents} className={s.Enrollment4SigningAContractByAnApplicant__documents} />
      <Field className={s.Enrollment4SigningAContractByAnApplicant__selectWrapper}>
        <FieldTitle title="Договор заключается с абитуриентом" />
        <Select
          onChange={(option: Option) => formik.setFieldValue('contract_with', option)}
          name="contract_with"
          value={formik.values.contract_with}
          options={contractWith}
          size={FieldSize.Small}
        />
      </Field>
      <Field>
        <Checkbox
          onChange={formik.handleChange}
          name="agree"
          checked={formik.values.agree}
          label="Я подтверждаю согласие с условиями Договора об образовании, принимаю и выражаю желание обучаться в НИУ ВШЭ"
        />
      </Field>
    </div>
  );
};
