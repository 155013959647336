import clsx from 'clsx';
import React, { useState } from 'react';

import { Icon } from 'common/components/ui/Icons/Icon';
import { Notification } from 'common/components/ui/Notification/Notification';
import { Button, ButtonType } from 'common/components/ui/Button/Button';
import { usePreventBodyScroll } from 'common/hooks/usePreventBodyScroll';
import { AdmissionsOfficerAction, AdmissionsOfficerActions } from '../AdmissionsOfficerActions';
import { AdmissionsOfficerHeader } from '../AdmissionsOfficerHeader';
import {
  AdmissionsOfficerCardItems,
  AdmissionsOfficerCardItem
} from './AdmissionsOfficerCardItem/AdmissionsOfficerCardItem';
import s from './AdmissionsOfficerCard.module.scss';

type Props = {
  visible?: boolean;
  setIsApplicationCard: (isApplicationCard: boolean) => void;
  actionsLeft: Array<AdmissionsOfficerAction>;
  actionsRight?: Array<AdmissionsOfficerAction>;
  cardTitle: string;
  cardItems: Array<AdmissionsOfficerCardItem>;
  cardTopChildren?: React.ReactNode;
  onNotification: () => void;
};
export const AdmissionsOfficerCard: React.FC<Props> = ({
  visible,
  setIsApplicationCard,
  actionsLeft,
  actionsRight,
  cardTitle,
  cardItems,
  cardTopChildren,
  onNotification
}) => {
  usePreventBodyScroll(visible);

  const [isNotification, setIsNotification] = useState(true);

  const onTogglIsNotification = () => {
    setIsNotification(!isNotification);
  };

  return (
    <div className={clsx(s.AdmissionsOfficerCard__wrapper, { [s.AdmissionsOfficerCard__wrapper_show]: visible })}>
      <div className={s.AdmissionsOfficerCard__background} onClick={() => setIsApplicationCard(false)} />
      <div className={s.AdmissionsOfficerCard}>
        <div className={s.AdmissionsOfficerCard__topActions}>
          <div className={s.AdmissionsOfficerCard__topAction}>
            <Icon type="arrow" color="#B3BACB" className={s.AdmissionsOfficerCard__topAction__icon} />
            Предыдущая
          </div>
          <div className={s.AdmissionsOfficerCard__topAction}>
            Следующая
            <Icon type="arrow" color="#222845" className={s.AdmissionsOfficerCard__topAction__icon} />
          </div>
        </div>
        <AdmissionsOfficerHeader title={cardTitle} />
        <AdmissionsOfficerActions actionsLeft={actionsLeft} actionsRight={actionsRight} />
        <div className={s.content}>
          {cardTopChildren}
          <AdmissionsOfficerCardItems items={cardItems} />
        </div>
        <div className={s.AdmissionsOfficerCard__notification__wrapper}>
          <Notification visible={isNotification} onToggle={onTogglIsNotification}>
            <p className={s.AdmissionsOfficerCard__notification_text}>Как поступит абитуриент?</p>
            <Button
              onClick={onNotification}
              btnType={ButtonType.Outlined}
              className={s.AdmissionsOfficerCard__notification_button}>
              Смотреть
            </Button>
          </Notification>
        </div>
      </div>
    </div>
  );
};
