import clsx from 'clsx';
import React from 'react';
import { useMediaQueryResult } from '../../../common/hooks/useMediaQueryResult';

import s from './ProgressBar.module.scss';

const itemsData = [
  { slug: 'level-of-preparation', title: 'Уровень подготовки' },
  { slug: 'specialization', title: 'Специализация' },
  { slug: 'educational-program', title: 'Образовательнная программа' },
  { slug: 'filing-an-application', title: 'Подача заявки' },
  { slug: 'test-task', title: 'Испытательное задание' },
  { slug: 'enrollment', title: 'Зачисление' }
];
type Props = {
  chosenProgress: number;
  type?: 'first' | 'second';
  className?: string;
  setChosenProgress: (number: number) => void;
};
export const ProgressBar: React.FC<Props> = ({ chosenProgress, type, className, setChosenProgress }) => {
  const isDesktop = useMediaQueryResult('(min-width: 1322px)');

  const items = (item, index) => (
    <div
      onClick={() => setChosenProgress(index)}
      className={clsx(
        s.ProgressBar__item,
        { [s.ProgressBar__item_active]: chosenProgress >= index, [s.ProgressBar__itemSecond]: type === 'second' },
        className
      )}
      key={item.slug}>
      <div className={s.ProgressBar__content}>
        <span className={s.ProgressBar__count}>{index + 1}</span>
        <span className={s.ProgressBar__text}>{item.title}</span>
      </div>
    </div>
  );
  const showItems = itemsData.map((item, index) => {
    return isDesktop
      ? items(item, index)
      : !isDesktop && type === 'first' && chosenProgress >= index
      ? items(item, index)
      : !isDesktop && type === 'second' && chosenProgress < index && items(item, index);
  });

  return <div className={s.ProgressBar__wrapper}>{showItems}</div>;
};
