import clsx from 'clsx';

import s from './FilterItem.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};
export const FilterItem: React.FC<Props> = ({ children, className }) => (
  <div className={clsx(s.FilterItem, className)}>{children}</div>
);
